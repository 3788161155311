@import '../../../Styles/variables.module';

$side-width: 100%;
$side-width-desktop: 300px;

.wrapper {
  background-color: $background;
  margin-top: 16px;
  padding: 0 16px;
  @media screen and (min-width: $desktop-min-width) {
    background-color: $text-white;
    margin-top: 0;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    min-height: 60px;
  }
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  @media screen and (min-width: $desktop-min-width) {
    max-width: $large-desktop-min-width;
    display: flex;
  }
}

.linkList {
  display: none;
  @media screen and (min-width: $desktop-min-width) {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    width: calc(100% - 300px);
    overflow: auto;
    padding: 10px 10px 0 10px;
    span {
      margin-right: 5px;
    }
    button {
      height: 100%;
      &:focus,
      &:active {
        outline: $link dotted 1px;
        -moz-outline-radius: 0.2em;
      }
    }
    button:disabled {
      cursor: default;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-inactive;
      padding: 4px 0;
      position: relative;
      font-size: 14px;
      border-bottom: 4px solid transparent;
      margin-right: 30px;
      margin-bottom: 10px;
      &:active,
      &:hover {
        outline: 0;
        transition: border-color 0.2s ease, border-width 0.2s ease,
          -webkit-transform 0.2s ease;
        -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
          -webkit-transform 0.2s ease;
        border-color: #0375ba;
      }
      &:first-child button {
        margin-left: 2px;
      }
    }
    li.active {
      color: #000;
      border-bottom: 4px solid #0375ba;
      cursor: default;
    }
    li.navigable {
      color: $link;
      cursor: pointer;
    }
    li.inactive {
      &:active,
      &:hover {
        outline: 0;
        transition: none;
        -webkit-transition: none;
        border-color: transparent;
      }
    }
  }
}

.stepSide {
  width: $side-width;
  padding: 16px;
  @media screen and (min-width: $desktop-min-width) {
    width: $side-width-desktop;
  }
}

.stepIcon {
  position: absolute;
  left: -25px;
}
