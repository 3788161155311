@import './variables.module';

/* LAYOUT.MODULE.SCSS EXTENDS */
@mixin vr_half {
  margin-bottom: 0.5 * $base-vertical-rhythm;
}
@mixin vr_1 {
  margin-bottom: 1 * $base-vertical-rhythm;
}
@mixin vr_2 {
  margin-bottom: 2 * $base-vertical-rhythm;
}
@mixin vr_3 {
  margin-bottom: 3 * $base-vertical-rhythm;
}
@mixin vr_4 {
  margin-bottom: 4 * $base-vertical-rhythm;
}
@mixin vr_5 {
  margin-bottom: 5 * $base-vertical-rhythm;
}
@mixin vr_6 {
  margin-bottom: 6 * $base-vertical-rhythm;
}
@mixin vr_7 {
  margin-bottom: 7 * $base-vertical-rhythm;
}
@mixin vr_8 {
  margin-bottom: 8 * $base-vertical-rhythm;
}

@mixin srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* TYPOGRAPHY.MODULE.SCSS EXTENDS */

/* Mobile-first typography */
// h2
@mixin pageTitle {
  color: $text-color;
  font-size: 24px;
  line-height: 30px;
  @media screen and (min-width: $desktop-min-width) {
    font-size: 32px;
    line-height: 40px;
  }
}

// h3
@mixin sectionTitle {
  color: $text-color;
  font-size: 20px;
  line-height: 24px;
  @media screen and (min-width: $desktop-min-width) {
    font-size: 24px;
    line-height: 30px;
  }
}

@mixin whiteText {
  color: $text-white;
}

@mixin bold {
  font-weight: 600;
}

// all texts are p tags
@mixin largeText {
  color: $text-color;
  font-size: 16px;
  line-height: 20px;
  @media screen and (min-width: $desktop-min-width) {
    font-size: 18px;
    line-height: 24px;
  }
}
@mixin mediumText {
  color: $text-color;
  font-size: 14px;
  line-height: 18px;
  @media screen and (min-width: $desktop-min-width) {
    font-size: 16px;
    line-height: 22px;
  }
}
@mixin smallText {
  color: $text-color;
  font-size: 14px;
  line-height: 18px;
  @media screen and (min-width: $desktop-min-width) {
    font-size: 14px;
    line-height: 18px;
  }
}

/* BUTTONS EXTENDS */
@mixin link($color) {
  color: $color;
  span {
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
  }
  &:active,
  &:focus,
  &:hover {
    outline: 0;
    span {
      transition: border-color 0.2s ease, border-width 0.2s ease,
        -webkit-transform 0.2s ease;
      -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
        -webkit-transform 0.2s ease;
      border-color: $color;
    }
  }
}
