@import '../Styles/variables.module';

@media print {
  .header {
    display: none !important;
  }
  .viewContainer {
    display: none !important;
  }
  .sideContainer {
    width: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    background: $text-white !important;
    > div {
      width: 100%;
      max-width: 100%;
    }
  }
}

.header {
  background-color: $blue-grad-pt-1;
  background-image: url(../../Assets/Images/Header-BG.jpg);
  background-size: cover;
}
.center {
  width: 100%;
  max-width: 1280px;
  display: flex;
}
.viewContainer {
  padding: 40px 16px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.viewContainerChild {
  width: 100%; // IE11 Fix
}
.sideContainer {
  background: linear-gradient($accent-blue, $accent-dark);
  color: #fff;
}
@media screen and (min-width: $desktop-min-width) {
  .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }
  .viewContainer {
    padding: 0;
    width: 60%;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    .viewContainerChild {
      background-color: $background;
      margin-top: 3px; // to show box shadow from header.
      padding: 70px 20px; // fix for wedges 
      width: 100%;
      max-width: 768px;
    }
  }
  .sideContainer {
    width: 40%;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    > div {
      width: 100%;
      max-width: 512px;
    }
  }
  .animate * {
    -webkit-animation: fadein 0.25s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.25s; /* Firefox < 16 */
    -ms-animation: fadein 0.25s; /* Internet Explorer */
    -o-animation: fadein 0.25s; /* Opera < 12.1 */
    animation: fadein 0.25s;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
