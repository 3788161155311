@import '../../../../../SharedComponents/Styles/index';

.wrapper {
  display: flex;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  img {
    width: 100%;
  }
}

.errorWrapper {
  text-align: center;
  img {
    max-width: 50%;
  }
}
