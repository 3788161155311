// breakpoints
$mobile-max-width: 768px;
$desktop-min-width: 769px;
$large-desktop-min-width: 1280px;

// colors
$background: #f8f8f8;
$text-color: #000000;
$text-white: #ffffff;
$text-error: #d40000;
$text-inactive: #aeaeae;
$accent-dark: #2e3134;
$accent-blue: #0c4a6f;
$accent-smoke: #f1f1f1;
$link: #0375ba;
$border-color: #e1e1e1;
$progress-color: #75ba33;
$focus-color: #00a1e7;
$label-color: #616a70;
$update-color: #fffed0;

// gradients
$blue-grad-pt-1: #0d1727;
$blue-grad-pt-2: #376291;

// layout
$base-vertical-rhythm: 10px;
