@import '../../../../../SharedComponents/Styles/variables.module';

.wrapper {
  margin: 40px 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .btnBack {
    background-color: $text-white;
    color: $link;
    &:hover {
      background-color: $link;
      color: $text-white;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
  }
  hr {
    margin: 0;
    margin-left: -20px;
    margin-right: -20px;
    border: 1px solid $border-color;
  }
  button {
    margin-top: 40px;
  }
  button:first-child {
    margin-right: 10px;
  }
}
