@import '../Styles/index';

.inputField {
  margin-top: 8px;
  position: relative;
  output {
    position: absolute;
    top: -30px;
    right: 0;
    font-size: 24px;
    font-weight: bold;
  }
}

/* ********************
   CUSTOM RANGE STYLING
   *******************/

/* NOTE: YOU CAN NOT COMBINE THESE SELECTORS,
         WHEN BROWSERS DO NOT UNDERSTAND ONE PART OF A SELECTOR,
         THEY DROP THE ENTIRE SELECTOR.
*/

/* 1. Hide default styling */
.customRange[type='range'] {
  margin: 0;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}
.customRange[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.customRange[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
.customRange[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* 2. Style slider */

// Chrome
.customRange[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 36px;
  height: 36px;
  border: 9px solid $text-white;
  border-radius: 18px;
  background: $link;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: -12px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}
.customRange[type='range']:focus::-webkit-slider-thumb {
  outline: $link dotted 1px;
}

// FF
.customRange[type='range']::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  border: 9px solid $text-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: $link;
  cursor: pointer;
  margin-top: -10px;
}
.customRange[type='range']:focus::-moz-range-thumb {
  outline: 1px dotted black;
}

// IE
.customRange[type='range']::-ms-thumb {
  height: 10px;
  width: 20px;
  border: 1px solid $accent-dark;
  background: $text-white;
  cursor: pointer;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.customRange[type='range']::-ms-tooltip {
  display: none;
}

/* 3. Style track */
$linear-gradient: linear-gradient(90deg, #07adde 0%, #0375ba 100%);

// Chrome
.customRange[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: $linear-gradient;
}
.customRange[type='range']:focus::-webkit-slider-runnable-track {
  background: $linear-gradient;
}

// FF
.customRange[type='range']::-moz-range-progress {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: $linear-gradient;
}
.customRange[type='range']::-moz-range-track {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: $accent-dark;
  outline: none;
}

// IE
.customRange[type='range']::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: $linear-gradient;
}
.customRange[type='range']::-ms-fill-lower {
  background: $linear-gradient;
}
.customRange[type='range']:focus::-ms-fill-lower {
  background: $linear-gradient;
}
.customRange[type='range']::-ms-fill-upper {
  background: $accent-dark;
}
.customRange[type='range']:focus::-ms-fill-upper {
  background: $accent-dark;
}

.ticks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @extend %smallText;
  color: $text-color;
  padding: 0 9px;
  margin-top: 20px;
  .line {
    border-bottom: 1px solid #ccc;
    margin: 0 20px;
    height: 2px;
    flex-grow: 1;
  }
  span {
    flex-grow: 0;
  }
  span.min {
    text-align: left;
  }
  span.max {
    text-align: right;
  }
}
