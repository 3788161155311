@import '../../../../../SharedComponents/Styles/typography.module.scss';

.helpTexts {
  display: flex;
  justify-content: space-between;
  p {
    @extend %smallText;
    width: calc(33.333333% - 40px);
    margin: 0 20px;
    text-align: center;
  }
}
