@import '../../../SharedComponents/Styles/index.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 16px;
}

.blur {
  position: absolute;
  background-color: black;
  opacity: 0.75;
  left: 0; // IE11 Flex fix
  top: 0; // IE11 Flex fix
  right: 0; // IE11 Flex fix
  bottom: 0; // IE11 Flex fix
  z-index: 100; // IE11 Flex fix
}

.view {
  // background-color: white;
  overflow: auto;
  opacity: 1;
  filter: blur(0);
  -webkit-filter: blur(0);
  align-items: stretch;
  @media screen and (min-width: $desktop-min-width) {
    display: flex;
  }
  z-index: 200; // IE11 Flex fix
}
.loader {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  img {
    width: 60px;
  }
}
