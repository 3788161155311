@import './variables.module';

/* Mobile-first typography */
// h2
.pageTitle, %pageTitle {
  color: $text-color;
  font-size: 24px;
  line-height: 30px;
}

// h3
.sectionTitle, %sectionTitle {
  color: $text-color;
  font-size: 20px;
  line-height: 24px;
}

.whiteText, %whiteText {
  color: $text-white;
}

.bold, %bold {
  font-weight: 600;
}

// all texts are p tags
.largeText, %largeText {
  color: $text-color;
  font-size: 16px;
  line-height: 20px;
}
.mediumText, %mediumText {
  color: $text-color;
  font-size: 14px;
  line-height: 18px;
}
.smallText, %smallText {
  color: $text-color;
  font-size: 14px;
  line-height: 18px;
}

/* Desktop typography */
@media screen and (min-width: $desktop-min-width) {
  .pageTitle, %pageTitle {
    font-size: 32px;
    line-height: 40px;
  }
  .sectionTitle, %sectionTitle {
    font-size: 24px;
    line-height: 30px;
  }
  .largeText, %largeText {
    font-size: 18px;
    line-height: 24px;
  }
  .mediumText, %mediumText {
    font-size: 16px;
    line-height: 22px;
  }
  .smallText, %smallText {
    font-size: 14px;
    line-height: 18px;
  }
}