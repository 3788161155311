@import '../../../../../SharedComponents/Styles/typography.module.scss';

.helpTexts {
  display: flex;
  justify-content: space-between;
  p {
    @extend %smallText;
    padding: 0 10px;
    text-align: center;
    width: calc(33.333333% - 40px);
    margin: 20px;
  }
}
