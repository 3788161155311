@import '../../../../../SharedComponents/Styles/typography.module.scss';

.helpTexts {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  p {
    @extend %smallText;
    padding: 0 10px;
    text-align: center;
    margin: 0;
    max-width: calc(50% - 40px);
  }
}
