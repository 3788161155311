@import '../Styles/variables.module';
@import '../Styles/mixins.module';

.vr_3 {
  @include vr_3();
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  z-index: 1000;
  .modalMain {
    border: 1px solid black;
    position: fixed;
    background: white;
    color: black;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
  }
}

.displayBlock {
  display: block;
}

.displayNone {
  display: none;
}

.btnGroup {
  button {
    margin: 0 10px 0 10px;
    height: 30px;
    width: 150px;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  input {
    align-self: start;
    margin-right: 0.5em;
    margin-top: 5px;
  }
  label {
    text-align: left;
  }
}
