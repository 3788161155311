@import url('//hello.myfonts.net/count/34b530');

body {
  margin: 0;
  padding: 0;
}

pingwebfit body,
pingwebfit html {
  width: 100%;
}

*,
pingwebfit .blocker,
pingwebfit .chat-window-head {
  -webkit-box-sizing: border-box;
}

pingwebfit .checkbox-style-box,
pingwebfit a,
pingwebfit button {
  cursor: pointer;
}

pingwebfit .mix-hdg_center,
pingwebfit .txt_center {
  text-align: center;
}

pingwebfit .icon,
pingwebfit .icon g {
  fill: currentColor;
}

pingwebfit .checkbox-style,
pingwebfit .link_longArrowLeft,
pingwebfit .link_longArrowRight {
  white-space: nowrap;
}

pingwebfit .chat iframe,
pingwebfit .chat-window-body {
  min-height: 550px;
  height: 100%;
}

pingwebfit .u-clearfix:after,
pingwebfit .u-clearfix:before {
  content: ' ';
  display: table;
}

pingwebfit .u-clearfix:after {
  clear: both;
}

pingwebfit a,
pingwebfit abbr,
pingwebfit acronym,
pingwebfit address,
pingwebfit applet,
pingwebfit article,
pingwebfit aside,
pingwebfit audio,
pingwebfit b,
pingwebfit big,
pingwebfit blockquote,
pingwebfit body,
pingwebfit canvas,
pingwebfit caption,
pingwebfit center,
pingwebfit cite,
pingwebfit code,
pingwebfit dd,
pingwebfit del,
pingwebfit details,
pingwebfit dfn,
pingwebfit div,
pingwebfit dl,
pingwebfit dt,
pingwebfit em,
pingwebfit embed,
pingwebfit fieldset,
pingwebfit figcaption,
pingwebfit figure,
pingwebfit footer,
pingwebfit form,
pingwebfit h1,
pingwebfit h2,
pingwebfit h3,
pingwebfit h4,
pingwebfit h5,
pingwebfit h6,
pingwebfit header,
pingwebfit hgroup,
pingwebfit html,
pingwebfit i,
pingwebfit iframe,
pingwebfit img,
pingwebfit ins,
pingwebfit kbd,
pingwebfit label,
pingwebfit legend,
pingwebfit li,
pingwebfit mark,
pingwebfit menu,
pingwebfit nav,
pingwebfit object,
pingwebfit ol,
pingwebfit output,
pingwebfit p,
pingwebfit pre,
pingwebfit q,
pingwebfit ruby,
pingwebfit s,
pingwebfit samp,
pingwebfit section,
pingwebfit small,
pingwebfit span,
pingwebfit strike,
pingwebfit strong,
pingwebfit sub,
pingwebfit summary,
pingwebfit sup,
pingwebfit table,
pingwebfit tbody,
pingwebfit td,
pingwebfit tfoot,
pingwebfit th,
pingwebfit thead,
pingwebfit time,
pingwebfit tr,
pingwebfit tt,
pingwebfit u,
pingwebfit ul,
pingwebfit var,
pingwebfit video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

pingwebfit article,
pingwebfit aside,
pingwebfit details,
pingwebfit figcaption,
pingwebfit figure,
pingwebfit footer,
pingwebfit header,
pingwebfit hgroup,
pingwebfit menu,
pingwebfit nav,
pingwebfit section {
  display: block;
}

pingwebfit body {
  line-height: 1;
}

pingwebfit ol,
pingwebfit ul {
  list-style: none;
}

pingwebfit blockquote,
pingwebfit q {
  quotes: none;
}

pingwebfit blockquote:after,
pingwebfit blockquote:before,
pingwebfit q:after,
pingwebfit q:before {
  content: '';
  content: none;
}

pingwebfit table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

pingwebfit button {
  background-color: transparent;
  border: none;
  color: inherit;
  font: inherit;
}

pingwebfit input {
  font-size: inherit;
  font-family: inherit;
}

pingwebfit a {
  text-decoration: none;
  color: #000;
}

pingwebfit .featuredVideos-sidebar-list-item > a:focus,
pingwebfit .link_footer:active,
pingwebfit .link_footer:focus,
pingwebfit .link_footer:hover,
pingwebfit .tile_pro:focus > .tile_pro-caption,
pingwebfit .tile_productMenu:focus .tile_productMenu-label,
pingwebfit .tile_productMenu:focus .tile_productMenu_title.blue {
  text-decoration: underline;
}

@media (max-width: 320px) {
  .hideAt_phoneSm {
    display: none;
  }
}

@media (max-width: 425px) {
  .hideAt_phoneLg {
    display: none;
  }
}

@media (max-width: 900px) {
  .hideAt_tableLg {
    display: none;
  }
}

@media (max-width: 768px) {
  .hideAt_tablet {
    display: none;
  }
}

@media (max-width: 600px) {
  .hideAt_tabletSm {
    display: none;
  }
}

@media (max-width: 1024px) {
  .hideAt_laptop {
    display: none;
  }
}

@media (max-width: 1440px) {
  .hideAt_desktop {
    display: none;
  }
}

@media (max-width: 1240px) {
  .hideAt_maxContent {
    display: none;
  }
}

@media (min-width: 321px) {
  .hideAbove_phoneSm {
    display: none;
  }
}

@media (min-width: 426px) {
  .hideAbove_phoneLg {
    display: none;
  }
}

@media (min-width: 901px) {
  .hideAbove_tableLg {
    display: none;
  }
}

@media (min-width: 769px) {
  .hideAbove_tablet {
    display: none;
  }
}

@media (min-width: 601px) {
  .hideAbove_tabletSm {
    display: none;
  }
}

@media (min-width: 1025px) {
  .hideAbove_laptop {
    display: none;
  }
}

@media (min-width: 1441px) {
  .hideAbove_desktop {
    display: none;
  }
}

pingwebfit .isVisuallyHidden,
pingwebfit .isVisuallyHidden_editable {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  position: absolute;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

pingwebfit body.js-kenticoEditMode .isVisuallyHidden_editable {
  width: auto;
  height: auto;
  padding: 5px;
  margin: 0;
  position: relative;
  clip: unset;
  color: red;
}

pingwebfit .checkbox-input,
pingwebfit .radio-input {
  clip: rect(0 0 0 0);
  overflow: hidden;
}

pingwebfit body.js-kenticoEditMode .isVisuallyHidden_editable:before {
  content: '(This content only visible in edit mode)';
  display: block;
}

pingwebfit .blockquote::after,
pingwebfit .link:after {
  content: '';
}

pingwebfit .hidden {
  display: none;
}

pingwebfit .fill {
  width: 100%;
  height: 100%;
}

pingwebfit body,
pingwebfit {
  width: 100%;
  overflow-x: hidden;
}

pingwebfit .filter {
  background-color: #0375ba;
  padding: 5px 7px;
  border-radius: 50%;
}

pingwebfit b,
pingwebfit strong {
  font-weight: 600;
}

pingwebfit input[type='submit'],
pingwebfit input[type='text'],
pingwebfit textarea {
  -webkit-appearance: none;
  border-radius: none;
}

pingwebfit .blockquote,
pingwebfit .mix-section_userContent blockquote {
  background-color: #2e3134;
  color: #f8f8f8;
  letter-spacing: 0.15rem;
  line-height: 1.4;
  margin: 3rem 0 3rem -3rem;
  padding: 4rem 3rem 4rem 6rem;
  position: relative;
  text-transform: uppercase;
}

pingwebfit .blockquote_light,
pingwebfit .mix-section_userContent .blockquote_light {
  background-color: #f8f8f8;
  color: #616a70;
}

pingwebfit .blockquote::after {
  background-color: #75ba33;
  bottom: 4rem;
  left: calc(3rem - 6px);
  position: absolute;
  top: 4rem;
  width: 6px;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_0_0.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_21_0.woff) format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_4_0.woff) format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_5_0.woff) format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/ProximaNova-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_3_0.woff) format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_2C_0.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_D_0.woff) format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_2A_0.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_2B_0.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_2E_0.woff) format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url(./webfonts/34B530_2F_0.woff) format('woff');
  font-weight: 100;
  font-style: italic;
}

pingwebfit {
  font-family: 'Proxima Nova', Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
}

pingwebfit .txt_caption,
pingwebfit .txt_disclaimer {
  font-style: italic;
  font-size: 0.8rem;
}

@media (max-width: 425px) {
  pingwebfit {
    font-size: 16px;
  }
}

pingwebfit .hdg {
  margin-bottom: 0.5em;
}

pingwebfit .hdg_1 {
  font-size: 2.67rem;
  font-weight: 700;
}

@media (max-width: 600px) {
  .hdg_1 {
    font-size: 1.33rem;
  }
}

@media (max-width: 425px) {
  .hdg_1 {
    font-size: 1.5rem;
  }
}

pingwebfit .hdg_2 {
  font-size: 2rem;
  font-weight: 400;
}

@media (max-width: 600px) {
  .hdg_2 {
    font-size: 1.22rem;
  }
}

@media (max-width: 425px) {
  .hdg_2 {
    font-size: 1.375rem;
  }
}

pingwebfit .hdg_3 {
  font-size: 1.78rem;
  font-weight: 300;
}

pingwebfit .hdg_4,
pingwebfit .hdg_5,
pingwebfit .hdg_6 {
  font-weight: 600;
}

@media (max-width: 600px) {
  .hdg_3 {
    font-size: 1.11rem;
  }
}

@media (max-width: 425px) {
  .hdg_3 {
    font-size: 1.25;
  }
}

pingwebfit .hdg_4 {
  font-size: 1.33rem;
}

@media (max-width: 600px) {
  .hdg_4 {
    font-size: 1rem;
  }
}

@media (max-width: 425px) {
  .hdg_4 {
    font-size: 1.125rem;
  }
}

pingwebfit .hdg_5 {
  font-size: 1rem;
}

@media (max-width: 425px) {
  .hdg_5 {
    font-size: 1.125rem;
  }
}

pingwebfit .hdg_6 {
  font-size: 0.9rem;
}

pingwebfit .icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
}

pingwebfit .link_utilityMenu,
pingwebfit .mix-icon_bottom {
  vertical-align: bottom;
}

pingwebfit .icon g {
  stroke: currentColor;
}

pingwebfit .icon_spin {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-animation: spin 1s infinite;
  animation: spin 1s infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

pingwebfit .mix-icon_big {
  height: 1.75em;
  width: 1.75em;
}

pingwebfit .mix-icon_bigest {
  height: 2.5em;
  width: 2.5em;
}

pingwebfit .mix-icon_medium {
  height: 1.25em;
  width: 1.25em;
}

pingwebfit .mix-icon_blue {
  color: #0371b5;
}

pingwebfit .mix-icon_titanium {
  color: #616a70;
}

pingwebfit .mix-icon_black {
  color: #000;
}

pingwebfit .mix-icon_red {
  color: #d40000;
}

pingwebfit .mix-icon_mist {
  color: #c3c3c3;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 320px) {
  .icon-hideAt_phoneSm {
    display: none;
  }
}

@media (max-width: 425px) {
  .hdg_6 {
    font-size: 1rem;
  }

  .icon-hideAt_phoneLg {
    display: none;
  }
}

@media (max-width: 900px) {
  .icon-hideAt_tableLg {
    display: none;
  }
}

@media (max-width: 768px) {
  .blockquote,
  .mix-section_userContent blockquote {
    margin-right: -30px;
    padding-right: calc(3rem + 30px);
  }

  .icon-hideAt_tablet {
    display: none;
  }
}

@media (max-width: 600px) {
  .icon-hideAt_tabletSm {
    display: none;
  }
}

@media (max-width: 1024px) {
  .icon-hideAt_laptop {
    display: none;
  }
}

@media (max-width: 1440px) {
  .icon-hideAt_desktop {
    display: none;
  }
}

@media (max-width: 1240px) {
  .icon-hideAt_maxContent {
    display: none;
  }
}

pingwebfit .link {
  position: relative;
  display: inline-block;
  color: #0371b5;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  padding: 0;
}

pingwebfit .link:after {
  display: block;
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  -webkit-transform: translateY(0.5em);
  transform: translateY(0.5em);
  border-bottom: 1px solid transparent;
}

pingwebfit .link.active:after,
pingwebfit .link:active:after,
pingwebfit .link:hover:after,
pingwebfit .link_longArrowRight:focus:after {
  -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  border-color: #0371b5;
  border-width: 2px;
}

pingwebfit .link.active,
pingwebfit .link:active,
pingwebfit .link:hover {
  color: #0371b5;
  outline: 0;
}

pingwebfit .link.active:after,
pingwebfit .link:active:after,
pingwebfit .link:hover:after {
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateY(0.25em);
  transform: translateY(0.25em);
}

pingwebfit .txt .link.active:after,
pingwebfit .txt .link:active:after,
pingwebfit .txt .link:hover:after {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

pingwebfit .link:focus {
  -webkit-transition: none;
  transition: none;
}

pingwebfit .link:focus:after {
  content: none;
  -webkit-transition: none;
  transition: none;
}

pingwebfit .link[disabled] {
  cursor: auto;
  color: #616a70;
}

pingwebfit .link[disabled]:hover {
  color: #616a70;
}

pingwebfit .link_sm {
  font-size: 0.8em;
}

pingwebfit .link_bold {
  font-weight: 700;
}

pingwebfit .link_mainMenu,
pingwebfit .link_subMenu {
  font-weight: 600;
  text-transform: uppercase;
}

pingwebfit .link_hdg4:hover:after {
  bottom: 0.5em;
}

pingwebfit .link_longArrowLeft svg {
  margin-right: 5px;
}

pingwebfit .link_longArrowRight:focus {
  color: #0371b5;
}

pingwebfit .link_longArrowRight:focus:after {
  content: '';
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateY(0.25em);
  transform: translateY(0.25em);
}

pingwebfit .link_mobileMenu:after,
pingwebfit .link_mobileSideNav:after,
pingwebfit .link_utilityMenu:after {
  content: none;
}

pingwebfit .txt .link_longArrowRight:focus:after {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

pingwebfit .link_black,
pingwebfit .link_black:active,
pingwebfit .link_black:hover {
  color: #000;
}

pingwebfit .link_black:active:after,
pingwebfit .link_black:hover:after {
  border-color: #000;
}

pingwebfit .link_white {
  color: #fff;
}

pingwebfit .link_white:active,
pingwebfit .link_white:hover {
  color: #0371b5;
}

pingwebfit .link_white:active:after,
pingwebfit .link_white:hover:after {
  border-color: #0371b5;
}

pingwebfit .link_mainMenu {
  color: #000;
  font-size: 0.9rem;
  letter-spacing: 0.1em;
}

pingwebfit .link_mainMenu:after {
  -webkit-transform: translateY(1em);
  transform: translateY(1em);
}

pingwebfit .link_mainMenu.js-active,
pingwebfit .link_mainMenu:active,
pingwebfit .link_mainMenu:hover {
  color: #0371b5;
}

pingwebfit .link_mainMenu.js-active:after,
pingwebfit .link_mainMenu:active:after,
pingwebfit .link_mainMenu:hover:after {
  -webkit-transform: translateY(0.5em);
  transform: translateY(0.5em);
  border-color: #0371b5;
  border-width: 4px;
}

pingwebfit .link_subMenu {
  font-size: 0.8rem;
  letter-spacing: 0.15em;
}

pingwebfit .link_dialogClose,
pingwebfit .link_skipLink {
  font-weight: 600;
  letter-spacing: 0.1em;
}

pingwebfit .link_subMenu .icon {
  margin-top: -3px;
}

pingwebfit .link_utilityMenu {
  color: #000;
}

pingwebfit .btn > *,
pingwebfit .checkbox-style > *,
pingwebfit .input-label_inline,
pingwebfit .input_inline-field,
pingwebfit .radio-style > * {
  vertical-align: middle;
}

pingwebfit .link_utilityMenu:active,
pingwebfit .link_utilityMenu:focus,
pingwebfit .link_utilityMenu:hover {
  color: #0371b5;
  outline: 0;
  text-decoration: underline;
}

pingwebfit .link_searchResult {
  font-size: 1.33rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

pingwebfit .link_mainMenu_search:active,
pingwebfit .link_mainMenu_search:hover {
  color: #0371b5;
}

pingwebfit .link_mobileMenu,
pingwebfit .link_mobileMenu:active,
pingwebfit .link_mobileMenu:hover {
  color: #000;
}

pingwebfit .link_mainMenu_search:active:after,
pingwebfit .link_mainMenu_search:hover:after {
  border-color: transparent;
}

pingwebfit .link_mobileSideNav,
pingwebfit .link_mobileSideNav:active,
pingwebfit .link_mobileSideNav:hover {
  color: #fff;
}

pingwebfit .link_mobileMenu:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 5px;
}

pingwebfit .link_mobileSideNav_isCurrentPage {
  position: relative;
}

pingwebfit .link_mobileSideNav_isCurrentPage:before {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  height: calc(100% - 6px);
  width: 0;
  left: -0.75em;
  border-left: 3px solid #fff;
}

pingwebfit .link_footer:active:after,
pingwebfit .link_footer:after,
pingwebfit .link_footer:focus:after,
pingwebfit .link_footer:hover:after {
  content: ' ';
  display: block;
  border: none;
  height: 1.15em;
  position: relative;
}

pingwebfit .link_footer {
  color: #000;
}

pingwebfit .link_footer:active,
pingwebfit .link_footer:focus,
pingwebfit .link_footer:hover {
  color: #0371b5;
  outline: 0;
}

pingwebfit .link_footer_sub,
pingwebfit .link_footer_sub:active,
pingwebfit .link_footer_sub:focus,
pingwebfit .link_footer_sub:hover {
  color: #f1f1f1;
}

pingwebfit .link_footer_sub + .link_footer_sub {
  margin-left: 1rem;
}

pingwebfit .link_footer_sub:active:after,
pingwebfit .link_footer_sub:focus:after,
pingwebfit .link_footer_sub:hover:after {
  border-color: #f1f1f1;
}

pingwebfit .link_blockWrapper {
  display: inline-block;
}

pingwebfit .link_blockWrapper:focus img {
  outline: 0;
}

pingwebfit .link_blockWrapper:after {
  content: none;
}

pingwebfit .link_dialogClose {
  font-size: 0.8rem;
}

pingwebfit .link_dialogClose:focus {
  color: #0371b5;
  outline: 0;
}

pingwebfit .link_sideNav,
pingwebfit .link_skipLink.active,
pingwebfit .link_skipLink:active,
pingwebfit .link_skipLink:hover {
  color: #000;
}

pingwebfit .link_dialogClose:focus:after {
  content: '';
  -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateY(0.25em);
  transform: translateY(0.25em);
  border-color: #0371b5;
  border-width: 2px;
}

pingwebfit .txt .link_dialogClose:focus:after {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

pingwebfit .link_termDefinition {
  border-bottom: 1px dashed #0371b5;
  font-weight: 600;
}

pingwebfit .link_termDefinition:after {
  content: none;
}

pingwebfit .btn_pageNav:after,
pingwebfit .btn_pageNav_prevNext:hover:after,
pingwebfit .link_skipLink:focus:after,
pingwebfit .radio-input:checked + .radio-style > .radio-style-box::after {
  content: '';
}

pingwebfit .link_termDefinition:hover {
  border-color: #0371b5;
}

pingwebfit .link_termDefinition:focus {
  outline: #0371b5 dashed 1px;
  outline-offset: -1px;
}

pingwebfit .link_skipLink {
  margin-left: calc((100vw - 1240px) / 2);
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: #000;
  font-size: 1rem;
  opacity: 0;
  padding: 1.5rem 2rem;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  display: block;
  -webkit-transform: translateY(-100%) translateY(-4px);
  transform: translateY(-100%) translateY(-4px);
  -webkit-transition: opacity 0 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, opacity 0 0.3s ease;
  transition: transform 0.3s ease, opacity 0 0.3s ease,
    -webkit-transform 0.3s ease;
  z-index: 20;
}

pingwebfit ol.txt,
pingwebfit ul.txt {
  margin-top: 1em;
  margin-bottom: 1 em;
  padding-left: 40px;
}

@media (max-width: 1240px) {
  .link_skipLink {
    margin-left: 25px;
  }
}

@media (max-width: 768px) {
  .link_skipLink {
    margin-left: 15px;
  }
}

pingwebfit .link_skipLink:after {
  width: calc(100% - 4rem);
  bottom: 1.5rem;
}

pingwebfit .link_skipLink:focus {
  opacity: 1;
  outline: #0371b5 dotted 1px;
  outline-offset: -1rem;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  top: 0;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

pingwebfit .link_skipLink:focus:after {
  -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
}

pingwebfit .mix-link_caps {
  text-transform: uppercase;
  font-size: 90%;
  letter-spacing: 1px;
}

pingwebfit .link.ping-bold {
  font-weight: 700;
}

pingwebfit .txt {
  font-size: 0.9rem;
  line-height: 1.4;
}

pingwebfit ol.txt {
  list-style-type: decimal;
}

pingwebfit ul.txt {
  list-style-type: disc;
}

pingwebfit ol.txt > li,
pingwebfit ul.txt > li {
  display: list-item;
}

pingwebfit .txt_upper {
  text-transform: uppercase;
}

pingwebfit .txt_lg {
  font-size: 1rem;
}

@media (max-width: 425px) {
  .txt_lg {
    font-size: 0.9rem;
  }
}

pingwebfit .txt_xLg {
  font-size: 1.5rem;
}

@media (max-width: 425px) {
  .txt_xLg {
    font-size: calc(1.5rem * 0.85);
  }
}

pingwebfit .txt_sm {
  font-size: 0.9rem;
  font-weight: 300;
}

pingwebfit .txt_xSm {
  font-size: 0.8rem;
  font-weight: 300;
}

pingwebfit .txt_bold {
  font-weight: 700;
}

pingwebfit .txt_isBad {
  color: #d40000;
}

pingwebfit .txt_gray {
  color: #f1f1f1;
}

pingwebfit .txt_right {
  text-align: right;
}

pingwebfit .able,
pingwebfit .input_left {
  text-align: left;
}

pingwebfit .txt_caption {
  padding: 1rem 0;
  color: #616a70;
}

pingwebfit .txt_warn {
  color: #d40000;
}

pingwebfit .txt_disclaimer {
  color: #616a70;
}

pingwebfit .txt_vr {
  margin-bottom: 2rem;
}

pingwebfit .txt_caption_invert {
  color: #e1e1e1;
}

pingwebfit .mix-txt_pSpc {
  margin-bottom: 1em;
}

pingwebfit .able {
  z-index: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

pingwebfit .able .able-media-container .able-big-play-button {
  display: none !important;
}

pingwebfit .able .able-descriptions {
  width: 100%;
}

pingwebfit .able-seekbar {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

pingwebfit .able-seekbar-head {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

pingwebfit .able-transcript {
  color: #000;
}

pingwebfit .cms-bootstrap [class*=' icon-'],
pingwebfit .cms-bootstrap [class^='icon-'] {
  font-family: Core-icons !important;
}

pingwebfit .grecaptcha-badge {
  z-index: 10;
}

pingwebfit .grecaptcha-badge.js-hover {
  right: -4px !important;
}

pingwebfit .checkbox-input {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  position: absolute;
}

pingwebfit .checkbox-style,
pingwebfit .checkbox-style > * {
  display: inline-block;
}

pingwebfit .checkbox-style-label {
  color: #616a70;
  text-transform: none;
  cursor: pointer;
  white-space: normal;
}

pingwebfit .checkbox-style-box {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 2px solid #616a70;
  background-color: #f8f8f8;
}

pingwebfit .checkbox-input:disabled + .checkbox-style > .checkbox-style-box {
  border-color: #c3c3c3;
  cursor: default;
}

pingwebfit .checkbox-input + .checkbox-style > .checkbox-style-box {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
  -webkit-transition: all var(--DEFAULT_TRANSITION_LENGTH) ease;
  transition: all var(--DEFAULT_TRANSITION_LENGTH) ease;
}

pingwebfit .checkbox-input:focus + .checkbox-style > .checkbox-style-label {
  outline: #0371b5 dotted 1px;
}

pingwebfit .checkbox-input:checked + .checkbox-style > .checkbox-style-box {
  background-color: #0371b5;
  border-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 14 11" style="fill:white;" xml:space="preserve"><polyline class="st0" points="3.7,10 4.7,11 14,2.1 11.8,0 4.7,6.8 2.2,4.3 0,6.4 3.7,10 "/></svg>');
  background-repeat: no-repeat;
  background-size: 14px 11px;
  background-position: center;
}

pingwebfit .checkbox-input:checked + .checkbox-style > .checkbox-style-label {
  color: inherit;
}

pingwebfit
  .checkbox-input:disabled
  + .checkbox-style:hover
  > .checkbox-style-box {
  border-color: #c3c3c3;
  cursor: default;
  color: #616a70;
  background-image: none;
}

pingwebfit .checkbox-input:disabled + .checkbox-style > .checkbox-style-label {
  color: #616a70;
  cursor: default;
}

pingwebfit
  .mix-section_black
  .checkbox-input
  + .checkbox-style
  > .checkbox-style-label {
  color: #f8f8f8;
}

pingwebfit
  .mix-section_black
  .checkbox-input:checked
  + .checkbox-style
  > .checkbox-style-label {
  color: #fff;
}

pingwebfit .input {
  display: block;
  width: 100%;
  position: relative;
}

pingwebfit .input_vr {
  margin-bottom: 2rem;
}

pingwebfit .input_vr_half {
  margin-bottom: 1rem;
}

pingwebfit .ErrorLabel {
  color: #d40000;
}

pingwebfit .input_inline {
  display: inline-block;
  width: auto;
}

pingwebfit .input-field {
  display: block;
  width: 100%;
  position: relative;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: 2px solid #c3c3c3;
  font: inherit;
  outline: 0;
}

pingwebfit .input_isBad .input-field,
pingwebfit .input_isBad .input-field:focus {
  border-color: #d40000;
  color: #d40000;
}

pingwebfit .input_isGood .input-field {
  border-color: #3b7800;
}

pingwebfit .input-field:disabled::-webkit-input-placeholder {
  color: #f1f1f1;
}

pingwebfit .input-field:disabled::-ms-input-placeholder {
  color: #f1f1f1;
}

pingwebfit .input-field:disabled::-moz-placeholder {
  color: #f1f1f1;
}

pingwebfit .input-field::-webkit-input-placeholder {
  color: #2e3134;
}

pingwebfit .input-field::-moz-placeholder {
  color: #2e3134;
}

pingwebfit .input-field:-ms-input-placeholder {
  color: #2e3134;
}

pingwebfit .input-field:disabled {
  border-color: #f1f1f1;
}

pingwebfit .input-field:focus {
  border-color: #0371b5;
}

pingwebfit .input_inline-field {
  display: inline-block;
  width: auto;
}

pingwebfit .input_inline-label + .input_inline-field {
  margin-left: 1rem;
}

pingwebfit select.input-field {
  visibility: hidden;
}

pingwebfit select.input-field_chat {
  visibility: visible;
  height: 50px;
}

pingwebfit .kForm,
pingwebfit .sideNav {
  visibility: hidden;
}

pingwebfit .input-field_big {
  padding: 0.5rem 2rem 0.5rem 1.5rem;
  border-radius: 0.2rem;
  border: 2px solid transparent;
}

pingwebfit .input-field_multiRow {
  height: 15rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

pingwebfit .input-field_select {
  border: none;
  padding: 0;
  position: relative;
}

pingwebfit .input-field_select select::-ms-expand {
  display: none;
}

pingwebfit .input-field_select-select {
  border: 2px solid #c3c3c3;
  border-radius: 0;
  display: block;
  padding: 0.5rem 3rem 0.5rem 1rem;
  margin: 0;
  background: #fff;
  color: #000000;
  line-height: 26px;
  font: inherit;
  width: 100%;
  height: 48px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media (max-width: 425px) {
  .input-field_select-select {
    height: 42px;
  }
}

pingwebfit .input-field_select-select_darkBg {
  border: 2px solid transparent;
  border-radius: 0.2em;
  -webkit-appearance: none;
  -moz-appearance: none;
}

pingwebfit .input-field_select-select_darkBg::-ms-expand {
  display: none;
}

pingwebfit .input-field_select-select:disabled {
  border-color: #f1f1f1;
}

pingwebfit .input.input_isBad .input-field_select-select {
  border-color: #d40000;
}

pingwebfit .input.input_isGood .input-field_select-select {
  border-color: #3b7800;
}

pingwebfit .input-field_select-select:focus {
  border-color: #0371b5;
}

pingwebfit .input-field_select-arrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 100%;
  font-size: 1.5rem;
  color: #0371b5;
  pointer-events: none;
  border-left: 2px solid #bbb;
  text-align: center;
  outline: 0;
}

pingwebfit .input_isGood .input-field_select-arrow {
  color: #3b7800;
  border-color: #3b7800;
}

pingwebfit .input_isBad .input-field_select-arrow {
  border-color: #d40000;
  color: #d40000;
}

pingwebfit .input-field_select-select:disabled ~ .input-field_select-arrow {
  border-color: #f1f1f1;
}

pingwebfit .input-field_select-select:focus ~ .input-field_select-arrow {
  border-color: #0371b5;
}

pingwebfit .input-field_select-arrow > .icon {
  display: block;
  height: 1rem;
  margin: 0.8rem auto;
  width: 1rem;
}

pingwebfit .input-error,
pingwebfit .input-label {
  font-size: 0.9rem;
  text-transform: uppercase;
}

pingwebfit .input-error_alternate {
  text-align: left;
  margin-bottom: 5px;
}

pingwebfit .carousel-button,
pingwebfit .input-success,
pingwebfit .reviewSummary_center [data-bv-show='rating_summary'] {
  text-align: center;
}

pingwebfit .input-label {
  color: #616a70;
  line-height: 1.5;
}

pingwebfit .input-label_noTxtStyle {
  font-size: inherit;
  text-transform: inherit;
  color: inherit;
}

pingwebfit .input-label_inline {
  display: inline-block;
}

pingwebfit .input-label_invisible {
  position: absolute;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

pingwebfit .input-label_invisible > :first-child {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  position: absolute;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

pingwebfit .mix-input-label_big {
  text-transform: none;
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

pingwebfit .input-error {
  display: none;
  color: #d40000;
}

pingwebfit .input_isBad .input-error {
  display: inline;
}

pingwebfit .input-success {
  display: none;
  position: absolute;
  z-index: 1;
  color: #3b7800;
  bottom: calc(0.25em + 11px);
  right: 1em;
  width: 1em;
  height: 1em;
  pointer-events: none;
}

pingwebfit .input-success .icon,
pingwebfit .input_isGood .input-success {
  display: block;
}

pingwebfit .input-success-txt {
  display: none;
  color: #3b7800;
}

pingwebfit .input_isGood .input-success-txt {
  display: inline;
}

pingwebfit .input-error_darkBg {
  color: #ff3b3b;
}

pingwebfit .blockInputs .checkbox,
pingwebfit .blockInputs .input,
pingwebfit .blockInputs .radio {
  margin-top: 18px !important;
}

pingwebfit .blockInputs .radio-style-label {
  max-width: 95%;
}

pingwebfit .ecomm__quantity__input {
  width: 48px;
  padding-left: 17px;
  border: 2px solid #c3c3c3;
  margin-right: 7px;
}

pingwebfit .ecomm__qty___productDetail {
  width: 88px;
}

pingwebfit .ecomm__cvc__pay {
  width: 191px;
}

pingwebfit .ecomm__cvc__pay > div:first-of-type {
  width: 60%;
  min-width: 110px;
}

pingwebfit select[multiple] {
  height: 125px;
}

pingwebfit select[multiple] + .input-field_select-arrow {
  display: none;
}

pingwebfit .radio-input {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  position: absolute;
}

pingwebfit .radio-style {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

pingwebfit .radio-style > * {
  display: inline-block;
}

pingwebfit .flexLayout,
pingwebfit .flexLayout_center {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

pingwebfit .radio-style-label {
  color: #616a70;
  text-transform: none;
  cursor: pointer;
  white-space: normal;
}

pingwebfit .btn,
pingwebfit .dataTable-notes-hd,
pingwebfit .dataTable-table th {
  text-transform: uppercase;
}

pingwebfit .radio-style-box {
  width: 14px;
  height: 14px;
  border: 2px solid #616a70;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  white-space: normal;
  position: relative;
}

pingwebfit .radio-input:disabled + .radio-style > .radio-style-box {
  border-color: #c3c3c3;
  cursor: default;
}

pingwebfit .radio-input + .radio-style > .radio-style-box {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

pingwebfit
  .radio-input:hover:not(:disabled)
  + .radio-style
  > .radio-style-box::after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  top: 20%;
  left: 20%;
  border-radius: 50%;
  background-color: #616a70;
}

pingwebfit
  .radio-input:checked:hover:not(:disabled)
  + .radio-style
  > .radio-style-box::after {
  background-color: #0371b5;
}

pingwebfit .radio-input:checked + .radio-style > .radio-style-box {
  border-color: #0371b5;
  background-color: #fff;
}

pingwebfit .radio-input:focus + .radio-style > .radio-style-label {
  outline: #0371b5 dotted 1px;
  outline-offset: 2px;
}

pingwebfit .radio-input:checked + .radio-style > .radio-style-label {
  color: #000;
}

pingwebfit .radio-input:checked + .radio-style > .radio-style-box::after {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 20%;
  left: 20%;
  border-radius: 50%;
  background-color: #0371b5;
}

pingwebfit .radio-input:disabled + .radio-style:hover > .radio-style-box {
  border-color: #c3c3c3;
  cursor: default;
}

pingwebfit .radio-input:disabled + .radio-style > .radio-style-label {
  color: #616a70;
}

pingwebfit .flexLayout {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

pingwebfit .flexLayout_outside {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

pingwebfit .flexLayout_center {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

pingwebfit .flexLayout_sidebar {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@media (min-width: 1025px) {
  .flexLayout_sidebar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .flexLayout_sidebar > :first-child {
    width: 260px;
    min-width: 260px;
    padding-right: 20px;
  }

  .flexLayout_sidebar > :last-child {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 1px;
  }
}

pingwebfit .mix-flexLayout_wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 425px) {
  .mix-flexLayout_stretchAt_phoneLg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mix-flexLayout_stretchAt_phoneLg > * {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

pingwebfit .mix-flexLayout_baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

pingwebfit .mix-flexLayout_top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

pingwebfit .mix-flexLayout_padded > * {
  margin: 0.5rem 1rem 0.5rem 0;
}

pingwebfit .mix-flexLayout_padded > :last-child {
  margin-right: 0;
}

pingwebfit .mix-flexLayout_vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 320px) {
  .mix-flexLayout_stackAt_phoneSm {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_phoneSm > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_phoneSm.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

@media (max-width: 425px) {
  .mix-flexLayout_stackAt_phoneLg {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_phoneLg > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_phoneLg.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

@media (max-width: 900px) {
  .mix-flexLayout_stackAt_tableLg {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_tableLg > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_tableLg.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .mix-flexLayout_stackAt_tablet {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_tablet > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_tablet.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .mix-flexLayout_stackAt_tabletSm {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_tabletSm > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_tabletSm.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .mix-flexLayout_stackAt_laptop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_laptop > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_laptop.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

@media (max-width: 1440px) {
  .mix-flexLayout_stackAt_desktop {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_desktop > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_desktop.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

@media (max-width: 1240px) {
  .mix-flexLayout_stackAt_maxContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mix-flexLayout_stackAt_maxContent > * + * {
    margin-top: 1rem;
  }

  .mix-flexLayout_stackAt_maxContent.mix-flexLayout_padded > * + * {
    margin-left: 0;
  }
}

pingwebfit .img {
  height: auto;
  width: 100%;
}

pingwebfit .img_aligned[align='left'] {
  margin-right: 2em;
}

pingwebfit .img_aligned[align='right'] {
  margin-left: 2em;
}

pingwebfit .section {
  position: relative;
  width: 100%;
  padding: 60px 0;
  background-color: #fff;
}

@media (max-width: 768px) {
  .section {
    padding: 39.6px 0;
  }
}

@media (max-width: 425px) {
  .section {
    padding: 30px 0;
  }
}

pingwebfit .mix-section_collapsed {
  padding: 0;
}

pingwebfit .mix-section_skinny {
  padding: 30px 0;
}

@media (max-width: 768px) {
  .mix-section_skinny {
    padding: 19.8px 0;
  }
}

@media (max-width: 425px) {
  .mix-section_skinny {
    padding: 15px 0;
  }
}

pingwebfit .mix-section_collapsed_bottom {
  padding-bottom: 0;
}

pingwebfit .mix-section_skinny_sides {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 768px) {
  .mix-section_skinny_sides {
    padding: 9.9px 0;
  }
}

@media (max-width: 425px) {
  .mix-section_skinny_sides {
    padding: 7.5px 0;
  }
}

pingwebfit .mix-section_skinny_top {
  padding: 30px 0 60px;
}

@media (max-width: 768px) {
  .mix-section_skinny_top {
    padding-top: 19.8px;
    padding-bottom: 39.6px;
  }
}

@media (max-width: 425px) {
  .mix-section_skinny_top {
    padding-top: 15px;
    padding-bottom: 30px;
  }
}

pingwebfit .mix-section_skinnier {
  padding: 20px 0;
}

@media (max-width: 768px) {
  .mix-section_skinnier {
    padding: 13.2px 0;
  }
}

@media (max-width: 425px) {
  .mix-section_skinnier {
    padding: 10px 0;
  }
}

pingwebfit .mix-section_fat {
  padding: 90px 0;
}

@media (max-width: 768px) {
  .mix-section_fat {
    padding: 39.6px 0;
  }
}

@media (max-width: 425px) {
  .mix-section_fat {
    padding: 30px 0;
  }
}

pingwebfit .mix-section_collapsedBottom {
  padding-bottom: 0;
}

pingwebfit .mix-section_collapsedTop {
  padding-top: 0;
}

pingwebfit .mix-section_divided {
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}

pingwebfit .mix-section_fixed {
  position: fixed;
  top: 0;
  z-index: 10;
}

pingwebfit .tileBox > *,
pingwebfit body.js-kenticoEditMode .mix-section_fixed {
  position: relative;
}

pingwebfit .mix-section_contrastColor {
  background-color: #f8f8f8;
}

pingwebfit .mix-section_grey {
  background-color: #f1f1f1;
}

pingwebfit .mix-section_darkGrey {
  background-color: #2e3134;
  color: #fff;
}

pingwebfit .mix-section_gradient {
  background: linear-gradient(
    135deg,
    #131315 0,
    #263842 47%,
    #263842 55%,
    #131315 100%
  );
}

pingwebfit .mix-section_black {
  background-color: #000;
  color: #fff;
}

@media (max-width: 600px) {
  .img_aligned[align='left'],
  .img_aligned[align='right'] {
    float: none;
    height: auto;
    margin: 2rem 0;
    width: 100%;
  }

  .mix-section_swoosh {
    background-image: none;
  }
}

pingwebfit .section-wrapper {
  max-width: 1240px;
  margin: 0 auto;
}

@media (max-width: 1240px) {
  .section-wrapper {
    max-width: calc(100% - 50px);
  }
}

@media (max-width: 768px) {
  .mix-section_swoosh_left {
    background-image: none;
  }

  .section-wrapper {
    max-width: calc(100% - 30px);
  }
}

pingwebfit .mix-section-wrapper_skinny {
  max-width: 925px;
}

@media (max-width: 975px) {
  .mix-section-wrapper_skinny {
    max-width: calc(100% - 50px);
  }
}

@media (max-width: 768px) {
  .mix-section-wrapper_skinny {
    max-width: calc(100% - 30px);
  }
}

pingwebfit .mix-section-wrapper_skinnier {
  max-width: 810px;
}

@media (max-width: 768px) {
  .mix-section-wrapper_skinnier {
    max-width: calc(100% - 30px);
  }
}

pingwebfit .mix-section-wrapper_padding.leftright {
  padding-left: 2rem;
  padding-right: 2rem;
}

pingwebfit .mix-section-wrapper_padding.topbottom {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

pingwebfit .mix-section-wrapper_padding.top0_5Rem {
  padding-top: 0.5rem;
}

pingwebfit .mix-section-wrapper_padding.bottom0_5Rem {
  padding-bottom: 0.5rem;
}

pingwebfit .mix-section-wrapper_padding.top1_5Rem {
  padding-top: 1.5rem;
}

pingwebfit .mix-section-wrapper_padding.bottom1_5Rem {
  padding-bottom: 1.5rem;
}

pingwebfit .tileBox {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow-x: hidden;
}

@media (min-width: 655px) {
  .tileBox_landing_width {
    min-width: 625px;
  }
}

@media (min-width: 350px) and (max-width: 654px) {
  .tileBox_landing_width {
    min-width: 330px;
  }
}

@media (max-width: 349px) {
  .tileBox_landing_width {
    min-width: 200px;
  }
}

pingwebfit .tileBox_multiRow {
  overflow-x: unset;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

pingwebfit .tileBox_1up > * {
  -ms-flex-preferred-size: calc(100% / 1);
  flex-basis: calc(100% / 1);
  max-width: calc(100% / 1);
  min-width: calc(100% / 1);
}

@media (max-width: 1024px) {
  .mix-section_swoosh_left {
    background-position: -150px center;
  }

  .tileBox_1up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 768px) {
  .tileBox_1up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 600px) {
  .tileBox_1up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 425px) {
  .tileBox_1up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 320px) {
  .tileBox_1up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_1up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 1) - 0px);
  flex-basis: calc((100% / 1) - 0px);
  margin: 0 calc(0 - 0.01px);
  max-width: calc((100% / 1) - 0px);
  min-width: calc((100% / 1) - 0px);
}

pingwebfit .tileBox_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_1up_padded.tileBox_noCollapse > :nth-child(1n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_1up_padded.tileBox_noCollapse > :nth-child(1n) {
  margin-right: 0;
}

pingwebfit .tileBox_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_1_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(0 - 0.01px);
}

pingwebfit .tileBox_1_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_1_1up_padded.tileBox_noCollapse > :nth-child(1n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_1_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_1_1up_padded.tileBox_noCollapse > :nth-child(1n) {
  margin-right: 0;
  max-width: calc(((100% / 2)) - -20px);
  min-width: calc(((100% / 2)) - -20px);
}

pingwebfit .tileBox_1_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_1up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_1up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_1up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_1up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_1up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_1up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_1_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_1_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_1up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 1) - 0px);
  flex-basis: calc((100% / 1) - 0px);
  margin: 0;
  max-width: calc((100% / 1) - 0px);
  min-width: calc((100% / 1) - 0px);
}

pingwebfit .tileBox_1up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_1up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_1up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_1up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_1up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_1up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_1up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_1up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .tileBox_2up > * {
  -ms-flex-preferred-size: calc(100% / 2);
  flex-basis: calc(100% / 2);
  max-width: calc(100% / 2);
  min-width: calc(100% / 2);
}

@media (max-width: 1024px) {
  .tileBox_2up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 768px) {
  .tileBox_2up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 600px) {
  .tileBox_2up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 425px) {
  .tileBox_2up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 320px) {
  .tileBox_2up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_2up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 2) - 10px);
  flex-basis: calc((100% / 2) - 10px);
  margin: 0 calc(10px - 0.01px);
  max-width: calc((100% / 2) - 10px);
  min-width: calc((100% / 2) - 10px);
}

pingwebfit .tileBox_2up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_2up_padded.tileBox_noCollapse > :nth-child(2n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_2up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_2up_padded.tileBox_noCollapse > :nth-child(2n) {
  margin-right: 0;
}

pingwebfit .tileBox_2up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_2_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(10px - 0.01px);
}

pingwebfit .tileBox_2_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_2_1up_padded.tileBox_noCollapse > :nth-child(2n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_2_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_2_1up_padded.tileBox_noCollapse > :nth-child(2n) {
  margin-right: 0;
  max-width: calc(((100% / 3)) - 0px);
  min-width: calc(((100% / 3)) - 0px);
}

pingwebfit .tileBox_2_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_2up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_2up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_2up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_2up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_2up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_2up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_2up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_2_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_2_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_2up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 2) - 10px);
  flex-basis: calc((100% / 2) - 10px);
  margin: 0 10px;
  max-width: calc((100% / 2) - 10px);
  min-width: calc((100% / 2) - 10px);
}

pingwebfit .tileBox_2up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_2up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_2up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_2up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_2up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_2up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_2up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_2up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .tileBox_3up > * {
  -ms-flex-preferred-size: calc(100% / 3);
  flex-basis: calc(100% / 3);
  max-width: calc(100% / 3);
  min-width: calc(100% / 3);
}

@media (max-width: 1024px) {
  .tileBox_3up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 768px) {
  .tileBox_3up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 600px) {
  .tileBox_3up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 425px) {
  .tileBox_3up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 320px) {
  .tileBox_3up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_3up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
  flex-basis: calc((100% / 3) - 13.33px);
  margin: 0 calc(10px - 0.01px);
  max-width: calc((100% / 3) - 13.33px);
  min-width: calc((100% / 3) - 13.33px);
}

pingwebfit .tileBox_3up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_3up_padded.tileBox_noCollapse > :nth-child(3n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_3up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_3up_padded.tileBox_noCollapse > :nth-child(3n) {
  margin-right: 0;
}

pingwebfit .tileBox_3up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_3_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(10px - 0.01px);
}

pingwebfit .tileBox_3_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_3_1up_padded.tileBox_noCollapse > :nth-child(3n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_3_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_3_1up_padded.tileBox_noCollapse > :nth-child(3n) {
  margin-right: 0;
  max-width: calc(((100% / 4)) - 6.67px);
  min-width: calc(((100% / 4)) - 6.67px);
}

pingwebfit .tileBox_3_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_3up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_3up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_3up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_3up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_3up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_3up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_3up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_3_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_3_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_3up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
  flex-basis: calc((100% / 3) - 13.33px);
  margin: 0 10px;
  max-width: calc((100% / 3) - 13.33px);
  min-width: calc((100% / 3) - 13.33px);
}

pingwebfit .tileBox_3up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_3up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_3up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 10px;
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_3up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_3up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_3up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_3up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_3up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .tileBox_4up > * {
  -ms-flex-preferred-size: calc(100% / 4);
  flex-basis: calc(100% / 4);
  max-width: calc(100% / 4);
  min-width: calc(100% / 4);
}

@media (max-width: 1024px) {
  .tileBox_4up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 768px) {
  .tileBox_4up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 600px) {
  .tileBox_4up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 425px) {
  .tileBox_4up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 320px) {
  .tileBox_4up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_4up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 4) - 15px);
  flex-basis: calc((100% / 4) - 15px);
  margin: 0 calc(10px - 0.01px);
  max-width: calc((100% / 4) - 15px);
  min-width: calc((100% / 4) - 15px);
}

pingwebfit .tileBox_4up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_4up_padded.tileBox_noCollapse > :nth-child(4n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_4up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_4up_padded.tileBox_noCollapse > :nth-child(4n) {
  margin-right: 0;
}

pingwebfit .tileBox_4up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_4_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(10px - 0.01px);
}

pingwebfit .tileBox_4_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_4_1up_padded.tileBox_noCollapse > :nth-child(4n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_4_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_4_1up_padded.tileBox_noCollapse > :nth-child(4n) {
  margin-right: 0;
  max-width: calc(((100% / 5)) - 10px);
  min-width: calc(((100% / 5)) - 10px);
}

pingwebfit .tileBox_4_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_4up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 4) - 15px);
    flex-basis: calc((100% / 4) - 15px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 4) - 15px);
    min-width: calc((100% / 4) - 15px);
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_4up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_4up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_4up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_4up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_4up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_4up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
    max-width: calc(((100% / 5)) - 10px);
    min-width: calc(((100% / 5)) - 10px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_4_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_4_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_4up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 4) - 15px);
  flex-basis: calc((100% / 4) - 15px);
  margin: 0 10px;
  max-width: calc((100% / 4) - 15px);
  min-width: calc((100% / 4) - 15px);
}

pingwebfit .tileBox_4up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_4up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_4up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 10px;
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_4up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_4up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_4up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_4up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_4up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .tileBox_5up > * {
  -ms-flex-preferred-size: calc(100% / 5);
  flex-basis: calc(100% / 5);
  max-width: calc(100% / 5);
  min-width: calc(100% / 5);
}

@media (max-width: 1024px) {
  .tileBox_5up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 4);
    flex-basis: calc(100% / 4);
    max-width: calc(100% / 4);
    min-width: calc(100% / 4);
  }
}

@media (max-width: 768px) {
  .tileBox_5up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 600px) {
  .tileBox_5up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 425px) {
  .tileBox_5up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

@media (max-width: 320px) {
  .tileBox_5up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_5up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 5) - 16px);
  flex-basis: calc((100% / 5) - 16px);
  margin: 0 calc(10px - 0.01px);
  max-width: calc((100% / 5) - 16px);
  min-width: calc((100% / 5) - 16px);
}

pingwebfit .tileBox_5up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_5up_padded.tileBox_noCollapse > :nth-child(5n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_5up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_5up_padded.tileBox_noCollapse > :nth-child(5n) {
  margin-right: 0;
}

pingwebfit .tileBox_5up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_5_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(10px - 0.01px);
}

pingwebfit .tileBox_5_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_5_1up_padded.tileBox_noCollapse > :nth-child(5n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_5_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_5_1up_padded.tileBox_noCollapse > :nth-child(5n) {
  margin-right: 0;
  max-width: calc(((100% / 6)) - 12px);
  min-width: calc(((100% / 6)) - 12px);
}

pingwebfit .tileBox_5_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_5up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 5) - 16px);
    flex-basis: calc((100% / 5) - 16px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 5) - 16px);
    min-width: calc((100% / 5) - 16px);
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(5n + 1) {
    margin-left: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(5n) {
    margin-right: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_5up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 4) - 15px);
    flex-basis: calc((100% / 4) - 15px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 4) - 15px);
    min-width: calc((100% / 4) - 15px);
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_5up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_5up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_5up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_5up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_5up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(5n + 1) {
    margin-left: 0;
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(5n) {
    margin-right: 0;
    max-width: calc(((100% / 6)) - 12px);
    min-width: calc(((100% / 6)) - 12px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
    max-width: calc(((100% / 5)) - 10px);
    min-width: calc(((100% / 5)) - 10px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_5_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_5_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_5up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 5) - 16px);
  flex-basis: calc((100% / 5) - 16px);
  margin: 0 10px;
  max-width: calc((100% / 5) - 16px);
  min-width: calc((100% / 5) - 16px);
}

pingwebfit .tileBox_5up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_5up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_5up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 4) - 15px);
    flex-basis: calc((100% / 4) - 15px);
    margin: 0 10px;
    max-width: calc((100% / 4) - 15px);
    min-width: calc((100% / 4) - 15px);
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_5up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 10px;
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_5up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_5up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_5up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_5up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .tileBox_6up > * {
  -ms-flex-preferred-size: calc(100% / 6);
  flex-basis: calc(100% / 6);
  max-width: calc(100% / 6);
  min-width: calc(100% / 6);
}

@media (max-width: 1024px) {
  .tileBox_6up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 5);
    flex-basis: calc(100% / 5);
    max-width: calc(100% / 5);
    min-width: calc(100% / 5);
  }
}

@media (max-width: 768px) {
  .tileBox_6up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 600px) {
  .tileBox_6up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 425px) {
  .tileBox_6up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 320px) {
  .tileBox_6up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_6up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 6) - 16.67px);
  flex-basis: calc((100% / 6) - 16.67px);
  margin: 0 calc(10px - 0.01px);
  max-width: calc((100% / 6) - 16.67px);
  min-width: calc((100% / 6) - 16.67px);
}

pingwebfit .tileBox_6up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_6up_padded.tileBox_noCollapse > :nth-child(6n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_6up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_6up_padded.tileBox_noCollapse > :nth-child(6n) {
  margin-right: 0;
}

pingwebfit .tileBox_6up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_6_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(10px - 0.01px);
}

pingwebfit .tileBox_6_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_6_1up_padded.tileBox_noCollapse > :nth-child(6n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_6_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_6_1up_padded.tileBox_noCollapse > :nth-child(6n) {
  margin-right: 0;
  max-width: calc(((100% / 7)) - 13.33px);
  min-width: calc(((100% / 7)) - 13.33px);
}

pingwebfit .tileBox_6_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_6up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 6) - 16.67px);
    flex-basis: calc((100% / 6) - 16.67px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 6) - 16.67px);
    min-width: calc((100% / 6) - 16.67px);
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(6n + 1) {
    margin-left: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(6n) {
    margin-right: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_6up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 5) - 16px);
    flex-basis: calc((100% / 5) - 16px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 5) - 16px);
    min-width: calc((100% / 5) - 16px);
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(5n + 1) {
    margin-left: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(5n) {
    margin-right: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_6up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_6up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_6up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_6up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_6up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(6n + 1) {
    margin-left: 0;
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(6n) {
    margin-right: 0;
    max-width: calc(((100% / 7)) - 13.33px);
    min-width: calc(((100% / 7)) - 13.33px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(5n + 1) {
    margin-left: 0;
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(5n) {
    margin-right: 0;
    max-width: calc(((100% / 6)) - 12px);
    min-width: calc(((100% / 6)) - 12px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_6_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_6_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_6up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 6) - 16.67px);
  flex-basis: calc((100% / 6) - 16.67px);
  margin: 0 10px;
  max-width: calc((100% / 6) - 16.67px);
  min-width: calc((100% / 6) - 16.67px);
}

pingwebfit .tileBox_6up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_6up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_6up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 5) - 16px);
    flex-basis: calc((100% / 5) - 16px);
    margin: 0 10px;
    max-width: calc((100% / 5) - 16px);
    min-width: calc((100% / 5) - 16px);
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_6up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 10px;
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_6up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 10px;
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_6up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_6up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_6up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .tileBox_7up > * {
  -ms-flex-preferred-size: calc(100% / 7);
  flex-basis: calc(100% / 7);
  max-width: calc(100% / 7);
  min-width: calc(100% / 7);
}

@media (max-width: 1024px) {
  .tileBox_7up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 6);
    flex-basis: calc(100% / 6);
    max-width: calc(100% / 6);
    min-width: calc(100% / 6);
  }
}

@media (max-width: 768px) {
  .tileBox_7up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 4);
    flex-basis: calc(100% / 4);
    max-width: calc(100% / 4);
    min-width: calc(100% / 4);
  }
}

@media (max-width: 600px) {
  .tileBox_7up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 425px) {
  .tileBox_7up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 320px) {
  .tileBox_7up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_7up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 7) - 17.14px);
  flex-basis: calc((100% / 7) - 17.14px);
  margin: 0 calc(10px - 0.01px);
  max-width: calc((100% / 7) - 17.14px);
  min-width: calc((100% / 7) - 17.14px);
}

pingwebfit .tileBox_7up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_7up_padded.tileBox_noCollapse > :nth-child(7n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_7up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_7up_padded.tileBox_noCollapse > :nth-child(7n) {
  margin-right: 0;
}

pingwebfit .tileBox_7up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_7_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(10px - 0.01px);
}

pingwebfit .tileBox_7_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_7_1up_padded.tileBox_noCollapse > :nth-child(7n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_7_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_7_1up_padded.tileBox_noCollapse > :nth-child(7n) {
  margin-right: 0;
  max-width: calc(((100% / 8)) - 14.29px);
  min-width: calc(((100% / 8)) - 14.29px);
}

pingwebfit .tileBox_7_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_7up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 7) - 17.14px);
    flex-basis: calc((100% / 7) - 17.14px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 7) - 17.14px);
    min-width: calc((100% / 7) - 17.14px);
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(7n + 1) {
    margin-left: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(7n) {
    margin-right: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_7up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 6) - 16.67px);
    flex-basis: calc((100% / 6) - 16.67px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 6) - 16.67px);
    min-width: calc((100% / 6) - 16.67px);
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(6n + 1) {
    margin-left: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(6n) {
    margin-right: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_7up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 4) - 15px);
    flex-basis: calc((100% / 4) - 15px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 4) - 15px);
    min-width: calc((100% / 4) - 15px);
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_7up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_7up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_7up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_7up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(7n + 1) {
    margin-left: 0;
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(7n) {
    margin-right: 0;
    max-width: calc(((100% / 8)) - 14.29px);
    min-width: calc(((100% / 8)) - 14.29px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(6n + 1) {
    margin-left: 0;
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(6n) {
    margin-right: 0;
    max-width: calc(((100% / 7)) - 13.33px);
    min-width: calc(((100% / 7)) - 13.33px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
    max-width: calc(((100% / 5)) - 10px);
    min-width: calc(((100% / 5)) - 10px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_7_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_7_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_7up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 7) - 17.14px);
  flex-basis: calc((100% / 7) - 17.14px);
  margin: 0 10px;
  max-width: calc((100% / 7) - 17.14px);
  min-width: calc((100% / 7) - 17.14px);
}

pingwebfit .tileBox_7up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_7up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_7up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 6) - 16.67px);
    flex-basis: calc((100% / 6) - 16.67px);
    margin: 0 10px;
    max-width: calc((100% / 6) - 16.67px);
    min-width: calc((100% / 6) - 16.67px);
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_7up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 4) - 15px);
    flex-basis: calc((100% / 4) - 15px);
    margin: 0 10px;
    max-width: calc((100% / 4) - 15px);
    min-width: calc((100% / 4) - 15px);
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_7up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 10px;
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_7up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_7up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_7up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .tileBox_8up > * {
  -ms-flex-preferred-size: calc(100% / 8);
  flex-basis: calc(100% / 8);
  max-width: calc(100% / 8);
  min-width: calc(100% / 8);
}

@media (max-width: 1024px) {
  .tileBox_8up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 7);
    flex-basis: calc(100% / 7);
    max-width: calc(100% / 7);
    min-width: calc(100% / 7);
  }
}

@media (max-width: 768px) {
  .tileBox_8up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 4);
    flex-basis: calc(100% / 4);
    max-width: calc(100% / 4);
    min-width: calc(100% / 4);
  }
}

@media (max-width: 600px) {
  .tileBox_8up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 3);
    flex-basis: calc(100% / 3);
    max-width: calc(100% / 3);
    min-width: calc(100% / 3);
  }
}

@media (max-width: 425px) {
  .tileBox_8up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 2);
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    min-width: calc(100% / 2);
  }
}

@media (max-width: 320px) {
  .tileBox_8up:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc(100% / 1);
    flex-basis: calc(100% / 1);
    max-width: calc(100% / 1);
    min-width: calc(100% / 1);
  }
}

pingwebfit .tileBox_8up_padded.tileBox_noCollapse > * {
  -ms-flex-preferred-size: calc((100% / 8) - 17.5px);
  flex-basis: calc((100% / 8) - 17.5px);
  margin: 0 calc(10px - 0.01px);
  max-width: calc((100% / 8) - 17.5px);
  min-width: calc((100% / 8) - 17.5px);
}

pingwebfit .tileBox_8up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_8up_padded.tileBox_noCollapse > :nth-child(8n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_8up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_8up_padded.tileBox_noCollapse > :nth-child(8n) {
  margin-right: 0;
}

pingwebfit .tileBox_8up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

pingwebfit .tileBox_8_1up_padded.tileBox_noCollapse > * {
  margin: 0 calc(10px - 0.01px);
}

pingwebfit .tileBox_8_1up_padded.tileBox_noCollapse > :first-child,
pingwebfit .tileBox_8_1up_padded.tileBox_noCollapse > :nth-child(8n + 1) {
  margin-left: 0;
}

pingwebfit .tileBox_8_1up_padded.tileBox_noCollapse > :last-child,
pingwebfit .tileBox_8_1up_padded.tileBox_noCollapse > :nth-child(8n) {
  margin-right: 0;
  max-width: calc(((100% / 9)) - 15px);
  min-width: calc(((100% / 9)) - 15px);
}

pingwebfit .tileBox_8_1up_padded.tileBox_noCollapse.tileBox_multiRow > * {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1024px) {
  .tileBox_8up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 8) - 17.5px);
    flex-basis: calc((100% / 8) - 17.5px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 8) - 17.5px);
    min-width: calc((100% / 8) - 17.5px);
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(8n + 1) {
    margin-left: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(8n) {
    margin-right: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_8up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 7) - 17.14px);
    flex-basis: calc((100% / 7) - 17.14px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 7) - 17.14px);
    min-width: calc((100% / 7) - 17.14px);
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(7n + 1) {
    margin-left: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(7n) {
    margin-right: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_8up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 4) - 15px);
    flex-basis: calc((100% / 4) - 15px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 4) - 15px);
    min-width: calc((100% / 4) - 15px);
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_8up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_8up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 calc(10px - 0.01px);
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_8up_padded:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0 calc(0 - 0.01px);
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
  }

  .tileBox_8up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(8n + 1) {
    margin-left: 0;
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(8n) {
    margin-right: 0;
    max-width: calc(((100% / 9)) - 15px);
    min-width: calc(((100% / 9)) - 15px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(7n + 1) {
    margin-left: 0;
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(7n) {
    margin-right: 0;
    max-width: calc(((100% / 8)) - 14.29px);
    min-width: calc(((100% / 8)) - 14.29px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) and (min-width: 601px) {
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n + 1) {
    margin-left: 0;
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(4n) {
    margin-right: 0;
    max-width: calc(((100% / 5)) - 10px);
    min-width: calc(((100% / 5)) - 10px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) and (min-width: 426px) {
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n + 1) {
    margin-left: 0;
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(3n) {
    margin-right: 0;
    max-width: calc(((100% / 4)) - 6.67px);
    min-width: calc(((100% / 4)) - 6.67px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) and (min-width: 321px) {
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(10px - 0.01px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n + 1) {
    margin-left: 0;
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(2n) {
    margin-right: 0;
    max-width: calc(((100% / 3)) - 0px);
    min-width: calc(((100% / 3)) - 0px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > * {
    margin: 0 calc(0 - 0.01px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :first-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n + 1) {
    margin-left: 0;
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :last-child,
  .tileBox_8_1up_padded:not(.tileBox_noCollapse) > :nth-child(1n) {
    margin-right: 0;
    max-width: calc(((100% / 2)) - -20px);
    min-width: calc(((100% / 2)) - -20px);
  }

  .tileBox_8_1up_padded:not(.tileBox_noCollapse).tileBox_multiRow > * {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pingwebfit .tileBox_8up_carousel > * {
  -ms-flex-preferred-size: calc((100% / 8) - 17.5px);
  flex-basis: calc((100% / 8) - 17.5px);
  margin: 0 10px;
  max-width: calc((100% / 8) - 17.5px);
  min-width: calc((100% / 8) - 17.5px);
}

pingwebfit .tileBox_8up_carousel > :first-child {
  margin-left: 0;
}

pingwebfit .tileBox_8up_carousel > :last-child {
  margin-right: 0;
}

@media (max-width: 1024px) {
  .tileBox_8up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 7) - 17.14px);
    flex-basis: calc((100% / 7) - 17.14px);
    margin: 0 10px;
    max-width: calc((100% / 7) - 17.14px);
    min-width: calc((100% / 7) - 17.14px);
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .tileBox_8up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 4) - 15px);
    flex-basis: calc((100% / 4) - 15px);
    margin: 0 10px;
    max-width: calc((100% / 4) - 15px);
    min-width: calc((100% / 4) - 15px);
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .tileBox_8up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 3) - 13.33px);
    flex-basis: calc((100% / 3) - 13.33px);
    margin: 0 10px;
    max-width: calc((100% / 3) - 13.33px);
    min-width: calc((100% / 3) - 13.33px);
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tileBox_8up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 2) - 10px);
    flex-basis: calc((100% / 2) - 10px);
    margin: 0 10px;
    max-width: calc((100% / 2) - 10px);
    min-width: calc((100% / 2) - 10px);
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

@media (max-width: 320px) {
  .tileBox_8up_carousel:not(.tileBox_noCollapse) > * {
    -ms-flex-preferred-size: calc((100% / 1) - 0px);
    flex-basis: calc((100% / 1) - 0px);
    margin: 0;
    max-width: calc((100% / 1) - 0px);
    min-width: calc((100% / 1) - 0px);
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :first-child {
    margin-left: 0;
  }

  .tileBox_8up_carousel:not(.tileBox_noCollapse) > :last-child {
    margin-right: 0;
  }
}

pingwebfit .btn,
pingwebfit .chat-floater {
  -webkit-box-align: center;
}

pingwebfit .scrollWrapper_horizontal {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

pingwebfit .vr {
  margin-bottom: 1rem;
}

@media (max-width: 425px) {
  .vr {
    margin-bottom: 0.5rem;
  }
}

pingwebfit .vr_half {
  margin-bottom: 0.5rem;
}

pingwebfit .vr_third {
  margin-bottom: 0.33rem;
}

pingwebfit .vr_both {
  margin: 1rem 0;
}

pingwebfit .vr_both_half {
  margin: 0.5rem 0;
}

@media (max-width: 425px) {
  .vr_both {
    margin: 0.5rem 0;
  }

  .vr_both_half {
    margin: 0.25rem 0;
  }
}

pingwebfit .vr_2x {
  margin-bottom: 2rem;
}

@media (max-width: 425px) {
  .vr_2x {
    margin-bottom: 0.5 * 2rem;
  }
}

pingwebfit .vr_3x {
  margin-bottom: 3rem;
}

@media (max-width: 425px) {
  .vr_3x {
    margin-bottom: 0.5 * 3rem;
  }
}

pingwebfit .vr_4x {
  margin-bottom: 4rem;
}

@media (max-width: 425px) {
  .vr_4x {
    margin-bottom: 0.5 * 4rem;
  }
}

pingwebfit .vr_5x {
  margin-bottom: 5rem;
}

@media (max-width: 425px) {
  .vr_5x {
    margin-bottom: 0.5 * 5rem;
  }
}

pingwebfit .vr_6x {
  margin-bottom: 6rem;
}

@media (max-width: 425px) {
  .vr_6x {
    margin-bottom: 0.5 * 6rem;
  }
}

pingwebfit .vr_7x {
  margin-bottom: 7rem;
}

@media (max-width: 425px) {
  .vr_7x {
    margin-bottom: 0.5 * 7rem;
  }
}

pingwebfit .vr_8x {
  margin-bottom: 8rem;
}

@media (max-width: 425px) {
  .vr_8x {
    margin-bottom: 0.5 * 8rem;
  }
}

pingwebfit
  [data-bv-show='rating_summary']
  .bv_main_container
  .bv_main_container_row_split {
  margin: 0 auto !important;
}

pingwebfit
  [data-bv-show='rating_summary']
  .bv_main_container
  .bv_main_container_row_split:nth-child(3) {
  margin: 0 !important;
}

pingwebfit
  [data-bv-show='rating_summary']
  .bv_main_container
  .bv_main_container_row_split
  .bv_stars_component_container
  .bv_stars_button_container {
  padding-right: 0 !important;
}

pingwebfit
  [data-bv-show='rating_summary']
  .bv_main_container
  .bv_main_container_row_split
  .bv_writeAReview_buttonMinimalist {
  margin-top: 0.25em !important;
  padding-right: 0 !important;
}

pingwebfit .reviewSummary {
  margin-top: 1em;
}

pingwebfit .btn {
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 0.2rem;
  border: 2px solid #0371b5;
  color: #0371b5;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: inherit;
  font-size: inherit;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 0.1em;
  line-height: 1;
  padding: 0.75rem 1.5rem;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;
  white-space: nowrap;
}

pingwebfit .btn:focus,
pingwebfit .btn:hover {
  color: #fff;
  background-color: #0371b5;
}

pingwebfit .btn:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 2px;
  -moz-outline-radius: 0.2em;
}

pingwebfit .btn:disabled,
pingwebfit .btn:disabled:focus,
pingwebfit .btn:disabled:hover {
  background-color: #aad7ed;
  border-color: #aad7ed;
  color: #fff;
}

pingwebfit .btn > * {
  display: inline-block;
}

pingwebfit .btn_pointer {
  cursor: pointer;
}

pingwebfit .btn_dark {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

pingwebfit .btn_primary {
  background-color: #0371b5;
  border-color: #0371b5;
  color: #fff;
}

pingwebfit .btn_primary:hover {
  background-color: #fff;
  color: #0371b5;
  cursor: pointer;
}

pingwebfit .btn_pageNav {
  background-color: transparent;
  border-color: #bbb;
  color: inherit;
  padding: 1rem;
  width: 3.25em;
}

@media (max-width: 600px) {
  .btn_pageNav {
    width: 3rem;
    padding: 0.75rem;
  }
}

@media (max-width: 425px) {
  .btn_pageNav {
    width: 2.25rem;
    padding: 0.33rem;
  }
}

pingwebfit .btn_pageNav:after {
  display: block;
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 3px;
  -webkit-transform: translateY(0.5em);
  transform: translateY(0.5em);
  border-bottom: 1px solid transparent;
}

pingwebfit .btn_pageNav_prevNext:hover:after,
pingwebfit .link_breadcrumbs:focus:after {
  -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  border-width: 2px;
}

pingwebfit .btn_pageNav:focus {
  background-color: inherit;
  color: inherit;
  outline: #0371b5 dotted 1px;
  outline-offset: 2px;
}

pingwebfit .btn_pageNav:hover {
  background-color: transparent;
  border-color: #0371b5;
  color: inherit;
}

pingwebfit .btn_pageNav_prevNext {
  border-color: transparent;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 0.75rem;
  padding: 1em 0;
  position: relative;
  width: auto;
}

@media (max-width: 600px) {
  .btn_pageNav_prevNext {
    margin: 0 0.5rem;
    padding: 0.75em 0;
  }
}

pingwebfit .btn_pageNav_prevNext:hover {
  background-color: transparent;
  border-color: transparent;
}

pingwebfit .btn_pageNav_prevNext:hover:after {
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateY(0.25em);
  transform: translateY(0.25em);
  border-color: #1ba5f5;
}

pingwebfit .btn_pageNav_isCurrentPage {
  background-color: #0371b5;
  border-color: #0371b5;
  color: #fff;
}

pingwebfit .btn_pageNav_isCurrentPage:focus,
pingwebfit .btn_pageNav_isCurrentPage:hover {
  background-color: #0371b5;
  border-color: #0371b5;
  cursor: default;
}

pingwebfit .mix-btn_stretch {
  width: 100%;
}

pingwebfit .mix-btn_stretch_mobile {
  width: auto;
}

@media (max-width: 425px) {
  .btn_pageNav_prevNext {
    margin: 0 0.33rem;
    padding: 0.33em 0;
  }

  .mix-btn_stretch_mobile {
    width: 100%;
  }
}

pingwebfit .mix-btn_share {
  min-width: 205px;
}

pingwebfit .mix-section_black .btn:not(.btn_primary):not(.btn_pageNav) {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

pingwebfit .mix-section_black .btn:not(.btn_primary):not(.btn_pageNav):hover {
  background-color: #0371b5;
  border-color: #0371b5;
}

pingwebfit .mix-section_black .btn_pageNav {
  border-color: #0371b5;
}

pingwebfit .mix-section_black .btn_pageNav:hover {
  background-color: transparent;
  border-color: #fff;
}

pingwebfit .mix-section_black .btn_pageNav_prevNext,
pingwebfit .mix-section_black .btn_pageNav_prevNext:hover {
  border-color: transparent;
}

pingwebfit .mix-section_black .btn_pageNav_isCurrentPage,
pingwebfit .mix-section_black .btn_pageNav_isCurrentPage:hover {
  background-color: #0371b5;
  border-color: #0371b5;
}

pingwebfit .btn_padding.padding_sides {
  padding-left: 5rem;
  padding-right: 5rem;
}

pingwebfit .breadcrumbs {
  padding: 2rem 0;
  font-size: 0.8rem;
  font-weight: 600;
  color: #616a70;
}

pingwebfit .breadcrumbs-item + .breadcrumbs-item:before {
  content: '/';
  display: inline-block;
  padding: 0 0.25rem;
}

pingwebfit .link_breadcrumbs:focus {
  color: #0371b5;
}

pingwebfit .link_breadcrumbs:focus:after {
  content: '';
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateY(0.25em);
  transform: translateY(0.25em);
  border-color: #1ba5f5;
}

pingwebfit .txt .link_breadcrumbs:focus:after {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

pingwebfit .bulletList {
  list-style-type: square;
  margin-left: 1rem;
  font-size: 0.9em;
}

pingwebfit .bulletList-item,
pingwebfit .bulletList > * {
  margin: 0.5em 0;
}

pingwebfit .carousel-wrapper {
  position: relative;
}

pingwebfit .carousel-button {
  position: absolute;
  z-index: 2;
  padding: 10px 0;
  width: 50px;
  font-size: 25px;
  color: #616a70;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.66);
}

pingwebfit .carousel-button[disabled] {
  color: rgba(97, 106, 112, 0.5);
  background-color: transparent;
}

pingwebfit .carousel-button:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 0;
}

pingwebfit .carousel-button_prev {
  left: 0;
}

pingwebfit .carousel-button_next {
  right: 0;
}

pingwebfit .carousel-pips {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

pingwebfit .carousel-pips-pip {
  background-color: #bbb;
  border-radius: 50%;
  height: 10px;
  margin: 0 4px;
  padding: 0;
  -webkit-transition: background-color 0.2s 0.1s ease;
  transition: background-color 0.2s 0.1s ease;
  width: 10px;
}

pingwebfit .carousel-pips-pip.active {
  background-color: #0371b5;
}

pingwebfit .carousel-pips-pip:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 2px;
  -moz-outline-radius: 0.2em;
}

pingwebfit .carousel-pips-inside {
  position: relative;
  background-color: #f8f8f8;
  margin-top: -20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1;
}

pingwebfit .chat-floater,
pingwebfit .chat-window {
  position: fixed;
  background-color: #fff;
}

pingwebfit .chat {
  z-index: 1;
}

pingwebfit .chat-floater {
  display: none;
  bottom: 30px;
  right: 30px;
  width: 80px;
  height: 80px;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  z-index: 4;
}

pingwebfit .chat-floater.chat-floater_online {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

pingwebfit .chat-window-body.minimized,
pingwebfit .chat-window-head-meta.minimized {
  display: none;
}

pingwebfit .chat-window {
  bottom: 0;
  right: 40px;
  width: 100%;
  max-width: 750px;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  z-index: 100;
}

@media (max-width: 768px) {
  .chat-window.minimized {
    height: initial;
  }

  .chat-window {
    max-width: 100%;
    width: 100%;
    height: 100%;
    right: 0;
  }
}

pingwebfit .chat-window-head {
  height: 50px;
  background-color: #0371b5;
  box-sizing: border-box;
  position: relative;
}

pingwebfit .chat-window-head-title {
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  margin-top: 15px;
  text-align: center;
}

pingwebfit .chat-window-head-meta {
  position: absolute;
  right: 0;
  text-align: right;
  margin-top: 15px;
  margin-right: 20px;
}

pingwebfit .chat-window-head-meta > span {
  margin-right: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .chat-window-head-title {
    left: 20px;
    margin-left: 0;
  }

  .chat-window-head-meta > span {
    margin-right: 44px;
  }
}

pingwebfit .chat-window-head-meta > span:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .chat-window-head-meta {
    margin-right: 30px;
  }
}

pingwebfit .chat iframe {
  width: 100%;
}

pingwebfit .chatWrapper {
  padding-top: 50px;
  width: 80%;
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}

pingwebfit .chatFrame {
  height: 100%;
}

pingwebfit .cookieConsent {
  padding-left: calc((100vw - 1240px) / 2);
  padding-right: calc((100vw - 1240px) / 2);
  background-color: #fff;
  bottom: 0;
  -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
  color: #000;
  display: none;
  font-size: 0.9rem;
  line-height: 1.33;
  font-weight: 400;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  position: fixed;
  width: 100%;
  z-index: 10;
}

@media (max-width: 1240px) {
  .cookieConsent {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 768px) {
  .cookieConsent {
    padding-left: 15px;
    padding-right: 15px;
  }
}

pingwebfit body.js-kenticoEditMode .cookieConsent {
  position: relative;
  display: block;
}

pingwebfit .dataTable p {
  color: #2e3134;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.015em;
  line-height: 1.5;
  text-align: left;
}

pingwebfit .dataTable .dataTable-table p {
  font-size: inherit;
}

pingwebfit .dataTable-hd {
  max-width: 900px;
  margin: 0 auto 3rem;
}

pingwebfit .dataTable-hd-img {
  width: auto;
  height: 3.75rem;
  display: block;
  float: left;
  border-right: 1px solid #bbb;
  padding-right: 0.5rem;
  margin-right: 1rem;
}

pingwebfit .dataTable-table td,
pingwebfit .dataTable-table th {
  padding: 1rem;
  text-align: left;
  vertical-align: middle;
}

pingwebfit .dataTable-hd-small {
  font-size: 0.9rem;
  color: #616a70;
  letter-spacing: 0.15em;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

pingwebfit .dataTable-hd-big {
  font-size: 2rem;
  line-height: 1;
  font-weight: 400;
}

pingwebfit .dataTable-table {
  min-width: 100%;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

pingwebfit .dataTable-table tbody tr:first-of-type {
  background-color: #f8f8f8;
}

pingwebfit .dataTable-table tr {
  background-color: #fff;
}

pingwebfit .dataTable-table tr:nth-child(2n + 3) {
  background-color: #f8f8f8;
}

pingwebfit .dataTable-table th {
  font-weight: 600;
  border-bottom: 1px solid #bbb;
  height: calc(2rem + 2em);
}

pingwebfit .dataTable-table td {
  font-weight: 300;
}

pingwebfit .dataTable-table td[rowspan] {
  background-color: #fff;
}

pingwebfit .dataTable-table img:not([class]) {
  display: block;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0;
  min-width: 125px;
}

@media (max-width: 600px) {
  .dataTable-table,
  .dataTable-table table,
  .dataTable-table tbody,
  .dataTable-table td,
  .dataTable-table th,
  .dataTable-table thead,
  .dataTable-table tr {
    display: block;
  }

  .dataTable-table tr {
    margin: 0 0 1rem;
  }

  .dataTable-table tr th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .dataTable-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .dataTable-table td[rowspan] {
    padding: 1rem;
  }

  .dataTable-table td:before {
    position: absolute;
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}

pingwebfit .dataTable_bordered table td,
pingwebfit .dataTable_bordered table th {
  border: 1px solid #e1e1e1;
}

pingwebfit .dataTable-notes ol,
pingwebfit .dataTable-notes ul {
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 1.5rem;
}

pingwebfit .dataTable-notes ol {
  list-style-type: decimal;
}

pingwebfit .dataTable-notes ul {
  list-style-type: disc;
}

pingwebfit .dataTable-notes li {
  padding-left: 0.5em;
  line-height: 1.25em;
  padding-bottom: 0.25em;
}

pingwebfit .dataTable-notes-hd {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 2;
}

pingwebfit table.fullswing-grips-table .name {
  width: 45%;
}

@media (max-width: 600px) {
  table.fullswing-grips-table td {
    padding-left: 40%;
  }

  table.fullswing-grips-table .name {
    padding-left: 1rem;
    width: 100%;
  }

  table.fullswing-grips-table .name::before {
    display: none;
  }
}

pingwebfit table.putter-grips-table .name {
  width: 50%;
}

@media (max-width: 600px) {
  table.putter-grips-table td {
    padding-left: 40%;
  }

  table.putter-grips-table .name {
    padding-left: 1rem;
    width: 100%;
  }

  table.putter-grips-table .name::before {
    display: none;
  }
}

pingwebfit table.shaft-specs-table td[rowspan] {
  width: 55%;
}

@media (max-width: 600px) {
  table.shaft-specs-table td[rowspan] {
    width: 100%;
  }
}

pingwebfit .dialogBackdrop {
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

pingwebfit .dialog {
  display: inline-block;
}

pingwebfit .dialog-content {
  background-color: #fff;
  border-radius: 0.25rem;
  display: none;
  left: 50%;
  max-height: calc(100vh - 1rem);
  max-width: 475px;
  overflow-y: auto;
  padding: 3.5rem 2rem 2rem;
  position: fixed;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 101;
}

@media (max-width: 425px) {
  .dialog-content {
    max-width: calc(100% - 1rem);
  }
}

pingwebfit .dialog-content-img {
  display: block;
  height: auto;
  margin: 0 auto 26px;
  width: 250px;
}

pingwebfit .dialog-content-hd {
  font-size: 1.33rem;
  font-weight: 600;
  margin-bottom: 24px;
}

pingwebfit .dialog-content-bd {
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
}

pingwebfit .dialog-content-windowBar {
  background-color: #0371b5;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #fff;
  font-size: 32px;
  left: 0;
  position: absolute;
  text-align: right;
  top: 0;
  width: 100%;
}

pingwebfit .dialog-content-windowBar-close {
  position: relative;
  top: 3px;
}

pingwebfit .dialog-content-windowBar-close:focus {
  outline: 0;
}

pingwebfit .dialog-content-windowBar-close:focus svg {
  outline: #fff dashed 1px;
  outline-offset: 2px;
}

pingwebfit .dialog-content-ft {
  padding-top: 1rem;
  text-align: center;
}

@media (min-width: 1024px) {
  .emailSignup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .emailSignup-hd {
    padding-right: 2rem;
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
  }
}

@media (min-width: 600px) {
  .emailSignup-bd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .emailSignup-bd {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

pingwebfit .emailSignup-bd-email {
  padding: 18px 0;
}

@media (min-width: 600px) {
  .emailSignup-bd-email {
    padding: 0 0.5rem 0 0;
    -webkit-box-flex: 0.25;
    -ms-flex-positive: 0.25;
    flex-grow: 0.25;
    margin-bottom: 15px;
  }
}

pingwebfit .emailSignup-bd-zip {
  padding: 18px 0;
}

@media (min-width: 600px) {
  .emailSignup-bd-zip {
    padding: 0 0.5rem 0 0;
    -webkit-box-flex: 0.25;
    -ms-flex-positive: 0.25;
    flex-grow: 0.25;
  }

  .emailSignup-bd-btn {
    padding-left: 1rem;
  }
}

@media (max-width: 600px) {
  .emailSignup-bd-btn {
    padding: 0.25rem 0;
  }
}

pingwebfit .fakeEditModeBtn {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 1000;
  background-color: rgba(221, 221, 221, 0.5);
  padding: 0.5rem;
  border: 1px solid #000;
}

pingwebfit .faqGroup-hd {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

pingwebfit .faqGroup-list {
  margin-bottom: 1rem;
}

pingwebfit .faqGroup-list-item {
  padding: 0.25rem 0;
  position: relative;
}

pingwebfit .faqGroup-list-item-toggle {
  color: #0371b5;
  font-size: 20px;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0.2rem;
}

pingwebfit .faqGroup-list-item-toggle:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 2px;
  -moz-outline-radius: 0.2em;
}

pingwebfit .faqGroup-list-item-question {
  color: #0371b5;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin-left: 2rem;
  position: relative;
}

pingwebfit .faqGroup-list-item-question:after {
  border-bottom: 1px solid transparent;
  bottom: 0;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  -webkit-transform: translateY(0.5em);
  transform: translateY(0.5em);
  width: 100%;
}

pingwebfit .faqGroup-list-item-question:hover {
  color: #0371b5;
}

pingwebfit .featuredProductList-hd-small,
pingwebfit .featuredVideos-main-small {
  color: #616a70;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

pingwebfit .faqGroup-list-item-question:hover:after {
  border-color: #1ba5f5;
  border-width: 2px;
  -webkit-transform: translateY(0.25em);
  transform: translateY(0.25em);
  -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
}

pingwebfit .faqGroup-list-item-answer {
  display: none;
  font-size: 0.9rem;
  margin-left: 2rem;
  padding: 0.5rem 0;
}

pingwebfit .featuredProductList {
  max-width: 825px;
  margin: 0 auto;
}

pingwebfit .featuredProductList-hd {
  text-align: center;
  margin-bottom: 4rem;
}

pingwebfit .featuredProductList-hd-small:after,
pingwebfit .featuredVideos-main-small:after {
  border-top: 2px solid #0371b5;
  margin: 1rem 0;
  content: '';
}

pingwebfit .featuredProductList-hd-small {
  font-size: 0.9rem;
  font-weight: 600;
  position: relative;
}

pingwebfit .featuredProductList-hd-small:after {
  display: block;
  width: 3ch;
  height: 0;
  position: relative;
  left: calc(50% - 1.5ch);
}

pingwebfit .featuredProductList-hd-big {
  font-size: 2.67rem;
  font-weight: 700;
}

pingwebfit .featuredVideos {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .featuredVideos {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2rem 0;
  }
}

pingwebfit .featuredVideos-main {
  min-width: 75%;
  padding-right: 2rem;
}

@media (max-width: 768px) {
  .featuredVideos-main {
    padding-right: 0;
    width: 100%;
  }

  .featuredVideos-main .video {
    width: 100%;
    height: auto;
  }
}

pingwebfit .featuredVideos-main-small {
  font-size: 0.9rem;
  font-weight: 600;
}

pingwebfit .featuredVideos-main-small:after {
  display: block;
  width: 3ch;
  height: 0;
}

@media (max-width: 425px) {
  .featuredVideos-main {
    text-align: center;
  }

  .featuredVideos-main-small {
    position: relative;
  }

  .featuredVideos-main-small:after {
    content: '';
    display: block;
    width: 3ch;
    height: 0;
    border-top: 2px solid #0371b5;
    margin: 1rem 0;
    position: relative;
    left: calc(50% - 1.5ch);
  }
}

pingwebfit .featuredVideos-sidebar {
  min-width: 25%;
  padding-left: 2rem;
  border-left: 1px solid #bbb;
}

pingwebfit .featuredVideos-sidebar-hd {
  font-weight: 600;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .featuredVideos-sidebar {
    padding: 0;
    border: none;
    margin-top: 2rem;
  }

  .featuredVideos-sidebar-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

pingwebfit .featuredVideos-sidebar-list-item {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 1.5em;
}

pingwebfit .featuredVideos-sidebar-list-item img {
  width: 100%;
  height: auto;
  margin-bottom: 0.5em;
}

@media (max-width: 768px) {
  .featuredVideos-sidebar-list-item {
    width: 50%;
  }

  .featuredVideos-sidebar-list-item:nth-child(2n) {
    padding-left: 7px;
  }

  .featuredVideos-sidebar-list-item:nth-child(2n + 1) {
    padding-right: 7px;
  }
}

@media (max-width: 425px) {
  .featuredVideos-sidebar-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .featuredVideos-sidebar-list-item {
    width: 100%;
  }

  .featuredVideos-sidebar-list-item:nth-child(2n) {
    padding-left: 0;
  }

  .featuredVideos-sidebar-list-item:nth-child(2n + 1) {
    padding-right: 0;
  }
}

pingwebfit .featuredVideos-sidebar-list-item > a:focus {
  color: #0371b5;
  outline: 0;
}

pingwebfit .fittingBanner {
  color: #fff;
  padding-top: 1rem;
  text-align: center;
}

pingwebfit .fittingBanner-small {
  font-size: 0.9rem;
  letter-spacing: 0.15em;
  padding-bottom: 1em;
  text-transform: uppercase;
  position: relative;
}

pingwebfit .fittingBanner-small:after {
  content: '';
  display: block;
  width: 3ch;
  height: 0;
  border-top: 2px solid #0371b5;
  margin: 1em 0;
  position: relative;
  left: calc(50% - 1.5ch);
  border-color: #1ba5f5;
}

pingwebfit .fittingBanner-big {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  margin: 0 auto;
  max-width: 30ch;
  padding-bottom: 1em;
}

pingwebfit .fittingBanner-bd {
  margin: 0 auto;
  max-width: 925px;
}

pingwebfit .fittingBanner-bd video {
  width: 100%;
}

pingwebfit .fittingBanner-ft {
  margin-top: 2rem;
}

pingwebfit .footer-hd-logo {
  width: 120px;
}

pingwebfit .footer-hd-social {
  padding: 0.5em 0;
  color: #2e3134;
  font-size: 20px;
}

pingwebfit .footer-hd-social > * {
  display: inline-block;
  margin: 0.25em 1em 0 0;
  line-height: 1;
}

pingwebfit .footer-hd-social-link > .icon,
pingwebfit .hero-image {
  display: block;
}

pingwebfit .hero-bd-big,
pingwebfit .hero-bd-small {
  margin-bottom: 0.5em;
  max-width: 100%;
}

pingwebfit .footer-hd-social > :last-child {
  margin-right: 0;
}

@media (max-width: 425px) {
  .footer-hd-social {
    width: 100%;
    font-size: 24px;
    margin-left: -5px;
  }
}

pingwebfit .footer-hd-social-link:focus {
  display: block;
  outline: #0371b5 dotted 1px;
  outline-offset: 2px;
}

pingwebfit .hero_large_product .btn,
pingwebfit .hero_mediaArticle .btn,
pingwebfit .hero_productCat .btn,
pingwebfit .hero_productCat_boxed .btn {
  outline-color: #fff;
}

pingwebfit .footer-hd-social-link:hover {
  color: #0371b5;
}

pingwebfit .footer-bd {
  padding: 3em 0;
  color: #2e3134;
  line-height: 1.15;
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .footer-bd {
    padding: 0;
  }
}

pingwebfit .footer-ft {
  color: #f1f1f1;
  background-color: #000;
  font-size: 0.8rem;
  line-height: 1.4;
  padding: 0.5em 0;
}

pingwebfit .hero {
  position: relative;
}

pingwebfit .hero-image {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

pingwebfit .hero-image-foreground {
  position: absolute;
  top: 70px;
  right: 10px;
  width: 45%;
}

@media (max-width: 600px) {
  .hero-image-foreground {
    top: 30px;
    right: -25px;
    width: 96%;
  }
}

pingwebfit .hero-bd {
  color: #fff;
  position: absolute;
  width: 100%;
}

pingwebfit .hero-bd > div:not([class]) {
  max-width: 100%;
  min-width: 1px;
}

pingwebfit .hero-bd-small {
  font-size: 100%;
}

pingwebfit .hero-bd-big {
  font-size: 200%;
  font-weight: 700;
}

pingwebfit .hero_large .hero-image {
  height: calc(100vw * 0.5);
}

@media (max-width: 600px) {
  .hero_large_lifestyle .hero-bd {
    background-color: #2e3134;
    background-position: -80vw;
    padding-left: 18vw;
    position: relative;
  }

  .hero_large_lifestyle .hero-bd-img {
    display: none;
  }
}

@media (max-width: 425px) {
  .hero_large_lifestyle .hero-bd {
    font-size: 2vw;
  }
}

pingwebfit .hero_large_lifestyle .hero-bd-img {
  bottom: 0;
  height: auto;
  left: 5vw;
  position: absolute;
  width: 18vw;
}

pingwebfit .hero_large_lifestyle .hero-bd-small {
  font-size: 90%;
  font-weight: 400;
  letter-spacing: 0.2em;
}

@media (max-width: 768px) {
  .footer-ft {
    line-height: 1;
  }

  .hero_large_lifestyle .hero-bd-small {
    font-size: 125%;
  }
}

@media (max-width: 600px) {
  .hero_large_lifestyle .hero-bd-small {
    font-size: 100%;
    margin: 2em 0;
  }
}

pingwebfit .hero_large_lifestyle .hero-bd-big {
  font-size: 250%;
  margin-bottom: 0.25em;
}

pingwebfit .hero_large_lifestyle .hero-bd-big b,
pingwebfit .hero_large_lifestyle .hero-bd-big strong {
  color: #9ad446;
}

@media (max-width: 425px) {
  .hero_large_lifestyle .hero-bd-big {
    font-size: 200%;
  }
}

pingwebfit .hero_large_lifestyle .btn {
  font-size: 1rem;
  font-weight: 400;
  outline-color: #fff;
}

@media (max-width: 600px) {
  .hero_large_product .hero-image {
    height: calc(100vw * 0.34722 * 1.25);
    left: -25vw;
    position: relative;
    width: 150vw;
  }
}

pingwebfit .hero_large_product .hero-bd {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  bottom: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 1.66vw;
  line-height: 1.3;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  top: 0;
  width: 100%;
}

@media (max-width: 600px) {
  .hero_large_product .hero-bd {
    background-color: #2e3134;
    bottom: 0;
    height: unset;
    padding: 1rem;
    position: relative;
    text-align: left;
    width: unset;
  }
}

@media (max-width: 600px) and (max-width: 1240px) {
  .hero_large_product .hero-bd {
    padding-right: 25px;
  }
}

@media (max-width: 600px) and (max-width: 768px) {
  .hero_large_product .hero-bd {
    padding-right: 15px;
  }
}

@media (min-width: 1240px) {
  .hero_large_product .hero-bd {
    font-size: 21px;
  }
}

pingwebfit .hero_large_product .hero-bd-small {
  font-size: 20px;
  letter-spacing: 0.1em;
  width: 100%;
}

pingwebfit .hero_large_product .hero-bd-big {
  padding-left: calc((100vw - 1240px) / 2);
  padding-right: calc((100vw - 1240px) / 2);
  font-size: 225%;
  letter-spacing: 0.05em;
  margin-bottom: 0.75em;
  width: 100%;
}

@media (max-width: 1240px) {
  .hero_large_product .hero-bd-big {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 768px) {
  .hero_large_product .hero-bd-big {
    padding-left: 15px;
    padding-right: 15px;
  }
}

pingwebfit .hero_large_product .btn {
  font-size: 1rem;
  margin: 0 auto;
}

pingwebfit .hero_large_product.hero_large_product-darkBackground .hero-bd {
  background-color: rgba(0, 0, 0, 0.5);
}

pingwebfit .hero_mediaArticle {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 900px) {
  .hero_mediaArticle {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

pingwebfit .hero_mediaArticle .hero-bd {
  padding-left: calc((100vw - 1240px) / 2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 30%;
  padding-right: 50px;
  position: relative;
  z-index: 1;
}

pingwebfit .hero_2up,
pingwebfit .hero_productCat .hero-bd {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-direction: normal;
}

@media (max-width: 1240px) {
  .hero_mediaArticle .hero-bd {
    padding-left: 25px;
  }
}

@media (max-width: 768px) {
  .hero_mediaArticle .hero-bd {
    padding-left: 15px;
  }
}

@media (max-width: 900px) {
  .hero_mediaArticle .hero-bd {
    padding-right: calc((100vw - 1240px) / 2);
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-bottom: 1rem;
  }

  .hero_mediaArticle .hero-bd > :first-child {
    margin-top: 5px;
    margin-bottom: -5px;
  }
}

@media (max-width: 900px) and (max-width: 1240px) {
  .hero_mediaArticle .hero-bd {
    padding-right: 25px;
  }
}

@media (max-width: 900px) and (max-width: 768px) {
  .hero_mediaArticle .hero-bd {
    padding-right: 15px;
  }
}

@media (max-width: 425px) {
  .hero_mediaArticle .hero-bd {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .hero_mediaArticle .hero-bd > :first-child {
    margin-top: 0;
    margin-bottom: 0;
  }
}

pingwebfit .hero_mediaArticle .hero-bd-big {
  line-height: 1.3em;
  margin: 1rem 0;
  font-weight: 400;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

@media (max-width: 900px) {
  .hero_mediaArticle .hero-bd-big {
    font-size: 150%;
    margin-top: 0.5rem;
    padding-right: 1rem;
  }
}

pingwebfit .hero_mediaArticle .hero-bd-small {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.2em;
}

pingwebfit .hero_mediaArticle .hero-bd-small-marginBottom {
  margin-bottom: 1rem;
}

pingwebfit .hero_mediaArticle .hero-image {
  min-height: 500px;
  max-height: 1px;
  min-width: 60%;
  max-width: none;
  background-position-x: center;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  .hero_mediaArticle .hero-image {
    height: auto;
  }
}

@media (max-width: 900px) {
  .hero_mediaArticle .hero-image {
    height: auto;
    margin-bottom: 1rem;
    max-height: 44.44444vw;
    min-width: 100vw;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media (max-width: 1024px), (max-width: 900px) {
  .hero_mediaArticle .hero-image.align-left {
    background-position-x: left;
  }

  .hero_mediaArticle .hero-image.align-right {
    background-position-x: right;
  }
}

pingwebfit .hero_productCat .hero-image {
  height: calc(100vw * 0.34722);
}

@media (max-width: 600px) {
  .hero_large_product.hero_large_product-darkBackground .hero-bd {
    background-color: #2e3134;
  }

  .hero_productCat .hero-image {
    height: calc(100vw * 0.34722 * 1.25);
    left: -25vw;
    position: relative;
    width: 150vw;
  }
}

pingwebfit .hero_productCat .hero-bd {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 1.66vw;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.3;
  top: 0;
  width: 46vw;
  padding-left: calc((100vw - 1240px) / 2);
}

@media (max-width: 1240px) {
  .hero_productCat .hero-bd {
    padding-left: 25px;
  }
}

@media (max-width: 768px) {
  .hero_productCat .hero-bd {
    padding-left: 15px;
  }
}

@media (max-width: 600px) {
  .hero_productCat .hero-bd {
    background-color: #000;
    bottom: 0;
    height: unset;
    padding: 1rem;
    position: relative;
    width: unset;
  }
}

@media (max-width: 600px) and (max-width: 1240px) {
  .hero_productCat .hero-bd {
    padding-right: 25px;
  }
}

@media (max-width: 600px) and (max-width: 768px) {
  .hero_productCat .hero-bd {
    padding-right: 15px;
  }
}

pingwebfit .hero_productCat .hero-bd-small {
  font-size: 75%;
  font-weight: 300;
  letter-spacing: 0.2em;
  margin: 0;
}

pingwebfit .hero_productCat .hero-bd-small > b,
pingwebfit .hero_productCat .hero-bd-small > strong {
  font-weight: 600;
}

pingwebfit .hero_productCat .hero-bd-small:after {
  content: '';
  display: block;
  width: 3ch;
  height: 0;
  border-top: 2px solid #0371b5;
  margin: 1rem 0;
}

@media (max-width: 900px) {
  .hero_productCat .hero-bd-small {
    font-size: 100%;
  }
}

@media (max-width: 768px) {
  .hero_productCat .hero-bd-small {
    font-size: 125%;
  }
}

@media (max-width: 600px) {
  .hero_productCat .hero-bd-small {
    font-size: 0.8rem;
  }
}

pingwebfit .hero_productCat .hero-bd-big {
  font-size: 225%;
  line-height: 1.15;
  margin-bottom: 2rem;
}

pingwebfit .hero_productCat .hero-bd-big b,
pingwebfit .hero_productCat .hero-bd-big strong {
  color: #0371b5;
  font-weight: inherit;
}

@media (max-width: 768px) {
  .hero_productCat .hero-bd-big {
    margin-bottom: 1rem;
  }
}

pingwebfit .hero_productCat .btn {
  font-size: 1rem;
}

pingwebfit .hr,
pingwebfit .hr_top {
  border-right: none;
  border-left: none;
}

pingwebfit .hero_productCat_boxed .hero-bd {
  background-color: rgba(0, 0, 0, 0.75);
  margin-left: 40px;
  height: auto;
  padding: 2em 3em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 38vw;
}

pingwebfit .hero_productCat_boxed .hero-bd-small {
  font-weight: 300;
}

pingwebfit .hero_productCat_boxed .hero-bd-small:after {
  content: '';
  display: block;
  width: 3ch;
  height: 0;
  border-top: 2px solid #0371b5;
  margin: 0.75em 0;
}

pingwebfit .hero_productCat_boxed .hero-bd-big {
  margin-bottom: 0.5em;
}

pingwebfit .hero_productCat_boxed_light .btn,
pingwebfit .hero_productCat_light .btn {
  outline-color: #0371b5;
}

@media (max-width: 600px) {
  .hero_productCat_boxed .hero-bd {
    background-color: #000;
    left: 0;
    margin-left: 0;
    padding: 1rem;
    top: 0;
    -webkit-transform: none;
    transform: none;
    width: 100vw;
  }
}

pingwebfit .hero_productCat_boxed_light .hero-bd {
  padding-left: calc((100vw - 1240px) / 2);
  padding-right: 6vw;
  margin: 0;
  width: 42vw;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  color: #2e3134;
}

@media (max-width: 1240px) {
  .hero_productCat_boxed_light .hero-bd {
    padding-left: 25px;
  }
}

@media (max-width: 768px) {
  .hero_productCat_boxed_light .hero-bd {
    padding-left: 15px;
  }
}

pingwebfit .hero_productCat_light .hero-bd {
  color: #2e3134;
  font-size: 1.25vw;
  width: 50vw;
}

pingwebfit .hero_productCat_light .hero-bd-small {
  font-size: 90%;
}

@media (max-width: 600px) {
  .hero_productCat_light .hero-bd {
    color: #fff;
    font-size: 1.75vw;
    padding: 1rem 2rem 1rem 1rem;
    width: 100%;
  }
}

pingwebfit .hero_2up {
  display: flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 1.4vw;
}

pingwebfit .hero_2up > * {
  position: relative;
  width: 50%;
}

@media (max-width: 600px) {
  .hero_2up {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hero_2up > * {
    width: 100%;
  }
}

pingwebfit .hero_2up .hero-image {
  width: 100%;
  height: auto;
}

pingwebfit .hero_2up .hero-bd-small:after,
pingwebfit .hero_2up_boxed .hero-bd-small:after {
  height: 0;
  border-top: 2px solid #0371b5;
  content: '';
}

pingwebfit .hero_2up .hero-bd {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: calc(1rem + 0.44em);
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1rem;
}

pingwebfit .hero_2up .hero-bd > :first-child {
  min-width: unset;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

pingwebfit .hero_2up .hero-bd > :last-child {
  min-width: unset;
}

@media (max-width: 1240px) {
  .hero_2up .hero-bd {
    padding-left: calc((100vw - 1240px) / 2);
    padding-right: calc((100vw - 1240px) / 2);
  }
}

@media (max-width: 1240px) and (max-width: 1240px) {
  .hero_2up .hero-bd {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 1240px) and (max-width: 768px) {
  .hero_2up .hero-bd {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1024px) {
  .hero_2up .hero-bd {
    font-size: 150%;
  }
}

@media (max-width: 768px) {
  .hero_2up .hero-bd {
    font-size: 160%;
  }
}

@media (max-width: 600px) {
  .hero_2up .hero-bd {
    font-size: 1rem;
  }
}

pingwebfit .hero_2up .hero-bd_taller {
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}

pingwebfit .hero_2up .hero-bd-small {
  font-size: 75%;
  font-weight: 400;
  letter-spacing: 0.12em;
  margin: 0.75em 0;
}

pingwebfit .hero_2up .hero-bd-small:after {
  display: block;
  width: 3ch;
  margin: 1em 0;
}

pingwebfit .hero_2up .hero-bd-big {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 110%;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 0.075em;
  line-height: 1.33;
  margin: 0 1rem -0.4em 0;
  min-height: 2.66em;
  overflow: hidden;
}

pingwebfit .hero_small .hero-bd,
pingwebfit .locator-ctrl,
pingwebfit .menu,
pingwebfit .menu-utility,
pingwebfit .submenu .btn {
  -webkit-box-orient: horizontal;
}

pingwebfit .hero_2up .btn {
  font-size: 80%;
  outline-color: #fff;
}

pingwebfit .hero_2up_boxed .hero-bd {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: unset;
  display: block;
  left: 50%;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 35vw;
  padding: 1.5em 2em;
}

@media (max-width: 1024px) {
  .hero_2up_boxed .hero-bd {
    font-size: 100%;
  }
}

@media (max-width: 768px) {
  .hero_2up_boxed .hero-bd {
    font-size: 100%;
  }
}

@media (max-width: 600px) {
  .hero_2up .hero-bd-big {
    min-height: unset;
  }

  .hero_2up_boxed .hero-bd {
    font-size: 175%;
    padding-bottom: 2em;
    padding-top: 2em;
    width: 85vw;
  }
}

pingwebfit .hero_2up_boxed .hero-bd-small {
  position: relative;
  font-size: 100%;
  letter-spacing: 0.15em;
}

pingwebfit .hero_2up_boxed .hero-bd-small:after {
  display: block;
  width: 3ch;
  margin: 0.75em 0;
  position: relative;
  left: calc(50% - 1.5ch);
}

@media (max-width: 768px) {
  .hero_2up_boxed .hero-bd-small {
    font-size: 120%;
  }
}

@media (max-width: 600px) {
  .hero_2up_boxed .hero-bd-small {
    font-size: 150%;
  }
}

pingwebfit .hero_2up_boxed .hero-bd-big {
  font-size: 190%;
  height: 4em;
  line-height: 1.2;
  margin-bottom: 0.75em;
}

@media (max-width: 600px) {
  .hero_2up_boxed .hero-bd-big {
    height: unset;
    margin-bottom: 0.5em;
  }
}

pingwebfit .hero_2up_boxed .btn {
  margin: 0;
}

@media (max-width: 1024px) {
  .hero_2up_boxed .btn {
    font-size: 100%;
  }
}

@media (max-width: 768px) {
  .hero_2up_boxed .btn {
    font-size: 120%;
  }
}

@media (max-width: 600px) {
  .hero_2up_boxed .btn {
    font-size: 150%;
  }
}

pingwebfit .hero_small .hero-image {
  height: calc(100vw * 0.24306);
}

pingwebfit .hero_small .hero-bd {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 18px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 1rem 1rem 1rem 0;
  text-transform: uppercase;
}

pingwebfit .hero_small .hero-bd-big {
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0.25em;
  margin: 0 1em 0 0;
}

@media (max-width: 425px) {
  .hero_small .hero-bd {
    left: 0;
    padding: 0.33em 0;
    position: relative;
    right: 0;
  }

  .hero_small .hero-bd .link {
    white-space: nowrap;
  }

  .hero_small .hero-bd-big {
    color: #000;
    letter-spacing: 0.1em;
    line-height: 1.1;
  }
}

pingwebfit .hr {
  margin: 0 0 2rem;
  padding-bottom: 2rem;
  border-top: none;
  border-bottom: 1px solid #e1e1e1;
}

pingwebfit .hr_thicker {
  border-bottom-width: 0.1em;
}

pingwebfit .hr_skinny_bottom {
  padding-bottom: 1rem;
}

pingwebfit .hr_top {
  margin: 2rem 0 0;
  padding-top: 2rem;
  border-bottom: none;
  border-top: 1px solid #e1e1e1;
}

pingwebfit .hr_top_thicker {
  border-top-width: 0.1em;
}

pingwebfit .hr_top_skinny_bottom {
  padding-top: 1rem;
}

pingwebfit .vr_border_left {
  border-left: #0371b5 solid 0.25em;
}

pingwebfit .kForm-caption {
  color: #616a70;
  line-height: 1.5;
  font-size: 0.9rem;
  text-transform: uppercase;
}

pingwebfit .kForm-control {
  display: block;
  width: 100%;
  position: relative;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: 2px solid #c3c3c3;
  font: inherit;
  outline: 0;
}

pingwebfit .kForm-control:disabled::-webkit-input-placeholder {
  color: #f1f1f1;
}

pingwebfit .kForm-control:disabled::-ms-input-placeholder {
  color: #f1f1f1;
}

pingwebfit .kForm-control:disabled::-moz-placeholder {
  color: #f1f1f1;
}

pingwebfit .kForm-control::-webkit-input-placeholder {
  color: #c3c3c3;
}

pingwebfit .kForm-control::-moz-placeholder {
  color: #c3c3c3;
}

pingwebfit .kForm-control:-ms-input-placeholder {
  color: #c3c3c3;
}

pingwebfit .kForm-control:disabled {
  border-color: #f1f1f1;
}

pingwebfit .kForm-control:focus {
  border-color: #0371b5;
}

pingwebfit .kForm-control:focus:invalid {
  border-color: #d40000;
}

pingwebfit .Error .kForm-control,
pingwebfit .kForm .Error .input-field_select-arrow,
pingwebfit .kForm .Error .input-field_select-select {
  border-color: #d40000;
  color: #d40000;
}

pingwebfit .kForm .EditingFormErrorLabel {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #d40000;
}

pingwebfit .kForm-notes {
  font-size: 0.9rem;
  color: #616a70;
  margin-bottom: 2rem;
}

pingwebfit .kForm-notes-hd,
pingwebfit .kForm-notes-list-item {
  margin-bottom: 0.5rem;
}

pingwebfit .kForm-notes-hd {
  font-weight: 600;
  margin-left: 1rem;
}

pingwebfit .kForm-notes-list {
  margin-left: 2rem;
}

pingwebfit .kForm-yellow {
  background: #ff0;
  padding: 1em;
}

pingwebfit .thumbnailViewer {
  text-align: center;
}

pingwebfit .thumbnailViewer-viewer {
  position: relative;
  margin-bottom: 40px;
}

pingwebfit .thumbnailViewer-viewer-img {
  display: block;
  width: 100%;
}

pingwebfit .thumbnailViewerThumbnails {
  font-size: 0;
  margin: -8px auto auto -8px;
}

pingwebfit .thumbnailViewerThumbnails-item {
  display: inline-block;
  padding-top: 8px;
  padding-left: 8px;
  width: 63px;
}

pingwebfit .thumbnailViewerThumbnails-item-btn {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}

pingwebfit .thumbnailViewerThumbnails-item-btn:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 10px;
}

pingwebfit .thumbnailViewerThumbnails-item-btn:focus:after {
  background-color: #0371b5;
  bottom: 0;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
}

pingwebfit .thumbnailViewerThumbnails-item-btn_isActive {
  opacity: 0.5;
}

pingwebfit .thumbnailViewerThumbnails-item-btn-img {
  display: block;
  width: 100%;
}

pingwebfit .locationTile {
  margin: 0.5rem 1rem;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  background-color: #fff;
}

pingwebfit .locationTile-events,
pingwebfit .locationTile-features {
  border-top: 1px solid #bbb;
  margin-top: 1rem;
  padding: 1rem 1rem 0 2.75rem;
}

@media (min-width: 769px) {
  .locationTile_isActive {
    background-color: #f8f8f8;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    position: relative;
  }

  .locationTile_isActive:after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    border-left: 10px solid #1ba5f5;
    top: 0;
    left: 0;
  }
}

pingwebfit .locationTile-main-idx {
  font-weight: 700;
  font-size: 2rem;
  min-width: 2rem;
  max-width: 2rem;
  text-align: center;
}

pingwebfit .locationTile:nth-child(n + 100) .locationTile-main-idx {
  min-width: 3rem;
  max-width: 2rem;
}

pingwebfit .locationTile-main-bd {
  line-height: 1.15;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-right: 1rem;
  margin-left: 0.75rem;
}

pingwebfit .locationTile-main-bd-specialNotice {
  color: #5c9228;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  letter-spacing: 0.1em;
}

pingwebfit .locationTile-main-bd-name {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 0.25rem;
}

pingwebfit .locationTile-main-bd-content {
  font-size: 0.8rem;
  font-weight: 400;
}

pingwebfit .locationTile-main-bd-dist {
  font-size: 0.9rem;
  line-height: 1.25;
  margin-right: 1rem;
}

pingwebfit .locationTile-events-event {
  margin-bottom: 1rem;
  line-height: 1.15;
  font-size: 0.8rem;
}

pingwebfit .locationTile-events-event:last-child {
  margin-bottom: 0;
}

pingwebfit .locationTile-events-event h1,
pingwebfit .locationTile-events-event h2,
pingwebfit .locationTile-events-event h3,
pingwebfit .locationTile-events-event h4,
pingwebfit .locationTile-events-event h5,
pingwebfit .locationTile-events-event h6 {
  text-transform: uppercase;
  margin-bottom: 0.4rem;
}

pingwebfit .locationTile-features {
  font-size: 0.8rem;
}

pingwebfit .locationTile-features h1,
pingwebfit .locationTile-features h2,
pingwebfit .locationTile-features h3,
pingwebfit .locationTile-features h4,
pingwebfit .locationTile-features h5,
pingwebfit .locationTile-features h6 {
  text-transform: uppercase;
}

pingwebfit .locationTile:nth-child(n + 100) .locationTile-features {
  padding-left: 3.75rem;
}

pingwebfit .locationTile-features .bulletList {
  font-size: 0.8rem;
  margin-top: 0.1rem;
}

@media (max-width: 768px) {
  .locationTile-main-bd-dist {
    margin-right: 0;
    text-align: center;
  }

  [data-locator-view-map-button] {
    display: none;
  }
}

pingwebfit .locator-hd {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 1rem 0.75rem 0;
}

@media (max-width: 425px) {
  .locator-hd {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
}

@media (min-width: 600px) {
  .locator-hd {
    font-size: 1.25rem;
    line-height: 54px;
    margin: 0 1rem 0 0;
  }
}

pingwebfit .locator-hd_bigger {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  margin: 0 0 1.5rem;
}

@media (max-width: 768px) {
  .locator-hd_bigger {
    font-size: 1.5rem;
  }
}

pingwebfit .locator-ctrl {
  height: 3em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  min-width: 295px;
}

@media (max-width: 600px) {
  .locator-ctrl {
    font-size: 85%;
  }
}

@media (max-width: 425px) {
  .locator-ctrl {
    font-size: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

pingwebfit .locator-ctrl_bigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

pingwebfit .locator-ctrl-leftBtn {
  background-color: #f1f1f1;
  border-bottom-left-radius: 0.2em;
  border-bottom-right-radius: 0;
  border-color: #bbb;
  border-style: solid;
  border-top-left-radius: 0.2em;
  border-top-right-radius: 0;
  border-width: 2px 0 2px 2px;
  color: #0371b5;
  margin: 0;
  padding: 0 1em;
  -webkit-transition: color 0.3s ease, background-color 0.3s ease;
  transition: color 0.3s ease, background-color 0.3s ease;
}

pingwebfit .locator-ctrl-leftBtn > svg {
  font-size: 1.25rem;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
}

pingwebfit .locator-ctrl-leftBtn:hover {
  background-color: #e4e4e4;
  color: #0381ce;
}

pingwebfit .locator-ctrl-leftBtn[disabled] {
  color: #616a70;
}

pingwebfit .locator-ctrl-leftBtn[disabled]:hover {
  background-color: #f1f1f1;
  color: #616a70;
}

pingwebfit .locator-ctrl-leftBtn[disabled] svg {
  color: #616a70;
  opacity: 0.5;
}

@media (max-width: 425px) {
  .locator-ctrl-leftBtn {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 0.5em;
  }
}

pingwebfit .mix-section_black .locator-ctrl-leftBtn {
  border-width: 0;
}

pingwebfit .locator-ctrl-leftBtn:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: -4px;
  -moz-outline-radius: 0.2em;
}

pingwebfit .locator-ctrl-input {
  border-color: #bbb;
  border-style: solid;
  border-width: 2px 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0;
  padding: 0 1em;
  min-width: 275px;
}

@media (max-width: 425px) {
  .locator-ctrl-input {
    padding-top: 1em;
    padding-bottom: 1em;
    border-right-width: 2px;
    border-top-right-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    margin-bottom: 0.5em;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

pingwebfit .mix-section_black .locator-ctrl-input {
  border-width: 0;
}

pingwebfit .locator-ctrl-input:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: -4px;
}

pingwebfit .locator-ctrl-rightBtn {
  padding: 0 1.5em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (max-width: 425px) {
  .locator-ctrl-rightBtn {
    padding-top: 1em;
    padding-bottom: 1em;
    border-top-left-radius: 0.2em;
    border-bottom-left-radius: 0.2em;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
}

pingwebfit .locator-ctrl-rightBtn:focus {
  outline: #fff dotted 1px;
  outline-offset: -4px;
  -moz-outline-radius: 0.2em;
}

pingwebfit .locatorResults {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  height: 700px;
  max-height: calc(100vh - 150px);
}

@media (max-width: 768px) {
  .locatorResults {
    max-height: unset;
    height: auto;
  }

  .locatorResults-map {
    display: none;
  }
}

pingwebfit .locatorResults-list {
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 900px) {
  .locatorResults-list {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

pingwebfit .locatorResults-map {
  height: 100%;
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
}

@media (max-width: 900px) {
  .locatorResults-map {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

pingwebfit .mediaCenter-filter,
pingwebfit .menu {
  display: -webkit-box;
  display: -ms-flexbox;
}

pingwebfit .locatorResults-list-toolbar {
  margin: 0.5rem 1rem;
  padding: 0.75rem;
}

pingwebfit .locatorResults-list-list {
  padding-bottom: 100%;
}

@media (max-width: 768px) {
  .locatorResults-list-list {
    padding-bottom: 0;
  }
}

pingwebfit .mediaCenter-filter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 60px;
  padding-left: calc((100vw - 1240px) / 2);
  padding-right: calc((100vw - 1240px) / 2);
}

@media (max-width: 768px) {
  .mediaCenter-filter {
    padding-top: 39.6px;
    padding-bottom: 39.6px;
  }
}

@media (max-width: 768px) {
  .mediaCenter-filter {
    margin-bottom: 39.6px;
  }
}

@media (max-width: 425px) {
  .mediaCenter-filter {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1240px) {
  .mediaCenter-filter {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 768px) {
  .mediaCenter-filter {
    padding-left: 15px;
    padding-right: 15px;
  }
}

pingwebfit .mediaCenter-filter > * {
  margin: 0.25rem 0.5rem;
}

pingwebfit .mediaCenter-filter > :first-child {
  margin-left: 0;
  margin-right: 1rem;
}

pingwebfit .mediaCenter-filter > :last-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: right;
}

pingwebfit .mediaCenter-filter > fieldset > * {
  margin-bottom: 1rem;
}

pingwebfit .mix-section_black .mediaCenter-filter {
  background-color: #fff;
  color: #000;
}

pingwebfit
  .mix-section_black
  .mediaCenter-filter
  .checkbox-input
  + .checkbox-style
  > .checkbox-style-label {
  color: #616a70;
}

pingwebfit
  .mix-section_black
  .mediaCenter-filter
  .checkbox-input:checked
  + .checkbox-style
  > .checkbox-style-label {
  color: #000;
}

pingwebfit .mediaCenter-filter-ctrl {
  text-align: center;
}

pingwebfit .mediaCenter-hd-sm {
  position: relative;
  font-size: 0.9rem;
  letter-spacing: 0.15em;
  text-align: center;
  text-transform: uppercase;
}

pingwebfit .mediaCenter-hd-sm:after {
  content: '';
  display: block;
  width: 3ch;
  height: 0;
  border-top: 2px solid #0371b5;
  margin: 1rem 0;
  position: relative;
  left: calc(50% - 1.5ch);
}

pingwebfit .mediaCenter-hd-big {
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 2rem;
  text-align: center;
}

pingwebfit .mediaCenter-bd > * {
  display: inline-block;
  margin-left: -4px;
}

pingwebfit .mediaCenter-bd > :first-child {
  float: left;
  margin-right: 23px;
  width: calc(50% - (0.5 * 20px) + 2px);
}

pingwebfit .mediaCenter-bd > :not(:first-child) {
  margin-bottom: 20px;
  width: calc(25% - 0.75 * 20px);
}

pingwebfit .mediaCenter-bd :nth-child(4n + 7),
pingwebfit .mediaCenter-bd :nth-child(4n + 8),
pingwebfit .mediaCenter-bd > :nth-child(2),
pingwebfit .mediaCenter-bd > :nth-child(4),
pingwebfit .mediaCenter-bd > :nth-child(4n + 6) {
  margin-right: 20px;
}

pingwebfit .mediaCenter-bd > :nth-child(4n + 9) {
  margin-right: 0;
}

pingwebfit .mediaCenter-bd_3up {
  margin-left: auto;
  margin-right: auto;
  max-width: 925px;
}

pingwebfit .mediaCenter-bd_3up > :nth-child(1) {
  margin-right: 24px;
  width: calc(66.7% - (0.333 * 20px) - 4px);
}

pingwebfit .mediaCenter-bd_3up > :not(:first-child) {
  margin-bottom: 20px;
  width: calc(33% - (0.667 * 20px));
}

pingwebfit .mediaCenter-bd_3up > :nth-child(2),
pingwebfit .mediaCenter-bd_3up > :nth-child(3) {
  margin-right: 0;
}

pingwebfit .mediaCenter-bd_3up :nth-child(3n + 5),
pingwebfit .mediaCenter-bd_3up > :nth-child(3n + 4) {
  margin-right: 20px;
}

pingwebfit .mediaCenter-bd_3up > :nth-child(3n + 6) {
  margin-right: 0;
}

@media (min-width: 768px) {
  .mediaCenter-filter > fieldset > * {
    float: left;
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

@media (max-width: 900px) {
  .mediaCenter-bd > :nth-child(1) {
    margin-right: 24px;
    width: calc(66.7% - (0.333 * 20px) - 4px);
  }

  .mediaCenter-bd > :not(:first-child) {
    margin-bottom: 20px;
    width: calc(33% - (0.667 * 20px));
  }

  .mediaCenter-bd > :nth-child(2),
  .mediaCenter-bd > :nth-child(3) {
    margin-right: 0;
  }

  .mediaCenter-bd :nth-child(3n + 5),
  .mediaCenter-bd > :nth-child(3n + 4) {
    margin-right: 20px;
  }

  .mediaCenter-bd > :nth-child(3n + 6) {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .mediaCenter-bd > :nth-child(n) {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

pingwebfit .mediaCenter-ft {
  clear: both;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 0.1em;
  padding-top: 1rem;
  text-align: center;
  text-transform: uppercase;
}

pingwebfit #mediaCenterBack {
  padding-left: calc((100vw - 1240px) / 2);
  margin-top: -2%;
  padding-bottom: 2%;
}

@media (max-width: 1240px) {
  #mediaCenterBack {
    padding-left: 25px;
  }
}

@media (max-width: 768px) {
  #mediaCenterBack {
    padding-left: 15px;
  }
}

pingwebfit #mediaCenterBack.icon {
  margin-top: -5px;
}

pingwebfit {
  padding-top: 118px;
}

@media (min-width: 769px) and (max-width: 900px) {
  pingwebfit {
    padding-top: 89px;
  }
}

pingwebfit body.js-kenticoEditMode {
  padding-top: 0;
}

pingwebfit .menu {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: flex;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 1rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

pingwebfit .menu-utility,
pingwebfit .submenu-loadingSpinner {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-direction: normal;
}

pingwebfit .menu > :first-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

pingwebfit .menu .link:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 6px;
}

pingwebfit .menu-utility {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #f1f1f1;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 0.8rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.66em 0;
  padding-left: calc((100vw - 1240px) / 2);
  padding-right: calc((100vw - 1240px) / 2);
}

@media (max-width: 1240px) {
  .menu-utility {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 768px) {
  .menu-utility {
    padding-left: 15px;
    padding-right: 15px;
  }
}

pingwebfit .menu-utility > * {
  padding-left: 1em;
  padding-right: 1em;
}

pingwebfit .menu-utility > :first-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 0;
}

pingwebfit .menu-utility > :last-child {
  padding-right: 0;
}

pingwebfit .menu-logo {
  height: 36px;
  margin: 25px 0;
  -webkit-transition: margin 0.3s ease;
  transition: margin 0.3s ease;
  width: 140px;
}

@media (max-width: 1024px) {
  .menu-logo {
    width: 120px;
  }

  .submenu {
    font-size: 0.85rem;
  }
}

@media (max-width: 900px) {
  .menu-logo {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .menu-logo {
    margin: 10px 0;
  }
}

@media (max-width: 600px) {
  .menu-logo {
    width: 85px;
  }
}

pingwebfit .menu-logo_compact {
  margin: 12px 0;
}

pingwebfit .submenu-col-category + .submenu-col-category,
pingwebfit .submenu-col > * + * {
  margin-top: 1em;
}

pingwebfit .menu-item {
  -ms-flex-item-align: center;
  align-self: center;
  border-bottom: 3px solid transparent;
  margin-left: 1.75rem;
  padding: 0.66em 0;
}

pingwebfit .menu-item.active {
  border-color: #0371b5;
}

@media (max-width: 768px) {
  .menu-item {
    padding: 0;
    margin-left: 0;
  }
}

pingwebfit .menu-item.menu-item_search {
  display: none !important;
}

pingwebfit .submenu {
  background-color: #fff;
  bottom: 0;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  display: none;
  font-size: 0.85rem;
  font-weight: 300;
  left: 0;
  padding: 2em 0;
  position: absolute;
  -webkit-transform: translateY(99%);
  transform: translateY(99%);
  width: 100%;
  z-index: 10;
}

pingwebfit .submenu .btn {
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-weight: 600;
}

pingwebfit .submenu-loadingSpinner,
pingwebfit .tile,
pingwebfit .tile_pro-caption {
  -webkit-box-orient: vertical;
}

pingwebfit .submenu .btn > .icon {
  margin-left: 1rem;
}

pingwebfit .submenu-loadingSpinner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2e3134;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 2rem;
  height: 3rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

pingwebfit .submenu-col .btn {
  max-width: 100%;
  white-space: normal;
}

pingwebfit .submenu-col_separator {
  position: relative;
  padding-left: 20px;
}

pingwebfit .submenu-col_separator:before {
  border-left: 1px solid #e1e1e1;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

pingwebfit .submenu-col-category {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

pingwebfit .submenu-col-category > :first-child {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

pingwebfit .submenu-col-category > :last-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

pingwebfit .submenu-col-category > a.submenu-col-category-img img {
  width: 100%;
}

pingwebfit .submenu-col-category-img {
  margin-right: 1rem;
  width: 35%;
}

@media (max-width: 1024px) {
  .submenu-col-category-img {
    margin-right: 0.5rem;
    width: 25%;
  }
}

@media (max-width: 900px) {
  .submenu-col_separator {
    padding-left: 10px;
  }

  .submenu-col-category-img {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .submenu-col-category-img {
    display: none;
  }
}

pingwebfit .submenu-col-category > a > img.submenu-col-category-img {
  width: auto;
}

pingwebfit .submenu-col-hdg {
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.12em;
  padding-bottom: 0.8rem;
  line-height: 1.2;
  text-transform: uppercase;
}

pingwebfit .submenu-col-hdg .icon {
  margin-top: -5px;
}

pingwebfit .submenu-col-category-items,
pingwebfit .submenu-col-category-items > * {
  padding-bottom: 0.5em;
}

pingwebfit .submenu-col-cta + * {
  margin-top: 1em;
}

pingwebfit .tileBox.tileBox_noCollapse.tileBox_5up_padded.ecomm__shopMegaMenu {
  overflow-y: hidden;
}

pingwebfit
  .tileBox.tileBox_noCollapse.tileBox_5up_padded.ecomm__shopMegaMenu
  > div:first-child {
  min-width: 300px;
  padding-left: 0;
}

pingwebfit
  .tileBox.tileBox_noCollapse.tileBox_5up_padded.ecomm__shopMegaMenu
  .submenu-col-featuredProduct {
  margin-left: 30px;
}

pingwebfit .submenu-col-featuredProduct {
  overflow-x: hidden;
  overflow-y: hidden;
}

pingwebfit .submenu-col-featuredProduct > a > img,
pingwebfit .submenu-col-featuredProduct > img {
  height: 12rem;
  width: auto;
  margin-bottom: 20px;
}

pingwebfit .menu-search,
pingwebfit .menu-search-field {
  height: 100%;
  width: 100%;
}

pingwebfit .submenu-col-text {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 18px;
}

pingwebfit .submenu_search {
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0;
  padding: 0.5rem 0 0.5rem 0.5rem;
  top: 0;
  -webkit-transform: none;
  transform: none;
}

pingwebfit .submenu_search_mobile {
  background-color: #f1f1f1;
}

pingwebfit .menu-search input::-webkit-input-placeholder {
  color: #616a70;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.05em;
}

pingwebfit .menu-search input:-ms-input-placeholder {
  color: #616a70;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.05em;
}

pingwebfit .menu-search input::placeholder {
  color: #616a70;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.05em;
}

pingwebfit .menu-search-field {
  border-radius: 3px;
  border: 1px solid #bbb;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
  padding-left: 1em;
}

pingwebfit .menu-search-field:focus {
  border-color: #0371b5;
  outline: 0;
}

pingwebfit .menu-search-searchBtn {
  color: #0371b5;
  font-size: 25px;
  height: calc(100% - 1rem);
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
}

pingwebfit .menu-search-searchBtn:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: -5px;
}

pingwebfit .menu-dropdown {
  position: relative;
}

pingwebfit .menu-drop-nav {
  display: none;
  background: #fff;
  border-left: 3px solid #0375ba;
  position: absolute;
  padding: 5px 40px 5px 5px;
  width: 180px;
  z-index: 3;
}

pingwebfit #helloUser:hover > #desktopUserMenu,
pingwebfit .menu-dropdown:hover > .menu-drop-nav {
  display: block;
}

pingwebfit .menu-drop-nav li {
  margin-top: 20px;
}

pingwebfit #helloUser {
  position: relative;
}

pingwebfit .flexLayout.flexLayout__spaceBetween {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

pingwebfit .flexLayout.flexLayout__alignEnd {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

pingwebfit .mobileMenu__register {
  margin-top: 8px;
}

pingwebfit .mobileMenu__signIn {
  margin-top: 0;
  padding-bottom: 40px;
}

pingwebfit .mobileMenu__numItemsCart {
  background-color: #0375ba;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  position: absolute;
  z-index: 5;
  color: #fff;
  font-size: 10px;
  right: 5px;
  bottom: 8px;
  text-align: center;
  padding-top: 4px;
}

@media (min-width: 769px) and (max-width: 900px) {
  .mobileMenu__numItemsCart {
    right: 15px;
    bottom: 23px;
  }
}

pingwebfit .menuDesktop_103w {
  width: 103px;
}

pingwebfit #desktopUserMenu {
  display: none;
  position: absolute;
  z-index: 5;
  padding-top: 20px;
  right: 10px;
}

pingwebfit .cart_summary > *,
pingwebfit .cart_summary_main .items .card_full > *,
pingwebfit .cart_summary_main .items > *,
pingwebfit .ecomm__desktopmenu__cart__cont,
pingwebfit .subtotal.bubble {
  position: relative;
}

pingwebfit #desktopUserMenu .usermenu-content {
  background: #fff;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-left: 3px solid #0375ba;
  padding: 19px 65px 18px 29px;
  width: 177px;
  white-space: nowrap;
}

pingwebfit #desktopUserMenu .usermenu-content > div {
  margin-top: 18px;
}

pingwebfit #desktopUserMenu .usermenu-content > div:first-child {
  margin-top: 0;
}

pingwebfit .tileBox_5up_padded.tileBox_noCollapse.ecomm__shopMegaMenu > * {
  min-width: 0;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

pingwebfit
  .tileBox_5up_padded.tileBox_noCollapse.ecomm__shopMegaMenu
  .submenu-col,
pingwebfit
  .tileBox_5up_padded.tileBox_noCollapse.ecomm__shopMegaMenu
  .submenu-col_separator {
  padding-left: 60px;
}

pingwebfit
  .tileBox_5up_padded.tileBox_noCollapse.ecomm__shopMegaMenu
  .link_subMenu {
  white-space: nowrap;
  text-overflow: ellipsis;
}

pingwebfit .divider__desktopmenu__item {
  position: relative;
  top: 1px;
}

pingwebfit .ecomm__desktopmenu__cart span {
  font-size: 14px;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;
}

pingwebfit .ecomm__desktopmenu__cart span.ecomm__desktopmenu__cart__bold {
  font-weight: 600;
}

pingwebfit .ecomm__desktopmenu__cart > svg.icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

pingwebfit .utility-flag-icon {
  margin-right: 3px;
}

pingwebfit .cart_empty,
pingwebfit .cart_list {
  display: none;
}

pingwebfit .cart_empty.isActive,
pingwebfit .cart_list.isActive {
  display: block;
}

pingwebfit .cart_summary,
pingwebfit .cart_summary_main .items {
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

pingwebfit .cart_summary {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow-x: hidden;
}

pingwebfit .cart_summary > :first-child {
  -ms-flex-preferred-size: 63%;
  flex-basis: 63%;
  max-width: 63%;
  min-width: 63%;
  margin-left: 0;
  margin-right: auto;
}

pingwebfit .cart_summary > :last-child {
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  max-width: 30%;
  min-width: 30%;
  margin-left: auto;
  margin-right: 0;
}

pingwebfit .cart_summary_main .items {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow-x: unset;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

pingwebfit .cart_summary_main .items .card_full {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow-x: hidden;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

pingwebfit
  .cart_summary_main
  .items
  .card_full
  + .cart_summary_main
  .items
  .card_full {
  margin-top: 1em;
}

pingwebfit .cart_summary_main .items .card_full > :first-child {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

pingwebfit .cart_summary_main .items .card_full > :last-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

pingwebfit .cart_summary_main .items .card_full > .card-product-info,
pingwebfit
  .cart_summary_main
  .items
  .card_full
  > .card-product-info
  > .card-product-header {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

pingwebfit .cart_summary_main .items .card_full > a.card-product-image img {
  width: 100%;
}

pingwebfit .cart_summary_main .items .card_full > .card-product-info {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow-x: unset;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 1em;
  padding-right: 1em;
}

pingwebfit
  .cart_summary_main
  .items
  .card_full
  > .card-product-info
  > .card-product-header {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

pingwebfit
  .cart_summary_main
  .items
  .card_full
  > .card-product-info
  > .card-product-header
  > .card-product-name {
  width: calc(100% - 1em - 10px);
  margin-left: 0;
  margin-right: auto;
}

pingwebfit
  .cart_summary_main
  .items
  .card_full
  > .card-product-info
  > .card-product-header
  > .card-product-delete {
  width: 1em;
  margin-left: auto;
  margin-right: 0;
}

pingwebfit .cart_summary_main .items .card_full > .card-product-info > .txt {
  width: 100%;
}

pingwebfit
  .cart_summary_main
  .items
  .card_full
  > .card-product-info
  > .card-product-footer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow-x: hidden;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

pingwebfit
  .cart_summary_main
  .items
  .card_full
  > .card-product-info
  > .card-product-footer
  > .product-quantity {
  width: 70%;
  margin-left: 0;
  margin-right: auto;
}

pingwebfit
  .cart_summary_main
  .items
  .card_full
  > .card-product-info
  > .card-product-footer
  > .product-price {
  width: 25%;
  margin-left: auto;
  margin-right: 0;
}

pingwebfit .card-product-image {
  margin-right: 1rem;
  width: 10%;
}

@media (max-width: 1024px) {
  .card-product-image {
    margin-right: 0.5rem;
    width: 10%;
  }
}

@media (max-width: 900px) {
  .card-product-image {
    width: 10%;
  }
}

pingwebfit .card-product-info,
pingwebfit .card-product-info > * {
  padding-bottom: 0.5em;
}

pingwebfit .card-product-delete svg {
  height: 21px;
  width: 21px;
}

pingwebfit .card-product-name > a.link {
  color: #0375ba;
  font-family: 'Proxima Nova';
  font-size: 24px;
  font-weight: 600;
}

pingwebfit .subtotal.bubble,
pingwebfit .subtotal.bubble-arrow:after {
  background: #fff;
  border: 2px solid #0371b5;
}

pingwebfit .subtotal.bubble {
  padding: 1em;
  border-radius: 0.3em;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

pingwebfit .subtotal.bubble > div:first-child {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

pingwebfit .subtotal.bubble-arrow {
  width: 4.5em;
  height: 1em;
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -2.25em;
  bottom: -1em;
}

pingwebfit .subtotal.bubble-arrow:after {
  content: '';
  position: absolute;
  left: 20px;
  top: -20px;
  width: 25px;
  height: 25px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

pingwebfit .moments-bd-content:before,
pingwebfit .shareBar:after {
  content: '';
}

@media (max-width: 768px) {
  .card-product-image {
    display: none;
  }

  pingwebfit {
    padding-top: 58px;
  }

  body.js-kenticoEditMode {
    padding-top: 0;
  }
}

@media (max-width: 600px) {
  pingwebfit {
    padding-top: 54px;
  }

  body.js-kenticoEditMode {
    padding-top: 0;
  }
}

pingwebfit .mobileMenu {
  background-color: #fff;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.125);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.125);
  display: none;
  left: 0;
  max-height: calc(100vh - 80px);
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-bottom: 28px;
  overflow-x: hidden;
  z-index: 6;
}

pingwebfit .mobileMenu_footer,
pingwebfit .mobileMenu_sideNav {
  display: block;
  max-height: unset;
  overflow-y: auto;
}

pingwebfit .mobileMenu_footer {
  -webkit-box-shadow: none;
  box-shadow: none;
  left: -15px;
  position: relative;
  top: 0;
  width: calc(100% + 30px);
}

pingwebfit .mobileMenu_sideNav {
  margin-left: calc(-1 * ((100vw - 1240px) / 2));
  background-color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1.33rem;
  margin-bottom: 2rem;
  overflow-x: hidden;
  padding: 0.5rem 0;
  position: relative;
  top: 0;
  width: 100vw;
}

@media (max-width: 1240px) {
  .mobileMenu_sideNav {
    margin-left: -25px;
  }
}

@media (max-width: 768px) {
  .mobileMenu_sideNav {
    margin-left: -15px;
  }
}

pingwebfit .mobileMenu-subMenu {
  padding-left: 1.5rem;
  display: none;
}

pingwebfit .mobileMenu-item {
  line-height: 2.5;
  position: relative;
}

pingwebfit .mobileMenu-item_l1 {
  border-bottom: 1px solid rgba(97, 106, 112, 0.25);
  font-size: 1.25rem;
  font-weight: 600;
  padding: 10px 15px;
  text-transform: uppercase;
}

pingwebfit .mobileMenu-item_l1_userManagement,
pingwebfit .mobileMenu-item_l2,
pingwebfit .userStateItem {
  text-transform: none;
}

pingwebfit .mobileMenu-item_l2 {
  font-weight: 400;
}

pingwebfit .mobileMenu-item_l3 {
  font-size: 1.15rem;
  font-weight: 300;
}

pingwebfit .mobileMenu-item-button {
  color: #000;
  display: inline-block;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: color 0.2s ease, -webkit-transform 0.2s ease;
  transition: color 0.2s ease, -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, color 0.2s ease;
  transition: transform 0.2s ease, color 0.2s ease, -webkit-transform 0.2s ease;
}

pingwebfit .moments-bd,
pingwebfit .pageNav-list {
  display: -webkit-box;
  display: -ms-flexbox;
}

pingwebfit .mobileMenu-item-button:focus {
  outline: 0;
}

pingwebfit .mobileMenu-item-button:focus svg {
  outline: #0371b5 dotted 1px;
  outline-offset: 5px;
}

pingwebfit .mobileMenu-item_l1 > .mobileMenu-item-button {
  font-size: 1.25rem;
  margin-right: calc(-0.75em + 15px);
  padding: 0.25em 1em;
  position: absolute;
  right: 0;
  top: 0;
}

pingwebfit .mobileMenu-item_l2 > .mobileMenu-item-button {
  font-size: 0.8rem;
  padding: 0 1rem;
}

pingwebfit .mobileMenu-item-button.active {
  color: rgba(0, 0, 0, 0.5);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

pingwebfit .mobileMenu_sideNav .mobileMenu-item-button {
  color: #fff;
  font-size: 1.5rem;
}

pingwebfit .mobileMenu_sideNav .mobileMenu-item-button.active {
  color: rgba(255, 255, 255, 0.85);
}

pingwebfit .mobileMenu-button {
  color: #000;
  font-size: 1.5rem;
  padding: 0 1rem;
  position: relative;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

pingwebfit .mobileMenu-button:focus {
  outline: 0;
}

pingwebfit .mobileMenu-button:focus svg {
  outline: #0371b5 dotted 1px;
  outline-offset: 5px;
}

pingwebfit .mobileMenu-button:first-child {
  margin-left: -1rem;
}

pingwebfit .mobileMenu-button:last-child {
  margin-right: -1rem;
}

pingwebfit .mobileMenu-button.active {
  color: rgba(0, 0, 0, 0.5);
}

pingwebfit .mobileMenu-button[disabled] {
  color: #616a70;
  cursor: auto;
}

pingwebfit .userStateItem {
  border: none;
  font-size: 14px;
  font-weight: 600;
}

pingwebfit .moments-hd,
pingwebfit .pageNav-label,
pingwebfit .tabset-tabs-tab {
  text-transform: uppercase;
  text-align: center;
}

pingwebfit .moments {
  font-size: 0.9rem;
}

pingwebfit .moments-hd {
  color: #616a70;
  font-weight: 600;
  letter-spacing: 0.15em;
  margin-bottom: 4rem;
}

@media (max-width: 600px) {
  .moments-hd {
    margin-bottom: 2rem;
  }

  .moments-bd {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

pingwebfit .moments-bd {
  display: flex;
  margin: 0 auto;
  max-width: 800px;
  min-height: 190px;
}

pingwebfit .moments-bd-img {
  display: block;
  max-height: 200px;
  max-width: 200px;
  min-height: 200px;
  min-width: 200px;
}

@media (max-width: 600px) {
  .moments-bd-img {
    -ms-flex-item-align: center;
    align-self: center;
    margin-bottom: 2rem;
  }
}

pingwebfit .moments-bd-content {
  margin: 0 1.75em;
  padding-left: 3.75em;
  position: relative;
}

@media (max-width: 600px) {
  .moments-bd-content {
    margin-left: 0;
  }
}

pingwebfit .moments-bd-content-year {
  font-weight: 600;
  margin-bottom: 1rem;
}

pingwebfit .moments-bd-content-text {
  line-height: 2;
}

pingwebfit .pageNav {
  font-size: 1rem;
  margin: 0 auto;
  max-width: 800px;
}

@media (max-width: 600px) {
  .pageNav {
    font-size: 0.8rem;
  }
}

pingwebfit .pageNav-label {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0.25rem 0;
}

pingwebfit .pageNav-list {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}

pingwebfit .pageNav-list-item {
  margin: 0.25em;
}

pingwebfit .proStats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 768px) {
  .proStats {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

pingwebfit .proStats-img {
  -ms-flex-preferred-size: 60%;
  flex-basis: 60%;
  padding-right: 2rem;
}

@media (max-width: 768px) {
  .proStats-img {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-right: 0;
  }
}

pingwebfit .proStats-img-img {
  width: 100%;
  display: block;
}

pingwebfit .proStats-img-caption {
  padding: 1rem 0;
  font-size: 0.8rem;
  color: #616a70;
  font-style: italic;
}

pingwebfit .proStats-content {
  -ms-flex-preferred-size: 40%;
  flex-basis: 40%;
  padding-left: 2rem;
}

pingwebfit .proStats-content-hd {
  font-weight: 600;
  font-size: 2.67rem;
  margin-bottom: 1.5rem;
}

pingwebfit .proStats-content-stats-statGroup {
  margin-bottom: 1rem;
  padding-right: 1rem;
}

@media (max-width: 768px) {
  .proStats-content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-left: 0;
  }

  .proStats-content-stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .proStats-content-stats-statGroup {
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
  }
}

@media (max-width: 600px) {
  .proStats-content-stats-statGroup {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 320px) {
  .proStats-content-stats-statGroup {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

pingwebfit .ping-landing-page-CTA-wrapper,
pingwebfit .searchResults-item {
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
}

pingwebfit .proStats-content-stats-statGroup-title {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

pingwebfit .proStats-content-stats-statGroup-value {
  font-size: 0.8rem;
  font-weight: 400;
}

pingwebfit .proStats-content-stats-statGroup-value > .bulletList {
  font-size: 1em;
}

pingwebfit .searchResults {
  max-width: 1100px;
  margin: 0 auto;
}

pingwebfit .searchResults-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  padding: 1rem;
}

pingwebfit .searchResults-item:last-child {
  margin-bottom: 0;
}

pingwebfit .searchResults-item-img {
  min-width: 20%;
}

pingwebfit .searchResults-item-img img {
  width: 100%;
  display: block;
}

@media (max-width: 425px) {
  .searchResults-item-img {
    display: none;
  }
}

pingwebfit .searchResults-item-txt {
  display: block;
  max-width: 66%;
  padding-left: 2rem;
}

@media (max-width: 425px) {
  .searchResults-item-txt {
    max-width: 100%;
    padding-left: 0;
  }
}

pingwebfit .searchResults-item-txt-path {
  margin-top: 0.5rem;
  color: #5c9228;
}

pingwebfit .searchMatch {
  background-color: #feff8f;
}

pingwebfit .shaftColorCode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 115px;
}

pingwebfit .shaftColorCode-item {
  min-width: 6ch;
  padding: 0.5rem;
  text-align: center;
}

pingwebfit .shaftColorCode-item-img {
  border-radius: 50%;
  -webkit-box-shadow: 0 0 1px 1px #2e3134;
  box-shadow: 0 0 1px 1px #2e3134;
  display: block;
  height: 22px;
  margin: 0 auto 3px;
  width: 22px;
}

pingwebfit .shareBar {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  white-space: nowrap;
  -webkit-transform: translate(-50%, calc(-100% - 15px));
  transform: translate(-50%, calc(-100% - 15px));
  border-radius: 0.25rem;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

pingwebfit .shareBar:after,
pingwebfit .shareBar_bottom:before {
  width: 0;
  height: 0;
  position: absolute;
  left: calc(50% - 10px);
}

pingwebfit .shareBar:after {
  border: 10px solid transparent;
  bottom: -20px;
  border-top: 10px solid #fff;
}

pingwebfit .shareBar_bottom {
  top: unset;
  bottom: 0;
  -webkit-transform: translate(-50%, calc(100% + 15px));
  transform: translate(-50%, calc(100% + 15px));
  -webkit-box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.2);
}

pingwebfit .shareBar_bottom:after {
  content: none;
}

pingwebfit .shareBar_bottom:before,
pingwebfit .sideNav-item > .link:focus:after,
pingwebfit .tabset-tabs-tab:after,
pingwebfit .tile-caption-bd:before,
pingwebfit .tile:focus .tile-imgWrapper::after,
pingwebfit .tile_productMenu-caption-bd:before,
pingwebfit .tile_productMenu::after {
  content: '';
}

pingwebfit .shareBar_bottom:before {
  border: 10px solid transparent;
  top: -20px;
  border-bottom: 10px solid #fff;
}

pingwebfit .shareBar-button {
  font-size: 27px;
  padding: 1.25rem;
  color: #616a70;
}

pingwebfit .shareBar-button:hover {
  color: #fff;
  background-color: #0371b5;
  outline: 0;
}

pingwebfit .shareBar-button:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: -5px;
  -moz-outline-radius: 0.2em;
}

pingwebfit .shareBar-button:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

pingwebfit .shareBar-button:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

pingwebfit .staggeredContent {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

pingwebfit .staggeredContent + .staggeredContent {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid #e1e1e1;
}

pingwebfit .staggeredContent-figure {
  display: block;
  min-width: 33%;
  max-width: 67%;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .staggeredContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

  .staggeredContent-figure {
    margin-bottom: 2rem;
  }
}

pingwebfit .staggeredContent:nth-of-type(odd) > .staggeredContent-figure {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

@media (max-width: 600px) {
  .staggeredContent:nth-of-type(odd) > .staggeredContent-figure {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

pingwebfit .staggeredContent:nth-of-type(even) > .staggeredContent-figure {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

pingwebfit .staggeredContent-figure-img {
  width: 100%;
}

pingwebfit .staggeredContent-figure-caption {
  font-size: 0.8rem;
  color: #616a70;
  line-height: 1.5;
  font-style: italic;
  padding: 0.5rem 0;
}

pingwebfit .staggeredContent:nth-of-type(odd) > .staggeredContent-text {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding-right: 4rem;
}

@media (max-width: 600px) {
  .staggeredContent:nth-of-type(odd) > .staggeredContent-text {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    padding-right: 0;
  }
}

pingwebfit .staggeredContent:nth-of-type(even) > .staggeredContent-text {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  padding-left: 4rem;
}

pingwebfit .staggeredContent-text-hd {
  font-size: 1.33rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

pingwebfit .staggeredContent-text-bd {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.75;
  margin-bottom: 2rem;
  color: #616a70;
}

pingwebfit .staggeredContent-text-bd p + .staggeredContent-text-bd p {
  margin-bottom: 2em;
}

pingwebfit .status {
  display: none;
  font-size: 1rem;
}

pingwebfit .status-indicator {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 100%;
  background-color: #e1e1e1;
  margin-right: 0.2rem;
}

pingwebfit .status-indicator_online {
  background-color: #75ba33;
}

pingwebfit .status.isLoaded {
  display: block;
}

pingwebfit .svg-container {
  margin: 20px auto;
}

pingwebfit .svg-container.svg-float-left {
  float: left;
  margin: 10px 20px;
}

pingwebfit .svg-container.svg-float-right {
  float: right;
  margin: 10px 20px;
}

@media (max-width: 1440px) {
  .svg-container.svg-container-lg.svg-container.svg-float-left,
  .svg-container.svg-container-lg.svg-container.svg-float-right,
  .svg-container.svg-container-xl.svg-container.svg-float-left,
  .svg-container.svg-container-xl.svg-container.svg-float-right {
    float: none;
  }
}

pingwebfit .svg-container.svg-container-xs {
  width: 25%;
}

@media (max-width: 1024px) {
  .svg-container.svg-container-md {
    float: none;
    margin: 20px auto;
  }

  .svg-container.svg-container-xs {
    width: 30%;
  }
}

@media (max-width: 900px) {
  .svg-container.svg-container-sm {
    float: none;
    margin: 20px auto;
  }

  .svg-container.svg-container-xs {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .svg-container.svg-container-xs {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .staggeredContent:nth-of-type(even) > .staggeredContent-text {
    padding-left: 0;
  }

  .svg-container.svg-container-xs {
    float: none;
    margin: 20px auto;
    width: 80%;
  }
}

@media (max-width: 425px) {
  .svg-container.svg-container-xs {
    width: 100%;
  }
}

pingwebfit .svg-container.svg-container-sm {
  width: 40%;
}

@media (max-width: 1024px) {
  .svg-container.svg-container-sm {
    width: 50%;
  }
}

@media (max-width: 900px) {
  .svg-container.svg-container-sm {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .svg-container.svg-container-sm {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .svg-container.svg-container-sm {
    width: 85%;
  }
}

@media (max-width: 425px) {
  .svg-container.svg-container-sm {
    width: 100%;
  }
}

pingwebfit .svg-container.svg-container-md {
  width: 65%;
}

@media (max-width: 1024px) {
  .svg-container.svg-container-md {
    width: 70%;
  }
}

@media (max-width: 900px) {
  .svg-container.svg-container-md {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .svg-container.svg-container-md {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .svg-container.svg-container-md {
    width: 85%;
  }
}

@media (max-width: 425px) {
  .svg-container.svg-container-md {
    width: 100%;
  }
}

pingwebfit .svg-container.svg-container-lg {
  width: 90%;
}

@media (max-width: 1240px) {
  .svg-container.svg-container-lg {
    width: 95%;
  }
}

@media (max-width: 1024px) {
  .svg-container.svg-container-lg {
    width: 100%;
  }
}

pingwebfit .svg-container.svg-container-xl {
  width: 95%;
}

@media (max-width: 1240px) {
  .svg-container.svg-container-xl {
    width: 100%;
  }
}

pingwebfit .tabset-tabs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #2e3134;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow-x: scroll;
  width: 100%;
  -ms-overflow-style: none;
}

pingwebfit .tabset-tabs-tab {
  border-radius: 0;
  color: #f1f1f1;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.075em;
  margin: 1.5rem 0 0;
  padding: 1.5rem 1.5rem 2rem;
  position: relative;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

pingwebfit .tabset-tabs-tab:after {
  border-bottom: 3px solid transparent;
  display: block;
  padding-top: 0.25rem;
  position: absolute;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  width: calc(100% - 3rem);
}

pingwebfit .tabset-tabs-tab:hover::after,
pingwebfit .tabset-tabs-tab_isActive:after {
  border-color: #0371b5;
}

pingwebfit .tabset-tabs-tab:first-child {
  margin-left: 15px;
}

pingwebfit .tabset-tabs-tab:last-child {
  margin-right: 15px;
}

pingwebfit .tabset-tabs-tab:focus {
  outline: #1ba5f5 dotted 1px;
  outline-offset: -10px;
}

pingwebfit .tabset-tabs-tab_isActive {
  background-color: #fff;
  color: #000;
  font-weight: 600;
}

pingwebfit .tabset-bodies {
  padding-left: calc((100vw - 1240px) / 2);
  padding-right: calc((100vw - 1240px) / 2);
  position: relative;
}

@media (max-width: 1240px) {
  .tabset-bodies {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 768px) {
  .tabset-tabs {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .tabset-bodies {
    padding-left: 15px;
    padding-right: 15px;
  }
}

pingwebfit .tabset-bodies-body {
  padding: 3rem 0 0;
  display: none;
  position: absolute;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

pingwebfit .tabset-bodies-body:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: -10px;
}

pingwebfit .tabset-bodies-body_isActive {
  opacity: 1;
  position: relative;
}

pingwebfit .tabset-bodies-body-skinnyWrapper {
  max-width: 900px;
  margin: 0 auto;
}

pingwebfit .sideNav {
  margin-bottom: 2rem;
}

pingwebfit .sideNav-item {
  font-weight: 300;
  padding: 0.25rem 0 0.25rem 0.75rem;
  margin: 0.75rem 0;
  border-left: 4px solid transparent;
}

pingwebfit .sideNav-item > .link:focus {
  color: #0371b5;
  outline: 0;
}

pingwebfit .sideNav-item > .link:focus:after {
  -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
  -webkit-transform: translateY(0.25em);
  transform: translateY(0.25em);
  border-color: #1ba5f5;
  border-width: 2px;
}

pingwebfit .txt .sideNav-item > .link:focus:after {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

pingwebfit .sideNav-item_isActive {
  font-weight: 600;
  border-color: #0371b5;
}

pingwebfit .sideNav-item_isActive:hover {
  border-color: #1ba5f5;
}

pingwebfit .tile {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

pingwebfit .tile.tile_list.global {
  float: left;
  width: 32%;
}

pingwebfit .tile.tile_list.global h3,
pingwebfit .tile.tile_list.global ul {
  margin-right: auto;
  margin-left: auto;
}

pingwebfit .tile_70 {
  width: 70%;
}

pingwebfit .tile:active .tile-imgWrapper {
  border-color: #0371b5;
}

pingwebfit .tile:focus .tile-imgWrapper::after {
  background-color: #0371b5;
  bottom: 0;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
}

pingwebfit .tile-img,
pingwebfit .tile-imgWrapper {
  height: auto;
  min-height: 1px;
}

pingwebfit .tile-caption-bd,
pingwebfit .tile-imgWrapper,
pingwebfit .tile-product-caption-relative,
pingwebfit .tile_product-caption,
pingwebfit .tile_product-img,
pingwebfit .tile_productMenu {
  position: relative;
}

pingwebfit .tile:hover .tile-imgWrapper {
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

pingwebfit .tile-imgWrapper {
  border-radius: 0.2em;
  border: 1px solid #f1f1f1;
  margin: 5px;
  width: calc(100% - 10px);
}

pingwebfit .tile-img {
  display: block;
  width: 100%;
}

pingwebfit .tile-caption {
  padding: 1rem;
  text-align: center;
  width: 100%;
}

pingwebfit .tile-caption-hd {
  font-size: 1.33rem;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}

pingwebfit .tile-caption-subHd {
  font-weight: 600;
  font-size: 1rem;
}

pingwebfit .tile-caption-bd {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.4;
}

pingwebfit .tile-caption-bd:before {
  display: block;
  width: 3ch;
  height: 0;
  border-top: 2px solid #0371b5;
  margin: 1em 0;
  position: relative;
  left: calc(50% - 1.5ch);
}

pingwebfit .tile-caption-eighteen {
  font-size: 18px;
}

pingwebfit .tile_product {
  border: 2px solid #f1f1f1;
  font-size: 1.5em;
  min-height: 1px;
  overflow: hidden;
  -webkit-transition: border-color 0.2s ease, -webkit-box-shadow 0.2s ease;
  transition: border-color 0.2s ease, -webkit-box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease, border-color 0.2s ease;
  transition: box-shadow 0.2s ease, border-color 0.2s ease,
    -webkit-box-shadow 0.2s ease;
}

pingwebfit .tile_product:focus,
pingwebfit .tile_product:hover {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-color: #e4e4e4;
}

pingwebfit .tile_product:focus .tile-img,
pingwebfit .tile_product:hover .tile-img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

pingwebfit .tile_product:focus .tile-caption,
pingwebfit .tile_product:hover .tile-caption {
  border-color: #e4e4e4;
}

pingwebfit .tile_product-img {
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  z-index: 0;
}

pingwebfit .tile_product-caption {
  background-color: #fff;
  text-transform: none;
  -webkit-transition: border-color 0.2s ease;
  transition: border-color 0.2s ease;
}

pingwebfit .tile_product-img + .tile_product-caption {
  border-top: 2px solid #f1f1f1;
}

pingwebfit .tile_product-caption-bd {
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.4;
}

pingwebfit .tile_productMenu.tech .tile_productMenu-label,
pingwebfit
  .tile_productMenu_title.blue
  .tile_productMenu-caption
  .tile-caption-hd.tile_productMenu-caption-hd {
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.2em;
}

pingwebfit .tile_productMenu::after {
  background-color: transparent;
  bottom: 0;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

pingwebfit .tile_productMenu:focus::after {
  background-color: #0371b5;
}

pingwebfit .tile_productMenu_title {
  background-color: #000;
  color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

pingwebfit .tile_productMenu-label {
  background-color: #000;
  color: #fff;
  display: inline-block;
  font-size: 0.8rem;
  left: 0;
  padding: 0.75em 1.5em;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
}

pingwebfit .tile_apparelDetail-caption,
pingwebfit .tile_technology-caption {
  padding-left: 0;
  padding-right: 0;
}

pingwebfit .tile_productMenu.tech .tile-img {
  width: 90%;
  margin-bottom: 3.1rem;
}

pingwebfit .tile_productMenu.tech .tile_productMenu-label {
  top: auto;
  bottom: 10%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: #fff;
  color: #000;
  text-align: center;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border-top: 2px solid #e1e1e1;
  border-bottom: 2px solid #e1e1e1;
  line-height: 1.25;
}

pingwebfit .tile_productMenu_title.blue {
  background-color: #0371b5;
}

pingwebfit
  .tile_productMenu_title.blue
  .tile_productMenu-caption
  .tile-caption-bd.tile_productMenu-caption-bd {
  font-size: 1.5rem;
  font-weight: 600;
}

pingwebfit
  .tile_productMenu_title.blue
  .tile_productMenu-caption
  .tile-caption-bd.tile_productMenu-caption-bd:before {
  border-top: 0;
  margin: 0.5em 0;
}

pingwebfit
  .tile_productMenu_title.blue
  .tile_productMenu-caption
  .btn.btn_primary {
  background-color: #fff;
  color: #0371b5;
}

pingwebfit
  .tile_productMenu_title.blue
  .tile_productMenu-caption
  .btn.btn_primary:hover {
  background-color: #f1f1f1;
  border-color: #fff;
}

pingwebfit .tile_productMenu-caption {
  font-size: 1rem;
}

@media (max-width: 900px) {
  .tile_productMenu-caption {
    font-size: 0.85rem;
  }
}

@media (max-width: 600px) {
  .tile_productMenu-caption {
    font-size: 0.7rem;
  }
}

@media (max-width: 425px) {
  .tile_productMenu_title {
    height: 100vw;
  }

  .tile_productMenu-caption {
    font-size: 1rem;
  }
}

pingwebfit .tile_productMenu-caption-hd {
  font-size: 1.75rem;
  font-weight: 600;
}

pingwebfit .tile_productMenu-caption-bd {
  position: relative;
  font-size: 1.15rem;
  font-weight: 300;
  margin-bottom: 2em;
}

pingwebfit .tile_productMenu-caption-bd:before {
  display: block;
  width: 3ch;
  height: 0;
  border-top: 2px solid #0371b5;
  margin: 1.25em 0;
  position: relative;
  left: calc(50% - 1.5ch);
}

pingwebfit .tile_mediaStory-caption-bd:before,
pingwebfit .tile_technology-caption-bd:before {
  content: none;
}

pingwebfit .tile_mediaStory {
  border-bottom: 2px solid transparent;
  -webkit-transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: border-color 0.2s ease, border-width 0.2s ease,
    -webkit-transform 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease;
  transition: transform 0.2s ease, border-color 0.2s ease,
    border-width 0.2s ease, -webkit-transform 0.2s ease;
}

pingwebfit a.tile_mediaStory:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

pingwebfit .tile_mediaStory:focus,
pingwebfit .tile_mediaStory:hover {
  border-bottom: 2px solid #0371b5;
}

pingwebfit .tile_mediaStory-caption {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: #fff;
  font-size: 1rem;
  left: 0;
  min-height: 103px;
  position: absolute;
  text-align: left;
}

pingwebfit .tile_mediaStory-caption-hd {
  font-size: 1.2rem;
  font-weight: 300;
  height: 2.3em;
  letter-spacing: 0;
  line-height: 1.15;
  margin-bottom: 0.25rem;
  white-space: normal;
}

pingwebfit .tile_mediaStory-caption-bd {
  font-size: 0.67rem;
  font-weight: 400;
  text-transform: uppercase;
}

pingwebfit .tile_carousel-caption-hd {
  color: #616a70;
  font-size: 0.85rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-bottom: 3px solid transparent;
  display: inline-block;
  margin: 0 auto;
}

pingwebfit .ping-landing-page-CTA-wrapper,
pingwebfit .tile_pro-caption {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-direction: normal;
}

pingwebfit .tile_carousel:focus .tile_carousel-caption-hd {
  border-color: #0371b5;
}

pingwebfit .tile_technology-caption-hd {
  color: #2e3134;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: left;
}

pingwebfit .tile_technology-caption-bd {
  color: #2e3134;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 0.015em;
  line-height: 1.5;
  text-align: left;
}

pingwebfit .tile_apparelDetail-caption-hd {
  color: #2e3134;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: left;
}

pingwebfit .tile_pro {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

pingwebfit .tile_pro-caption {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.3;
}

pingwebfit .tile_pro-caption::after {
  background-color: transparent;
  bottom: 0;
  content: '';
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

pingwebfit .tile_pro:focus > .tile_pro-caption::after {
  background-color: #0371b5;
}

pingwebfit .tile_pro-caption-hd {
  color: #616a70;
  font-size: 0.9rem;
  font-weight: 400;
  white-space: normal;
}

pingwebfit .tile_simple-caption {
  padding: 0 0 1rem;
}

pingwebfit .tile_simple-caption-bd {
  text-align: left;
}

pingwebfit .tile_simple-caption-bd:before {
  content: none;
}

pingwebfit .tile_list {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

pingwebfit .tile_list-title {
  font-weight: 600;
  letter-spacing: 0.15em;
  margin-bottom: 1em;
  text-transform: uppercase;
}

pingwebfit .tile_list-list-item {
  margin-bottom: 0.5em;
}

pingwebfit .tile_list-list-item a:after {
  content: ' ';
  display: block;
  border: none;
  height: 0.25em;
  position: relative;
}

pingwebfit .tagContainer {
  position: relative;
  background: #f1f1f1;
  padding: 8px 10px;
  z-index: 5;
  text-transform: uppercase;
  font-size: 12px;
  top: -30px;
  width: 134px;
  margin: 0 auto -20px;
}

pingwebfit .tagContainer.holiday {
  background-color: #ee3425;
  color: #fff;
}

pingwebfit .faqGroup-list-item-answer,
pingwebfit .mix-section_userContent {
  position: relative;
}

pingwebfit .faqGroup-list-item-answer figcaption,
pingwebfit .mix-section_userContent figcaption {
  font-size: 0.8rem;
  font-style: italic;
}

pingwebfit .faqGroup-list-item-answer img,
pingwebfit .mix-section_userContent img {
  height: auto;
  width: 100%;
}

pingwebfit .faqGroup-list-item-answer p,
pingwebfit .mix-section_userContent p {
  margin-top: 1rem;
}

pingwebfit .faqGroup-list-item-answer .hr,
pingwebfit .mix-section_userContent .hr {
  border-bottom-color: #0371b5;
  margin: 0 auto 2rem;
  width: 40px;
}

pingwebfit .faqGroup-list-item-answer ul,
pingwebfit .mix-section_userContent ul {
  list-style-type: square;
  margin-left: 1rem;
  font-size: 0.9em;
}

pingwebfit .faqGroup-list-item-answer ul > *,
pingwebfit .mix-section_userContent ul > * {
  margin: 0.5em 0;
}

pingwebfit .faqGroup-list-item-answer ol,
pingwebfit .mix-section_userContent ol {
  list-style-type: decimal;
  margin-left: 1rem;
}

pingwebfit .faqGroup-list-item-answer ol > *,
pingwebfit .mix-section_userContent ol > * {
  margin: 0.5em 0;
}

pingwebfit .mix-section_userContent_article p:first-child {
  font-size: 1rem;
  margin-top: 0;
}

pingwebfit .mix-section_userContent_article p:first-child:first-letter {
  color: #0371b5;
  float: left;
  font-size: 3.25rem;
  font-weight: 600;
  line-height: 3rem;
  padding-right: 5px;
}

pingwebfit .mix-section_userContent_article::after {
  background-color: #0371b5;
  content: '';
  display: block;
  height: 10px;
  left: 50%;
  margin-left: -5px;
  margin-top: 2rem;
  position: relative;
  width: 10px;
}

pingwebfit .video {
  width: 100%;
}

pingwebfit .video_fittingBanner {
  width: 925px;
  max-width: 100%;
}

pingwebfit .video-wrapper {
  position: relative;
  width: 100%;
}

pingwebfit .video-button {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2rem;
  padding: 0.75rem 0.5rem 0.5rem;
  color: #000;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.5rem;
}

pingwebfit .video-button:focus {
  outline: #0371b5 dotted 1px;
  outline-offset: 2px;
  -moz-outline-radius: 0.5rem;
}

pingwebfit .video-button-content {
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0.2em;
}

pingwebfit .videoThumbnailWrapper {
  position: relative;
}

pingwebfit .blocker:before,
pingwebfit .breadcrumb a:after {
  content: '';
}

pingwebfit .videoThumbnailWrapper:focus:after {
  outline: #fff dotted 1px;
  outline-offset: 2px;
}

pingwebfit .ping-landing-page-CTA-wrapper {
  display: flex;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

pingwebfit .ping-landing-page-CTA-button-left {
  margin-right: 8px;
  margin-bottom: 10px;
}

pingwebfit .ping-landing-page-video-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

pingwebfit .ping-landing-page-video {
  width: 400px;
  max-width: 90%;
  margin-right: 10px;
  margin-bottom: 10px;
}

pingwebfit .blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 12;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

pingwebfit .blocker:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

pingwebfit .blocker.behind {
  background-color: transparent;
}

pingwebfit .modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 500px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 15px 30px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
}

pingwebfit .modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

pingwebfit .modal-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

pingwebfit .modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

pingwebfit .modal-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

pingwebfit .modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(0.5);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  100%,
  40% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

pingwebfit .modal .modalSection {
  margin-top: 30px;
}

pingwebfit .modal .multilineUl li {
  margin-top: 5px;
}

pingwebfit .shippingTable {
  width: 100%;
}

pingwebfit .shippingTable td {
  padding: 5px;
  border: 1px solid #000;
  font-size: 14px;
}

pingwebfit figcaption.shippingDisclaimer {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 16px;
}

pingwebfit .sizeChartTable {
  width: 100%;
  margin-top: 0.5em;
}

pingwebfit .sizeChartTable td {
  padding: 5px;
  border: 1px solid #000;
  font-size: 14px;
}

pingwebfit .sizeChartTable td.sizeChartBolded {
  font-weight: 600;
}

pingwebfit .ping-grey-divider {
  height: 2px;
  width: 100%;
  background: rgba(128, 128, 128, 0.16);
  margin-bottom: 40px;
  margin-top: 40px;
}

pingwebfit .modalSection span {
  font-weight: 400;
}

pingwebfit .sizingChartLabel {
  font-weight: 700;
  text-transform: uppercase;
}

pingwebfit .modal__h6TopMargin {
  margin-top: 11px;
}

pingwebfit .modal__customerService {
  margin-top: 17px;
}

pingwebfit .modal__sizingCharts__h3Margin {
  margin-bottom: 1em;
  margin-top: 0.5em;
}

pingwebfit .rainPantsOnly {
  font-size: 12px;
}

pingwebfit .modal__fitting__h3Margins {
  margin-bottom: 0.5em;
  margin-top: 1em;
}

pingwebfit .modal__fitting__halfEMVertMargin {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

pingwebfit .modal__fitting__logoPlacement {
  margin-bottom: 0.5em;
  margin-top: 1em;
}

pingwebfit .modal__fitting__aside {
  font-style: italic;
  margin-top: 0.5em;
}

pingwebfit .modal__marginRight10 {
  margin-right: 10px;
}

pingwebfit .modal__marginLeft10 {
  margin-left: 10px;
}

pingwebfit .modal__halfEmMarginTop {
  margin-top: 0.5em;
}

pingwebfit .ecomm__modal h3,
pingwebfit .ecomm__modal h4 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
}

pingwebfit .ecomm__modal p {
  margin-top: 8px;
  margin-bottom: 8px;
}

pingwebfit .ecomm__modal__h3 {
  font-size: 24px;
}

pingwebfit .ecomm__modal__h4 {
  font-size: 16px;
}

pingwebfit div.ecomm__modal__customer {
  margin-top: 0;
  margin-bottom: 0;
}

pingwebfit .region__modal {
  max-width: 650px;
}

pingwebfit .region__modal img {
  width: 50%;
  margin: 5% 25%;
}

pingwebfit .region__modal p {
  text-align: center;
}

pingwebfit .region__modal p.txt-region {
  font-weight: 400;
  margin-bottom: 1.2em;
  font-size: 1.3em;
  line-height: 0.8em;
}

pingwebfit .region__modal .checkbox {
  text-align: center;
  margin: 1em 0;
}

pingwebfit .region__modal .checkbox-style-label {
  color: #000;
}

pingwebfit .region__modal .btn {
  margin: 0.3em 0;
}

pingwebfit .region__modal .countryFlagsPopUp {
  padding-top: 10px;
}

pingwebfit .region__modal .countryFlagsPopUp ul {
  float: left;
}

@media (min-width: 764px) {
  .region__modal .countryFlagsPopUp ul:not(:first-child) {
    padding-left: 25px;
  }
}

pingwebfit .cartTotals__standardGround {
  margin-bottom: 50px;
}

pingwebfit .cartTotals__total {
  margin-top: 7px;
}

pingwebfit .grips-content-cta {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
}

pingwebfit .grips-content-cta > * {
  -ms-flex-preferred-size: calc(50% - 4px);
  flex-basis: calc(50% - 4px);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 4px 0.5rem;
}

pingwebfit .grips-content-cta .left {
  text-align: right;
}

pingwebfit .grips-content-cta .left .hdg {
  margin-top: 0.5em;
  padding-right: 25px;
}

pingwebfit .ping-section-top-margin_email-preferences {
  margin-top: 60px;
}

pingwebfit
  .ping-section-top-margin_email-preferences
  .input-field_select-arrow {
  right: auto;
  left: 190px;
}

pingwebfit .hdg-3_ping-email-preferences {
  padding-bottom: 10px;
}

pingwebfit .mxm-form-field-errors > ul > li {
  color: red;
}

pingwebfit .ping-back-to-account-settings {
  margin-bottom: 30px;
}

@media screen and (min-width: 769px) {
  .ping-indented_subscriptions {
    padding-left: 6%;
  }
}

pingwebfit .ping-indented_subscriptions .ping-grey-divider_subscriptions {
  height: 2px;
  width: 50%;
  background: rgba(128, 128, 128, 0.16);
  margin-bottom: 30px;
}

pingwebfit .ping-indented_subscriptions .checkbox {
  margin-bottom: 12px;
}

pingwebfit .ping-indented_subscriptions input[type='submit'] {
  margin-top: 12px;
}

pingwebfit .ping-toast-notification {
  position: absolute;
  -webkit-box-shadow: 0 18px 35px -16px #d1ceca;
  box-shadow: 0 18px 35px -16px #d1ceca;
  text-align: center;
  font-size: 18px;
  padding: 16px 4px 12px 6px;
  z-index: -1;
  width: 350px;
}

pingwebfit .ping-toast-notification.fixed {
  position: fixed;
}

pingwebfit .ping-toast-notification.bg-white {
  background-color: #fff;
}

pingwebfit .ping-toast-notification.centered {
  left: calc((100% - 350px) / 2);
}

@media screen and (max-width: 768px) {
  .ping-toast-notification {
    width: 300px;
    font-size: 16px;
  }
}

@media screen and (min-width: 769px) {
  .ping-toast-notification {
    left: 40%;
  }
}

pingwebfit .link_longArrowLeft svg.breadcrumbsStyled {
  margin-right: 2px;
  vertical-align: top;
  padding-top: 1px;
}

pingwebfit .cartBox > .signIn,
pingwebfit .cartBox > .subtotal {
  padding: 27px 30px;
  background-color: #f8f8f8;
}

pingwebfit .shoppingCart-summary {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

pingwebfit .shoppingCart-summary > div:first-of-type {
  width: 100%;
}

@media screen and (min-width: 1240px) {
  .shoppingCart-summary > div:first-of-type {
    max-width: calc(100% - 470px - 50px);
  }
}

pingwebfit .shoppingCart-yourCart {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

pingwebfit .cartBox {
  width: 470px;
  min-width: 32.64%;
  margin-left: 25px;
}

@media screen and (max-width: 1240px) {
  .cartBox {
    width: 100%;
    margin-top: 27px;
    margin-left: 0;
  }
}

pingwebfit .cartBox .cont-modalLink {
  margin-top: 13px;
}

pingwebfit .cartBox > .subtotal {
  width: 100%;
  border-left: 5px solid #0371b5;
}

pingwebfit .cartBox > .signIn {
  width: 100%;
}

pingwebfit .breadcrumb,
pingwebfit .breadcrumb a:after {
  background-color: #ddd;
  z-index: 1;
}

@media screen and (min-width: 1241px) {
  .cartBox > .subtotal {
    margin-left: 2.67%;
  }

  .cartBox > .signIn {
    margin-left: 2.67%;
    margin-bottom: 50px;
  }
}

pingwebfit .cartBox > .mobileSignIn {
  display: none;
}

pingwebfit .cartBox > .desktopSignIn {
  display: block;
}

@media screen and (max-width: 1240px) {
  .cartBox > .signIn,
  .cartBox > .subtotal {
    width: 100%;
    margin-top: 27px;
  }

  .cartBox > .mobileSignIn {
    display: block;
  }

  .cartBox > .desktopSignIn {
    display: none;
  }
}

pingwebfit .smallBluelink {
  font-size: 14px;
  color: #0371b5;
}

pingwebfit .taxDisclaimer {
  margin-top: 22px;
}

pingwebfit .cont-proceedCheckout {
  margin-top: 15px;
}

pingwebfit .cont-proceedCheckout a,
pingwebfit .cont-proceedCheckout button.btn,
pingwebfit .cont-proceedCheckout input.btn {
  width: 100%;
}

pingwebfit .couponContainer {
  margin-top: 37px;
  border-top: 2px solid #e1e1e1;
  padding-top: 22px;
}

pingwebfit .typeDiscountCode {
  text-transform: uppercase;
  font-size: 14px;
  color: #616a70;
}

pingwebfit .cont-discountForm {
  margin-top: 12px;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

pingwebfit .cont-discountForm button {
  margin-left: 12px;
}

pingwebfit .cartItemUPC {
  font-size: 14px;
  color: #616a70;
}

pingwebfit .itemInStock {
  color: #3b7800;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

pingwebfit .itemOutStock,
pingwebfit .reducedPrice {
  color: #d40000;
}

pingwebfit .itemOutStock {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

pingwebfit .itemListPrice {
  text-decoration: line-through;
}

pingwebfit .cont-cartItems {
  margin-top: 39px;
}

pingwebfit .cont-cartItems .cartItem {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 28px 2px 28px 11px;
}

pingwebfit .cont-cartItems .cartItem .itemInfoActions {
  margin-left: 2.29%;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

pingwebfit .cont-cartItems .cartItem .itemInfoActions .productNameTrash {
  color: #0371b5;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .productNameTrash
  .nameHdg {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  margin-right: 5px;
}

pingwebfit .cont-cartItems .cartItem .itemInfoActions .cartItemPrices {
  margin-top: 8px;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .cartItemPrices
  .crossedOutPrice {
  margin-right: 3px;
  color: #616a70;
  text-decoration: line-through;
}

pingwebfit .cont-cartItems .cartItem .itemInfoActions .cartItemStock {
  margin-top: 9px;
  margin-bottom: 9px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .cartItemStock
  .itemOutStock {
  color: #d40000;
}

pingwebfit .cont-cartItems .cartItem .itemInfoActions .cont-itemDetail {
  margin-bottom: 7px;
}

pingwebfit .cont-cartItems .cartItem .itemInfoActions .itemDetailLabel {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

pingwebfit .cont-cartItems .cartItem .itemInfoActions .cont-quantityUpdate {
  margin-top: 9px;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .cont-quantityUpdate
  .quantityPrice {
  margin-top: 6px;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .cont-quantityUpdate
  .quantityPrice
  .cont-trashCan {
  margin-left: 7px;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .cont-quantityUpdate
  .quantityPrice
  .cont-trashCan
  .trashCanIconMid {
  vertical-align: middle;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .cont-quantityUpdate
  .quantityPrice
  .cont-trashCan
  > svg {
  color: #616a70;
}

pingwebfit
  .cont-cartItems
  .cartItem
  .itemInfoActions
  .cont-quantityUpdate
  .quantityPrice
  .cont-trashCan:hover
  > svg {
  color: #d40000;
}

pingwebfit .cont-cartItems .cartItem:first-child {
  border-bottom: none;
}

pingwebfit .cont-cartItems .cartItemImg {
  margin-right: 7px;
}

pingwebfit .cont-cartItems .cartItemImg img {
  width: 59px;
}

pingwebfit .breadcrumb,
pingwebfit .stepsNav-container {
  display: -webkit-box;
  display: -ms-flexbox;
  width: 100%;
}

pingwebfit .stepsNav-container {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-top: 55px;
  margin-bottom: 39px;
}

@media screen and (max-width: 1240px) {
  .stepsNav-container {
    margin-bottom: 0;
  }
}

pingwebfit .breadcrumb {
  display: flex;
  margin: auto;
  text-align: center;
  top: 50%;
  height: 57px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 14px;
}

pingwebfit .breadcrumb a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-decoration: none;
  margin: auto;
  height: 100%;
  padding-left: 38px;
  padding-right: 0;
  color: #666;
}

pingwebfit .breadcrumb a:first-child {
  padding-left: 15.2px;
}

pingwebfit .breadcrumb a:last-child {
  padding-right: 15.2px;
}

pingwebfit .breadcrumb a:after {
  position: absolute;
  display: inline-block;
  width: 57px;
  height: 57px;
  top: 0;
  right: -28.15px;
  border-top-right-radius: 5px;
  -webkit-transform: scale(0.707) rotate(45deg);
  transform: scale(0.707) rotate(45deg);
  -webkit-box-shadow: 1px -1px rgba(0, 0, 0, 0.25);
  box-shadow: 1px -1px rgba(0, 0, 0, 0.25);
}

pingwebfit .breadcrumb__inner,
pingwebfit .productDetail {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-direction: normal;
}

pingwebfit .breadcrumb a:last-child:after {
  content: none;
}

pingwebfit .breadcrumb__inner {
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  z-index: 2;
}

pingwebfit .breadcrumb__title {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Proxima Nova', Arial, sans-serif;
}

pingwebfit .addNewAddress,
pingwebfit .pingBoldedTxt,
pingwebfit .stepNumber {
  font-weight: 700;
}

pingwebfit .breadcrumb a.active,
pingwebfit .breadcrumb a.active:after {
  background: #0371b5;
  color: #fff;
}

pingwebfit #orderHistoryLink,
pingwebfit .stepNumber {
  color: #0371b5;
}

@media all and (max-width: 1000px) {
  .breadcrumb {
    font-size: 12px;
  }
}

@media all and (max-width: 710px) {
  .breadcrumb__desc {
    display: none;
  }

  .breadcrumb {
    height: 38px;
  }

  .breadcrumb a {
    padding-left: 25.33px;
  }

  .breadcrumb a:after {
    content: '';
    width: 38px;
    height: 38px;
    right: -19px;
    -webkit-transform: scale(0.707) rotate(45deg);
    transform: scale(0.707) rotate(45deg);
  }
}

@media all and (max-width: 516px) {
  .breadcrumb {
    height: auto;
  }

  .breadcrumb a:after {
    display: none;
  }

  .breadcrumb a.active,
  .breadcrumb a.active:after {
    padding: 10px;
  }
}

pingwebfit .stepNumber {
  background: #fff;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  display: inline-block;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

pingwebfit #backToTop,
pingwebfit #diffShippingInputs,
pingwebfit #newBillingAddressPartial,
pingwebfit #newShippingAddressInputs {
  display: none;
}

pingwebfit .billingDetailsHdg {
  margin-top: 23px;
  margin-bottom: 25px;
}

pingwebfit .billAddressHdg,
pingwebfit .diffDelivery {
  margin-bottom: 14px;
}

pingwebfit .billAddressHdg {
  margin-top: 38px;
}

pingwebfit .addNewAddress {
  color: #0371b5;
}

pingwebfit .contNewAddress {
  margin-top: 23px;
}

pingwebfit .accountSignIn {
  border-left: none;
  margin-bottom: 60px;
}

pingwebfit .signInBtnCont {
  margin-top: 13px;
}

pingwebfit #billingShippingItems {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

pingwebfit #billingShippingListed {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

pingwebfit #billingShippingListed h4 {
  margin-bottom: 19px;
}

pingwebfit #billingShippingListed .reviewOrder__billingAddressSubHead {
  margin-top: 20px;
}

pingwebfit #billingShippingListed .billingDetailsListedCont {
  margin-right: 11.39%;
}

pingwebfit #billingShippingListed .billingDetailsListedCont :last-child {
  margin-bottom: 20px;
}

pingwebfit #billingShippingListed .cartMainBox > div > div {
  margin-bottom: 7px;
}

pingwebfit #cardImages img {
  margin-right: 5px;
}

pingwebfit .orderConfirm__p {
  max-width: 704px;
}

pingwebfit .orderConfirm__orderSummary {
  margin-top: 37px;
  margin-bottom: 37px;
}

pingwebfit .orderConfirm__orderSummary .orderConfirm__orderSummary__upper {
  text-transform: uppercase;
}

pingwebfit .ping-account-profile-standard {
  padding-left: 35px;
  padding-right: 35px;
  height: 300px;
}

pingwebfit .categorySidebarHeading {
  font-weight: 600;
  margin-top: 38px;
}

pingwebfit .ping__ecomm__shopCategoryLanding .checkbox-style-box {
  margin-right: 8px;
}

pingwebfit .filterCheckbox {
  margin-top: 15px;
}

pingwebfit .shopCategoryLanding__catUpper {
  text-transform: uppercase;
}

pingwebfit .shopCategoryLanding__desktop__nav {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

pingwebfit .shopCategoryLanding__desktop__nav #filtersSidebar {
  padding-right: 120px;
}

pingwebfit .categoryShop__mobile__navSection {
  padding-bottom: 0;
  padding-top: 0;
}

pingwebfit .divider-1-px {
  height: 1px;
}

@media all and (max-width: 1024px) {
  #categoryShop__desktop {
    display: none;
  }

  #categoryShop__mobile {
    display: block;
  }

  #categoryShop__mobile .mobileMenu.mobileMenu_sideNav {
    background-color: #2e3134;
  }

  .filterToggledSection {
    background-color: #f1f1f1;
  }

  .filterToggled {
    background: #0371b5;
  }

  #mobileFilterSection {
    margin-top: -0.5rem;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  #mobileToggleFilters {
    width: 60px;
    height: 60px;
    margin-right: -15px;
  }

  #mobileFiltersList {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #2e3134;
    letter-spacing: 1px;
    padding-left: 10px;
    max-width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .categorySidebarHeading {
    margin-top: 0;
    padding-top: 38px;
  }

  #categoryFiltersMobile > .categorySidebarHeading {
    padding-top: 0;
  }
}

@media all and (min-width: 1025px) {
  #categoryShop__mobile {
    display: none;
    z-index: 5;
  }

  #categoryShop__desktop {
    display: block;
  }
}

pingwebfit #categoryFiltersMobile {
  display: none;
  padding-left: 10px;
  -webkit-box-shadow: -5px 13px 18px 0 rgba(0, 0, 0, 0.2);
  box-shadow: -5px 13px 18px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 22px;
  position: relative;
  z-index: 5;
}

pingwebfit #productLoading {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

pingwebfit #backToTop {
  position: fixed;
  bottom: 3.5vw;
  left: 3vw;
  z-index: 99;
  letter-spacing: 0;
}

pingwebfit .productDetail {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: flex;
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 768px) {
  .productDetail {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .productDetail-imgs {
    margin-bottom: 3rem;
    max-width: 500px;
  }
}

pingwebfit .productDetail-content-cta,
pingwebfit .productDetail-content-lists {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
}

pingwebfit .productDetail-imgs {
  -ms-flex-preferred-size: calc(60% - 2rem);
  flex-basis: calc(60% - 2rem);
}

pingwebfit .productDetail-content {
  -ms-flex-preferred-size: calc(40% - 2rem);
  flex-basis: calc(40% - 2rem);
}

pingwebfit .productDetail-content-hd {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

@media (max-width: 768px) {
  .productDetail-content {
    -ms-flex-preferred-size: calc(50% - 2rem);
    flex-basis: calc(50% - 2rem);
  }

  .productDetail-content-hd {
    text-align: center;
  }
}

pingwebfit .productDetail-content-hd-big {
  font-size: 3rem;
  font-weight: 700;
}

pingwebfit .productDetail-content-bd {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 2rem;
}

pingwebfit .productDetail-content-cta {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -4px 1rem;
}

pingwebfit .productDetail-content-cta > * {
  -ms-flex-preferred-size: calc(50% - 4px);
  flex-basis: calc(50% - 4px);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 4px 0.5rem;
}

pingwebfit .productDetail-content-cta > .hide {
  display: none;
}

pingwebfit .productDetail-content-cta_unbalanced > * {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

pingwebfit .productDetail-content-lists {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 2px solid #e1e1e1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-weight: 300;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-top: 2rem;
}

pingwebfit .productDetail-content-lists > * {
  -ms-flex-preferred-size: calc(50% - 1rem);
  flex-basis: calc(50% - 1rem);
}

pingwebfit .productDetail-content-lists-hd {
  font-weight: 400;
  letter-spacing: 0.15em;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}

pingwebfit .productDetail__ecomm__stock {
  margin-bottom: 28px;
}

pingwebfit .productDetail__ecomm__actions {
  margin-top: 35px;
}

pingwebfit .productDetail__ecomm__price {
  font-size: 18px;
}

pingwebfit #youMayAlsoLikeText p {
  font-size: 30px;
  padding-top: 15px;
}

pingwebfit #youMayAlsoLike li {
  width: 103px;
  margin-top: 10px;
}

pingwebfit .shopStorefront__bestSellers {
  padding-top: 0;
  padding-bottom: 0;
}

pingwebfit .juniorCodeHdg {
  padding-top: 2rem;
}

@media (min-width: 1025px) {
  .junior-code-chart-inputs .btn {
    font-size: smaller;
  }

  .juniorCodeChart {
    display: inline;
    margin-left: 5%;
    position: relative;
  }

  [data-junior-fitting-chart] .flexLayout_sidebar > :first-child {
    width: 300px;
    min-width: 300px;
  }
}

@media (max-width: 1109px) {
  [data-junior-fitting-chart] .flexLayout_sidebar > :first-child {
    padding-bottom: 15px;
  }

  .junior-code-chart-inputs,
  .juniorCodeChart {
    width: 100%;
  }

  .juniorCodeChart .dotOnChart {
    display: none !important;
  }
}

pingwebfit .junior-code-chart-inputs .radio {
  display: inline-block;
  padding-right: 20px;
}

pingwebfit .junior-code-chart-inputs button {
  margin-top: 2rem;
}

pingwebfit [data-junior-fitting-chart] .flexLayout {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
}

pingwebfit [data-junior-fitting-chart] .validJrSubmit {
  font-weight: 700;
  display: none;
}

pingwebfit [data-junior-fitting-chart] ul {
  padding-top: 5px;
  font-weight: 700;
}

pingwebfit [data-junior-fitting-chart] li {
  padding-left: 15px;
  font-weight: 700;
}

pingwebfit [data-junior-fitting-chart] .validJrSubmit span,
pingwebfit [data-junior-fitting-chart] li span,
pingwebfit [data-junior-fitting-chart] ul span {
  font-weight: 400;
}

pingwebfit .juniorCodeChart .imgChart {
  position: relative;
}

pingwebfit .juniorCodeChart .dotOnChart {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  z-index: 1;
}

pingwebfit .juniorCodeChart .cirlce {
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  border: 2px solid #fff;
}

pingwebfit .juniorCodeChart img {
  width: 100%;
}

pingwebfit #juniorPdf {
  width: 100% !important;
  color: #0371b5 !important;
  text-align: center !important;
}

pingwebfit {
  background-color: #efefef;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
pingwebfit.modal-open {
  overflow: hidden;
  position: fixed;
}
@media print {
  #main {
    height: auto;
  }
  pingwebfit.modal-open {
    overflow: auto;
    position: static;
  }
}

table.recommendationTable {
  width: 65%;
}
