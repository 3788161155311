@import '../../../SharedComponents/Styles/index.scss';

.wrapper {
  @extend %smallText;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.barWrapper {
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background-color: $text-inactive;
}
.bar {
  margin: 0;
  padding: 0;
  height: 8px;
  border-radius: 8px;
  background-color: $progress-color;
}
