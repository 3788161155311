@import '../../../Styles/index';

.header {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  @media screen and (min-width: $desktop-min-width) {
    padding: 0 70px;
  }
}

.headerLogo {
  height: 90px;
  display: flex;
  align-items: center;
}

.headerNav {
  height: 50px;
  @media screen and (max-width: $mobile-max-width) {
    margin: -16px;
    background-color: $text-white;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  button {
    padding: 0;
    margin: 0;
    margin-right: 2px;
  }
}

.responsiveTab {
  width: 50px;
  height: 40px;
  border-radius: 4px;
  &.active {
    background-color: $accent-smoke;
  }
}

.desktopTab {
  display: none;
  width: 100%;
  @extend %smallText;
  @extend %bold;
  text-transform: uppercase;
  color: $text-white;
  padding: 16px 30px;
  background-color: #0375ba;
  margin-right: 2px;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  img {
    position: absolute;
    left: 8px;
    top: 16px;
    width: 18px;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.active {
    background-color: $text-white;
    color: $text-color;
  }
}

@media screen and (min-width: $desktop-min-width) {
  .responsiveTab {
    display: none;
  }
  .desktopTab {
    display: flex;
  }
}
