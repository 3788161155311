@import '../../../../../SharedComponents/Styles/index';

.heading {
  @extend %sectionTitle;
  text-align: center;
  width: 100%; // for when a fieldset uses this.
}
.smallText {
  @extend %smallText;
  text-align: center;
}
.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 100%;
}
.input {
  width: 50%;
  cursor: pointer;
  padding: 0;
}
.label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 14px;
  text-transform: uppercase;
  border: 2px solid $accent-dark;
  &.first {
    border-right: 1px solid $accent-dark;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &.last {
    border-left: 1px solid $accent-dark;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &.selected {
    background-color: $link;
    color: $text-white;
  }
  &:focus ~ .label {
    border: 3px solid $focus-color;
    box-shadow: 0 0 3px $focus-color;
  }
}
