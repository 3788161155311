@import '../../../../../SharedComponents/Styles/index';
@import '../../../../../SharedComponents/Styles/mixins.module';

.wrapper {
  display: flex;
  background: linear-gradient(#3c5174, #0a1220);
  @media print {
    margin-top: -30px;
  }
}

.labels {
  width: 40%;
  background: rgba(0, 0, 0, 0.2);
  .row {
    flex-wrap: nowrap;
  }
}

.marker {
  width: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.notedText {
  @extend %smallText;
  color: $text-white;
}

.clubs {
  width: 60%;
  img {
    width: 32px;
    margin-right: 6px;
  }
  .row {
    padding-left: 8px;
    padding-right: 8px;
    .club {
      margin-right: 4px;
    }
  }
}

.disabled {
  @include mediumText();
  color: $text-inactive;
  display: flex;
  align-items: center;
  overflow: hidden;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  img {
    opacity: 0.5;
  }
}

.link {
  @include mediumText();
  @include link($text-white);
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  img {
    width: 24px;
  }
}

.row {
  height: 60px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
  .marker {
    width: 8px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .club {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    .fill {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: $text-white;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $link;
      font-size: 12px;
    }
  }
}

@media print {
  .wrapper,
  .labels {
    background: white;
  }
  .labels {
    width: 20%;
    font-weight: bold;
  }
  .row,
  .link,
  .disabled {
    color: $text-color;
  }
  .row {
    height: 40px;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      display: none;
    }
    .marker {
      display: none;
    }
    .club {
      height: 32px;
      width: 32px;
      border-radius: 16px;
      border: 2px solid $label-color;
      display: flex;
      margin-right: 8px;
      justify-content: center;
      align-items: center;
      .fill {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: transparent;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $text-color;
        font-size: 12px;
      }
    }
  }
}
