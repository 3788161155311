@import '../../../../../SharedComponents/Styles/typography.module.scss';

.helpTexts {
  display: flex;
  height: 100%;
  overflow: hidden;
  margin-top: 20px;
  p {
    @extend %smallText;
    padding: 0 10px;
    text-align: center;
    width: 50%;
    flex-grow: 1;
    &:first-child {
      padding-right: 20px;
    }
  }
}
