@import '../Styles/index';

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  &.scrunched {
    justify-content: center;
  }
  &.tiled {
    justify-content: space-around;
  }
  &.stretched {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
label {
  cursor: pointer;
  &.scrunched {
    max-width: calc(50% - 10px);
    display: block;
    margin: 20px 5px;
  }
  &.tiled {
    text-align: center;
    max-width: 100%;
    width: 100%;
    margin: 20px 20px;
  }
  &.stretched {
    text-align: center;
    margin: 5px;
  }
}

@media screen and (min-width: 415px) {
  label.tiled {
    margin-right: 20px;
    max-width: calc(50% - 40px);
    width: calc(50% - 40px);
  }
}

@media screen and (min-width: $desktop-min-width) {
  label.tiled {
    margin-right: 20px;
    max-width: calc(50% - 40px);
    width: calc(50% - 40px);
  }
}

@media screen and (min-width: 1024px) {
  .inputWrapper.scrunched {
    justify-content: center;
  }
  label.scrunched {
    max-width: calc(20% - 10px);
  }
}

.inputDisplay {
  border: 3px solid $border-color;
  border-radius: 6px;
  background-color: $text-white;
  p {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 20px 0;
  }
  img {
    max-width: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    pointer-events: none; // IE11 fix to let the radio inputs be selected by clicking on the image.
  }
}

input:checked ~ .inputDisplay {
  border: 3px solid $link;
}
input:focus ~ .inputDisplay {
  border: 3px solid $focus-color;
  box-shadow: 0 0 6px $focus-color;
}
