@import '../../../../../SharedComponents/Styles/typography.module';

legend {
  width: 100%;
  text-align: center;
}
.wrapper {
  @extend %pageTitle;
  text-align: center;
  img {
    width: 24px;
    margin-right: 8px;
  }
}
