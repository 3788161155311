@import '../../../../../SharedComponents/Styles/index';
@import '../../../../../SharedComponents/Styles/mixins.module';

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.recommendationCards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.recommendationCard {
  color: $text-color;
  display: flex;
  flex-direction: column;
  flex: 1 1 155.555px;
  padding: 0 16px;
  margin-bottom: 20px;
  &:first-child {
    @media screen and (min-width: 1368px) {
      border-right: 2px solid $accent-smoke;
    }
  }
  &:only-child {
    @media screen and (min-width: 1368px) {
      border-right: none;
    }
  }
  &.primary {
    border: 3px solid $link;
    position: relative;
  }
  @media screen and (min-width: $desktop-min-width) and (max-width: 1300px) {
    padding: 0 40px;
  }
}
.picture {
  width: 100%;
  text-align: center;
  background-color: $text-white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  img {
    max-width: 100%;
    height: auto;
  }
}
.tabsSpacing {
  padding: 20px 16px;
}
.text {
  padding: 0 20px;
  color: $text-color;
  @extend %smallText;
  position: relative;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    margin-bottom: 10em;
  }
  .heading {
    @extend %largeText;
    font-weight: bold;
  }
  .bold {
    font-weight: bold;
  }
  .rec {
    position: relative;
  }
  .star {
    position: absolute;
    left: -15px;
    top: 4px;
  }
  .tabularText {
    display: flex;
    span {
      &.datum {
        margin-right: 2%;
        font-weight: 600;
      }
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
.centered {
  text-align: center;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
