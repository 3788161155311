@import './variables.module';

// vertical rhythm
.vr_half,
%vr_half {
  margin-bottom: 0.5 * $base-vertical-rhythm;
}
.vr_1,
%vr_1 {
  margin-bottom: 1 * $base-vertical-rhythm;
}
.vr_2,
%vr_2 {
  margin-bottom: 2 * $base-vertical-rhythm;
}
.vr_3,
%vr_3 {
  margin-bottom: 3 * $base-vertical-rhythm;
}
.vr_4,
%vr_4 {
  margin-bottom: 4 * $base-vertical-rhythm;
}
.vr_5,
%vr_5 {
  margin-bottom: 5 * $base-vertical-rhythm;
}
.vr_6,
%vr_6 {
  margin-bottom: 6 * $base-vertical-rhythm;
}
.vr_7,
%vr_7 {
  margin-bottom: 7 * $base-vertical-rhythm;
}
.vr_8,
%vr_8 {
  margin-bottom: 8 * $base-vertical-rhythm;
}

.srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
