@import '../../../../../SharedComponents/Styles/index';
@import '../../../../../SharedComponents/Styles/mixins.module';

.responsiveOpener {
  @include link($text-white);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 100%;

  background-color: $text-color;

  @media screen and (min-width: $desktop-min-width) {
    display: none;
  }
}
.responsiveWrapper {
  display: block;
  @media screen and (min-width: $desktop-min-width) {
    display: none;
  }
  .responsiveWrapperBody {
    position: fixed;
    right: 0;
    left: 0;
    height: 100%;
    bottom: 0;
    z-index: 999;
    overflow: auto;
    background: linear-gradient($accent-blue, $accent-dark);
    padding: 16px;
  }
}
.desktopWrapper {
  display: none;
  @media screen and (min-width: $desktop-min-width) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.loaderContainer {
  height: 18px;
}
.loader {
  display: flex;
  align-items: center;
  img {
    width: 18px;
    margin-right: 8px;
  }
  font-size: 12px;
}
.printBlock {
  display: none;
}
.block,
.printBlock {
  padding: 16px;
  @media screen and (min-width: $desktop-min-width) {
    padding: 16px 30px;
  }
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  @extend %sectionTitle;
  @include link($text-white);
  color: $text-white;
  &.desktopLabel {
    @media print {
      display: none;
    }
  }
  &.printLabel {
    display: none;
    @media print {
      display: block;
      font-weight: bold;
    }
  }
}

.link {
  @include mediumText();
  @include link($text-white);
  margin-right: 16px;
  img {
    width: 14px;
    margin-right: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
}

.link {
  &:disabled {
    pointer-events: none;
    span {
      border: none;
      color: gray;
    }
  }
}

.pdfLink {
  display: none;
}

.backLinkResponsive img {
  position: relative;
  top: 2px;
  margin-right: 2px;
}

.mapIcon {
  position: relative;
  top: 4px;
}

.printIcon {
  position: relative;
  top: 2px;
}

.emailIcon {
  position: relative;
  top: 2px;
  filter: invert(1);
}

.input {
  color: $text-color;
  &:focus {
    border: 3px solid $focus-color;
    box-shadow: 0 0 6px $focus-color;
  }
}

.inputFieldSelectArrow {
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 100%;
  color: $link;
  pointer-events: none;
  text-align: center;
  outline: 0;
  img {
    width: 36px;
    height: 100%;
  }
}

.printOnly {
  display: none !important;
}

.disclaimerText {
  @include mediumText();
  color: $text-white;
}

// hide responsive styles, print desktop styles regardless of view.
@media print {
  .printOnly,
  .printLink,
  .desktopWrapper,
  .printBlock {
    display: block !important;
    color: black;
  }
  .printOnly {
    border-bottom: 1px solid lightgray;
    h3 {
      font-weight: bold;
    }
  }
  .printBlock {
    padding-bottom: 0;
  }
  .wrapper,
  .block,
  .title {
    color: $text-color;
  }
  .block {
    padding: 20px 0;
    border-color: $border-color;
  }
  .link,
  select.input,
  .responsiveOpener,
  .responsiveWrapperBody,
  .responsiveWrapper .loaderContainer {
    display: none !important;
  }
  .fittingInfoColumns {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    border-top: 1px solid lightgray;
    h3 {
      font-weight: bold;
    }
  }
}

.productDetailsContent {
  flex-basis: 60%;
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  &.visibilityHidden {
    visibility: hidden;
  }
}

.modalInner {
  width: 80vw;
  max-width: 1000px;
  min-width: 730px;
  height: 80vh;
  margin: 2em auto;
}

.getOutputButtonContainer {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.closeBar {
  padding: 10px;
  width: 100%;
  background-color: #f1f0f0;
  text-align: right;
}

.closeBarX {
  cursor: pointer;
  user-select: none;
}
