@import '../../../SharedComponents/Styles/mixins.module';

.vr_1 {
  @include vr_1();
}
.vr_3 {
  @include vr_3();
}
.vr_5 {
  @include vr_5();
}
.vr_8 {
  @include vr_8();
}
.vr_half {
  @include vr_half();
}
.bold {
  @include bold();
}
.srOnly {
  @include srOnly();
}

.headerContainer {
  position: relative;
  width: 100%;
  max-width: $large-desktop-min-width;
  margin: 0 auto;
}

.responsiveHeader {
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: $desktop-min-width) {
    display: none;
  }
}

.desktopHeader,
.desktopMenu {
  display: none;
  @media screen and (min-width: $desktop-min-width) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.link {
  @include mediumText();
  @include link($text-white);
  img {
    position: relative;
    top: 6px;
  }
}

.menuLink {
  @include smallText();
  @include link($link);
}

.btn {
  @include mediumText();
  @include link($text-white);
  img {
    position: relative;
    top: 4px;
  }
}

.input {
  @include largeText();
  color: $link;
}

.menuBtn {
  @include mediumText();
  @include link($link);
}

.icon {
  width: 24px;
}
.logo {
  width: 200px;
}

.menu {
  background-color: $text-white;
  color: $text-color;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  @media screen and (min-width: $desktop-min-width) {
    position: absolute;
    width: 200px;
    top: 32px;
    height: auto;
    padding: $base-vertical-rhythm * 3;
    width: $base-vertical-rhythm * 23;
  }
}
.closeBtn {
  position: absolute;
  right: 0;
  img {
    width: 32px;
    height: 32px;
  }
}

.responsiveMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  @media screen and (min-width: $desktop-min-width) {
    display: none;
  }
  .responsiveMenuBody {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    button {
      color: $link;
    }
    .bold {
      font-weight: bold;
    }
  }
}
.desktopMenu {
  display: none;
  button {
    display: block;
    color: $link;
  }
  @media screen and (min-width: $desktop-min-width) {
    display: block;
  }
  hr {
    margin: 20px -30px;
    border: 1px solid $accent-smoke;
  }
  a {
    font-size: 14px;
    line-height: 18px;
  }
}

.form {
  label {
    cursor: pointer;
    input {
      position: relative;
      bottom: 2px;
    }
  }
  color: $link;
}
