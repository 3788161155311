@import '../../../SharedComponents/Styles/mixins.module';

.wrapper {
  height: 100vh;
  padding: 16px;
  background-color: $blue-grad-pt-1;
  background-image: url(../../../Assets/Images/Glide-BG.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.vr_4 {
  @include vr_4();
}
.vr_2 {
  @include vr_2();
}

.modalContainer {
  height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link {
  @include mediumText();
  @include link($text-white);
  img {
    width: 24px;
    position: relative;
    top: 6px;
  }
}

.txt1 {
  @include largeText();
  color: $text-white;
}

.txt2 {
  @include smallText();
  color: $text-white;
  a {
    color: white;
    text-decoration: underline;
  }
}

.bold {
  font-weight: bold;
}

.modal {
  padding: 40px 16px;
  color: $text-white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  img {
    margin-bottom: 24px;
    max-width: 100%;
    height: auto;
  }
  .link {
    font-size: 16px;
  }

  .loadingWheel {
    width: 100%;
    max-width: 50px;
    margin: 0;
  }
}

.btnWidth {
  max-width: 250px;
}
.btnWidth:disabled {
  width: 100%;
}

@media screen and (min-width: $desktop-min-width) {
  .wrapper {
    padding: 40px;
  }
  .modal {
    width: 850px;
    padding: 70px;
  }
  .modalContainer {
    align-items: center;
  }
}
