@import '../Styles/index';

.inputWrapper {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
.input {
  flex: 1 1;
  cursor: pointer;
}
.label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 60px;
  font-size: 14px;
  text-transform: uppercase;
  border: 2px solid $accent-dark;
  border-right: 1px solid $accent-dark;
  border-left: 1px solid $accent-dark;
  padding: 10px;
  text-align: center;
  &.first {
    border-left: 2px solid $accent-dark;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &.last {
    border-right: 2px solid $accent-dark;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
input:checked ~ .label {
  background-color: $link;
  color: $text-white;
}
input:focus ~ .label {
  border: 3px solid $focus-color;
  box-shadow: 0 0 3px $focus-color;
}
