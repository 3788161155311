@import '../../../../../SharedComponents/Styles/index';

.recommendationCard {
  color: $text-color;
  background-color: $text-white;
  padding: 16px;
  margin-bottom: 15px;
  border-radius: 3px;
  overflow: hidden;
  &.primary {
    border: 3px solid $link;
    position: relative;
    .rec {
      color: $text-white;
      background-color: $link;
    }
  }
  &.alt {
    .rec {
      color: $text-color;
      background-color: $border-color;
    }
  }
  &.notUpdated {
    background-color: $text-white;
  }
  &.updated {
    animation-name: updatedCard;
    animation-duration: 3s;
  }
}

@keyframes updatedCard {
  from {
    background-color: $update-color;
  }
  to {
    background-color: $text-white;
  }
}

.header {
  display: flex;
  height: 70px;
  position: relative;
  .picture {
    margin-right: 20px;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .rec {
    @extend %smallText;
    .bold {
      font-weight: bold;
    }
    padding: 4px;
    border-radius: 2px;
    color: white;
    background-color: $link;
  }
  .textHeading {
    @extend %largeText;
    font-weight: bold;
  }
}

.table {
  @extend %smallText;
  color: $text-color;
  margin-top: 20px;
}
.tableEntry {
  margin-bottom: 5px;
  display: flex;
  .datum,
  .value {
    width: 50%;
    &:first-letter {
      text-transform: capitalize;
    }
  }
}

@media screen and (min-width: $desktop-min-width) {
  .header {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: auto;
  }
  .tableEntry {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: $large-desktop-min-width) {
  .header {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    text-align: left;
  }
  .table {
    margin-left: 90px;
    margin-top: 0;
  }
  .tableEntry {
    flex-direction: row;
    margin-bottom: 5px;
    .datum {
      width: 120px;
    }
    .value {
      width: calc(100% - 120px);
    }
  }
}

@media print {
  .recommendationCard {
    page-break-inside: avoid;
    .table {
      margin-left: 90px;
      margin-top: 0;
      .tableEntry {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
      }
    }
    &.primary {
      border: 3px solid $text-color;
      .rec {
        color: $text-color;
        background-color: transparent;
      }
    }
  }
}
