@import '../Styles/index';
@import '../Styles/mixins.module';

.label {
  @extend %sectionTitle;
  text-align: center;
  width: 100%; // for when a fieldset uses this.
}

.srOnly {
  @include srOnly();
}

.link {
  @include mediumText();
  @include link($link);
}

.helpTextArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .chevron {
    width: 24px;
    height: 24px;
    position: relative;
    top: 7px;
    left: 4px;
  }
}

.help {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-out;
  width: 100%; // IE11 Fix
  &.showing {
    max-height: 500px;
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease-in;
  }
}

.errorText {
  @extend %smallText;
  color: $text-error;
  font-weight: 600;
  display: flex;
  align-items: center;
  img {
    width: 24px;
    margin-right: 5px;
  }
}
